import { useState, useCallback, useEffect } from "react";
import { useScrollToTop } from "hooks/scroll-to-top-hook";
import FilledButton from "components/buttons/FilledButton";
import Pagination from "components/Pagination";
import Loader from "components/Loader";
import iconAvatarPlaceholder from "assets/icons/avatar-placeholder.svg";
import iconLogoGray from "assets/images/logo-gray.svg";
import iconScrollToTop from "assets/icons/scroll-to-top.svg";
import serviceUsers from "services/users";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const Unassigned = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { isOpenScrollToTop, handleClickScrollToTop } = useScrollToTop();

  const getPage = useCallback(async () => {
    setIsLoading(true);
    serviceUsers
      .getUnassignedCustomersPage(page, pageSize)
      .then((data) => {
        setUsers(data.items);
        setPagesCount(data.page_count);
      })
      .finally(() => setIsLoading(false));
  }, [page, pageSize]);

  useEffect(() => {
    getPage();
  }, [getPage]);

  const hadnleClickAssign = async (userId) => {
    setIsLoading(true);
    try {
      await serviceUsers.assignCustomerToMe(userId);
    } catch (e) {
      console.error(e);
    }
    getPage().finally(() => setIsLoading(false));
  };

  const handleChangePage = (page) => {
    setPage(page);
    handleClickScrollToTop();
  };

  const handleChangePageSize = (pageSize) => {
    setPage(1);
    setPageSize(pageSize);
    handleClickScrollToTop();
  };

  if (isLoading) {
    return <Loader isOpen />;
  }

  return (
    <div className={s["unassigned"]}>
      {users.length ? (
        <>
          <div className={s["unassigned__table"]}>
            <div className={s["unassigned__heading"]}>
              <p className={s["unassigned__title"]}>Customer</p>
              <p className={s["unassigned__title"]}>Email</p>
              <p className={s["unassigned__title"]}>Last updated</p>
            </div>
            {users.map((user) => (
              <div className={s["unassigned__row"]} key={user.id}>
                <div className={s["unassigned__user"]}>
                  <img
                    className={s["unassigned__avatar"]}
                    alt={(user.first_name || "") + " " + (user.last_name || "")}
                    src={user.avatar?.link || iconAvatarPlaceholder}
                  />
                  <p className={s["unassigned__text"]}>
                    {user.first_name || ""} {user.last_name || ""}
                  </p>
                </div>
                <FilledButton
                  className={s["unassigned__button"]}
                  onClick={() => hadnleClickAssign(user.id)}
                >
                  Assign to me
                </FilledButton>
                <p
                  className={
                    s["unassigned__text"] + " " + s["unassigned__text--email"]
                  }
                >
                  {user.email || ""}
                </p>
                <p
                  className={
                    s["unassigned__text"] + " " + s["unassigned__text--date"]
                  }
                >
                  {user.event_at ? utilFormatter.prettyDate(user.event_at) : ""}
                </p>
              </div>
            ))}
          </div>
          <div className={s["unassigned__pagination"]}>
            <Pagination
              page={page}
              pageSize={pageSize}
              pageCount={pagesCount}
              isLoading={isLoading}
              onChangePage={handleChangePage}
              onChangePageSize={handleChangePageSize}
            />
          </div>
        </>
      ) : (
        <div className={s["unassigned__empty-wrapper"]}>
          <h1 className={s["unassigned__banner"]}>
            There are no not assigned customers yet
          </h1>
          <img
            className={s["unassigned__logo"]}
            alt="Logo"
            src={iconLogoGray}
          />
        </div>
      )}
      <img
        className={
          s["unassigned__scroll-to-top-arrow"] +
          (isOpenScrollToTop
            ? ` ${s["unassigned__scroll-to-top-arrow--open"]}`
            : "")
        }
        alt="Scroll to top"
        src={iconScrollToTop}
        onClick={handleClickScrollToTop}
      />
    </div>
  );
};

export default Unassigned;
