import { useState, useEffect } from "react";
import Input from "components/inputs/Input";

const NumberInput = ({
  value: defaultValue = 0,
  placeholder = "",
  minValue = 0,
  maxValue = null,
  onChange = () => {},
  spliter = "",
  ...rest
}) => {
  const [value, setValue] = useState(String(defaultValue));

  useEffect(() => setValue(String(defaultValue)), [defaultValue]);

  const handleChange = (value) => {
    if (spliter) {
      setValue((currentStateValue) => {
        if (!currentStateValue) {
          return value.replace(/\D+/g, "").replace(/^0+/, "");
        }
        return value.replace(/\D+/g, "");
      });
      return;
    }

    setValue(value.replace(/\D+/g, "").replace(/^0+/, ""));
    return;
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    handleBlur();
  };

  const handleBlur = () => {
    const filteredValue = value.replace(/^0+/, "");

    if (!filteredValue) {
      setValue(String(minValue));
      onChange(minValue);
      return;
    }
    const numberValue = Number(filteredValue.replaceAll(spliter, ""));
    if (maxValue !== null && numberValue > maxValue) {
      setValue(String(maxValue));
      onChange(maxValue);
      return;
    }
    setValue(filteredValue);
    onChange(numberValue);
  };

  return (
    <Input
      value={value}
      placeholder={placeholder}
      spliter={spliter}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      {...rest}
    />
  );
};

export default NumberInput;
