import FilledButton from "components/buttons/FilledButton";
import Select from "react-select";
import ExpandIndicator from "components/ExpandIndicator";
import NumberInput from "components/inputs/NumberInput";
import iconDropdown from "assets/icons/dropdown.svg";
import iconPaginationFirst from "assets/icons/pagination-first.svg";
import iconPaginationFirstDisabled from "assets/icons/pagination-first-disabled.svg";
import iconPaginationLast from "assets/icons/pagination-last.svg";
import iconPaginationLastDisabled from "assets/icons/pagination-last-disabled.svg";
import iconPaginationPrev from "assets/icons/pagination-prev.svg";
import iconPaginationNext from "assets/icons/pagination-next.svg";
import utilConstants from "utils/constants";
import s from "./index.module.scss";

const Pagination = ({
  page = 1,
  pageSize = 10,
  pageCount = 1,
  isLoading = false,
  onChangePage = () => {},
  onChangePageSize = () => {},
}) => {
  const isFirst = page === 1;
  const isLast = page === pageCount;

  const handleClickFirst = () => {
    if (isLoading || isFirst) {
      return;
    }
    onChangePage(1);
  };

  const handleChangePageSize = ({ value }) => {
    if (isLoading) {
      return;
    }
    if (value === pageSize) {
      return;
    }
    onChangePageSize(value);
  };

  const handleClickLast = () => {
    if (isLoading || isLast) {
      return;
    }
    onChangePage(pageCount);
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      border: "unset",
      borderRadius: "unset",
      boxShadow: "unset",
      cursor: "pointer",
      width: 110,
      height: 40,
      display: "grid",
      gridTemplateColumns: "1fr 14px",
      gridColumnGap: 5,
      padding: "0 16px",
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      fontSize: 14,
      display: "grid",
      gridTemplateTolumns: "1fr 0",
      alignItems: "center",
    }),

    singleValue: (provided) => ({
      ...provided,
      overflow: "unset",
      whiteSpace: "nowrap",
      margin: 0,
      paddingLeft: 1,
      fontSize: 14,
    }),

    indicatorsContainer: (provided) => ({
      ...provided,
      marginTop: 3,
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    menu: (provided) => ({
      ...provided,
      margin: "1px 0",
      boxShadow: "unset",
    }),

    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),

    option: (provided) => ({
      ...provided,
      height: 40,
      fontSize: 14,
      padding: "0 20px",
      display: "grid",
      alignContent: "center",
      backgroundColor: "unset",
      color: "inherit",
      "&:hover": {
        backgroundColor: "#e0e0e0 !important",
      },
    }),
  };

  return (
    <>
      <div className={s["pagination"]}>
        <div className={s["pagination__ui-controls"]}>
          <img
            src={
              isLoading || isFirst
                ? iconPaginationFirstDisabled
                : iconPaginationFirst
            }
            alt="First page"
            className={
              s["pagination__icon"] +
              (isLoading || isFirst
                ? ` ${s["pagination__icon--disabled"]}`
                : "")
            }
            onClick={handleClickFirst}
          />
          <div className={s["pagination__buttons"]}>
            <FilledButton
              className={s["pagination__button"]}
              disabled={isLoading || isFirst}
              onClick={() => onChangePage(page - 1)}
            >
              <img
                src={iconPaginationPrev}
                alt="Prev page"
                className={s["pagination__button-icon"]}
              />
              Previous page
            </FilledButton>
            <Select
              options={utilConstants.paginationOptions}
              value={
                utilConstants.paginationOptions.filter(
                  (selectOption) => selectOption.value === pageSize
                )[0]
              }
              onChange={handleChangePageSize}
              isSearchable={false}
              components={{
                DropdownIndicator: ({ selectProps: { menuIsOpen } }) => (
                  <ExpandIndicator src={iconDropdown} isOpen={menuIsOpen} />
                ),
              }}
              styles={selectStyles}
            />
            <FilledButton
              className={s["pagination__button"]}
              disabled={isLoading || isLast}
              onClick={() => onChangePage(page + 1)}
            >
              Next page
              <img
                src={iconPaginationNext}
                alt="Next page"
                className={s["pagination__button-icon"]}
              />
            </FilledButton>
          </div>
          <img
            src={
              isLoading || isLast
                ? iconPaginationLastDisabled
                : iconPaginationLast
            }
            alt="Last page"
            className={
              s["pagination__icon"] +
              (isLoading || isLast ? ` ${s["pagination__icon--disabled"]}` : "")
            }
            onClick={handleClickLast}
          />
        </div>
        <div className={s["pagination__input-wrapper"]}>
          <p className={s["pagination__text"]}>Page</p>
          <NumberInput
            value={page}
            minValue={1}
            maxValue={pageCount}
            onChange={(page) => onChangePage(page)}
            className={s["pagination__input"]}
            disabled={isLoading}
          />
          <p className={s["pagination__text"]}>of {pageCount}</p>
        </div>
      </div>

      <div className={s["mobile-pagination"]}>
        {!isFirst && (
          <FilledButton
            className={
              s["pagination__button"] +
              " " +
              s["pagination__button--mobile-unfilled"]
            }
            disabled={isLoading}
            onClick={() => onChangePage(page - 1)}
          >
            <img
              src={iconPaginationPrev}
              alt="Prev page"
              className={s["pagination__button-icon"]}
            />
          </FilledButton>
        )}
        {!isLast && (
          <FilledButton
            className={s["pagination__button"]}
            disabled={isLoading}
            onClick={() => onChangePage(page + 1)}
          >
            Next page
            <img
              src={iconPaginationNext}
              alt="Next page"
              className={s["pagination__button-icon"]}
            />
          </FilledButton>
        )}
      </div>
    </>
  );
};

export default Pagination;
