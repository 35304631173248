import s from "./index.module.scss";

const UploadPhoto = ({ onClick = () => {}, inputRef }) => {
  const handleChange = (e) => {
    const files = [...e.target.files];

    if (!files.length) {
      return;
    }

    const file = files[0];
    const fileType = file["type"].split("/")[1];

    if (!["jpg", "jpeg", "png"].includes(fileType)) {
      return;
    }
    onClick(file);

    inputRef.current.value = "";
  };
  return (
    <input
      className={s["upload-photo__input"]}
      type="file"
      ref={inputRef}
      accept=".jpg, .jpeg, .png"
      onChange={handleChange}
    />
  );
};

export default UploadPhoto;
