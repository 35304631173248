import { useEffect, useState } from "react";
import Modal from "components/Modal";
import iconCrossBlack from "assets/icons/cross-black.svg";
import SimpleInput from "components/inputs/SimpleInput";
import FilledButton from "components/buttons/FilledButton";
import s from "./index.module.scss";

const SaveSearchModal = ({
  isOpen = false,
  onClose = () => {},
  onDone = () => {},
  title = "",
}) => {
  const [localTitle, setLocalTitle] = useState(title);

  const isValidTitle = title ? localTitle && localTitle !== title : localTitle;

  useEffect(() => {
    setLocalTitle(title);
  }, [title]);

  const handleClose = () => {
    setLocalTitle(title);
    onClose();
  };

  const handleTitleChange = (e) => {
    setLocalTitle(e.target.value);
  };

  const handleTitleKeyDown = ({ code }) => {
    if (code !== "Enter" || !localTitle) {
      return;
    }

    handleClickSaveSearch();
  };

  const handleClickSaveSearch = () => {
    onDone(localTitle);
    setLocalTitle("");
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["save-search-modal"]}>
        <img
          className={s["save-search-modal__cross"]}
          src={iconCrossBlack}
          alt="Close"
          onClick={handleClose}
        />
        <div className={s["save-search-modal__content-wrapper"]}>
          <p className={s["save-search-modal__title"]}>Save search</p>
          <SimpleInput
            placeholder="Title"
            wrapperClassName={s["save_search-modal__input-wrapper"]}
            className={s["save_search-modal__input"]}
            onChange={handleTitleChange}
            onKeyDown={handleTitleKeyDown}
            value={localTitle}
          />
          <FilledButton
            className={s["save-search-modal__button"]}
            disabled={!isValidTitle}
            onClick={handleClickSaveSearch}
          >
            Save search
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default SaveSearchModal;
