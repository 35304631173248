import savedSearchesGateway from "gateways/api/savedSearchesGateway";
import vehiclesGateway from "gateways/api/vehiclesGateway";

import authRepository from "repositories/authRepository";
import userRepository from "repositories/userRepository";
import vehicleRepository from "repositories/vehicleRepository";
import savedSearchRepository from "repositories/savedSearchRepository";

import utilConstants from "utils/constants";

const getMySavedSearches = () => {
  const userId = authRepository.getUserId();

  // Have to pass page size and page number to get all saved searches
  const pageNumber = 1;
  const pageSize = savedSearchRepository.getPageSize();

  return savedSearchesGateway.getSavedSearches(userId, pageNumber, pageSize);
};

const findFirstVehicleThumbnail = (vehicles) => {
  let thumbnail = null;

  if (vehicles.length > 0) {
    thumbnail = vehicles.find((vehicle) => vehicle.thumbnail).thumbnail;
  }

  return thumbnail;
};

const addSavedSearch = (title) => {
  const zipCode = vehicleRepository.getZipCode();
  const sorting = vehicleRepository.getSorting();
  const filters = vehicleRepository.getFilters();
  const searchText = vehicleRepository.getSearchText();

  const vehicles = vehicleRepository.getVehicles();
  const thumbnail = findFirstVehicleThumbnail(vehicles);

  return savedSearchesGateway.addSavedSearch(
    title,
    zipCode,
    sorting,
    filters,
    searchText,
    thumbnail
  );
};

const getSavedSearch = (savedSearchId) => {
  return savedSearchesGateway
    .getSavedSearch(savedSearchId)
    .then((savedSearch) => {
      savedSearchRepository.updateSavedSearchId(savedSearch.id);
      savedSearchRepository.updateTitle(savedSearch.title);
      savedSearchRepository.updateIsAutoConvosEnabled(savedSearch.is_autoconvo);
      savedSearchRepository.updateSorting(savedSearch.query.sorting);
      savedSearchRepository.updateZipCode(savedSearch.query.zipCode);
      savedSearchRepository.updateFilters(savedSearch.query.filters);
      savedSearchRepository.updateSearchText(savedSearch.query.searchText);
      return savedSearch;
    });
};

const getVehiclesPage = () => {
  const userRole = userRepository.getRole();

  const zipCode = savedSearchRepository.getZipCode();
  const sorting = savedSearchRepository.getSorting();
  const filters = savedSearchRepository.getFilters();
  const searchText = savedSearchRepository.getSearchText();
  const isExtendedFiltering = utilConstants.roles[userRole].isExtendedFiltering;
  const pageNumber = savedSearchRepository.getPageNumber();
  const pageSize = savedSearchRepository.getPageSize();
  const searchedAt = savedSearchRepository.getSearchedAtTimestamp();

  savedSearchRepository.updateLoadingState(true);

  return vehiclesGateway
    .getVehiclesPage(
      zipCode,
      sorting,
      filters,
      searchText,
      isExtendedFiltering,
      pageNumber,
      pageSize,
      searchedAt
    )
    .then(
      ({
        items,
        page_count: pageCount,
        vehicles_stats: vehicleStats = null,
      }) => {
        savedSearchRepository.appendVehicles(items);
        savedSearchRepository.updatePageCount(pageCount);
        if (utilConstants.roles[userRole].isLoadVehicleStats) {
          savedSearchRepository.updateStats(vehicleStats);
        }
      }
    )
    .catch(() => {
      savedSearchRepository.updateVehicles([]);
      return Promise.reject();
    })
    .finally(() => savedSearchRepository.updateLoadingState(false));
};

const updateSavedSearch = (savedSearchId, title) => {
  const zipCode = savedSearchRepository.getZipCode();
  const sorting = savedSearchRepository.getSorting();
  const filters = savedSearchRepository.getFilters();
  const searchText = savedSearchRepository.getSearchText();

  const vehicles = savedSearchRepository.getVehicles();
  const thumbnail = findFirstVehicleThumbnail(vehicles);

  return savedSearchesGateway
    .updateSavedSearch(
      savedSearchId,
      title,
      zipCode,
      sorting,
      filters,
      searchText,
      thumbnail
    )
    .then((savedSearch) => savedSearch);
};

const deleteSavedSearch = (savedSearchId) =>
  savedSearchesGateway.deleteSavedSearch(savedSearchId);

const startAutoConvos = (savedSearchId, isForceStart) =>
  savedSearchesGateway.startAutoConvos(savedSearchId, isForceStart);

const stopAutoConvos = (savedSearchId) =>
  savedSearchesGateway.stopAutoConvos(savedSearchId);

const service = {
  getMySavedSearches,
  addSavedSearch,
  getSavedSearch,
  getVehiclesPage,
  updateSavedSearch,
  deleteSavedSearch,
  startAutoConvos,
  stopAutoConvos,
};

export default service;
