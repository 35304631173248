import { forwardRef, useCallback, useEffect, useRef } from "react";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);

  if (!filteredRefs.length) {
    return null;
  }

  if (filteredRefs.length === 1) {
    return filteredRefs[0];
  }

  return (inst) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

const Input = forwardRef(
  (
    {
      type = "text",
      className = "",
      value: defaultValue = "",
      placeholder = "",
      disabled = false,
      isError = false,
      spliter = "",
      chunkSize = null,
      onChange = () => {},
      onKeyDown = () => {},
      onBlur = () => {},
    },
    ref
  ) => {
    const prettyValue = useCallback(
      (value) => {
        if (!spliter || !chunkSize) {
          return value;
        }
        return utilFormatter.prettyString(value, spliter, chunkSize);
      },
      [spliter, chunkSize]
    );

    const inputRef = useRef(null);
    const cursorPosition = useRef(
      defaultValue.length ? defaultValue.length : 0
    );

    const inputValue = prettyValue(defaultValue);

    const handleKeyDown = (e) => {
      onKeyDown(e);

      if (!spliter) return;

      const currentInputValue = inputRef.current.value;
      const startSelectionPosition = inputRef.current.selectionStart;
      const endSelectionPosition = inputRef.current.selectionEnd;

      if (startSelectionPosition !== endSelectionPosition) return;

      switch (e.key) {
        case "Backspace":
          if (
            currentInputValue.charAt(startSelectionPosition - 1) === spliter
          ) {
            inputRef.current.setSelectionRange(
              startSelectionPosition - 1,
              startSelectionPosition - 1
            );
          }
          break;
        case "Delete":
          if (currentInputValue.charAt(startSelectionPosition) === spliter) {
            inputRef.current.setSelectionRange(
              startSelectionPosition + 1,
              startSelectionPosition + 1
            );
          }
          break;
        default:
          break;
      }
    };

    const handleChange = ({ target: { value, selectionStart } }) => {
      const splitersCountBeforeCursor =
        !spliter || !chunkSize
          ? 0
          : value.substring(0, selectionStart).split(spliter).length - 1;
      cursorPosition.current = selectionStart - splitersCountBeforeCursor;
      onChange(value.replaceAll(spliter, ""));
    };

    useEffect(() => {
      if (!inputRef.current) {
        return;
      }

      if (!spliter || !chunkSize) return;

      if (cursorPosition.current === 0) {
        inputRef.current.setSelectionRange(0, 0);
        return;
      }

      let charactersCount = 0;
      let splitersCount = 0;
      for (let i = 0; i < inputValue.length; i++) {
        if (inputValue[i] !== spliter) charactersCount++;
        else splitersCount++;

        if (charactersCount === cursorPosition.current) break;
      }

      const newCursorPosition = charactersCount + splitersCount;
      inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }, [inputValue, spliter, chunkSize]);

    return (
      <input
        type={type}
        className={
          s["input"] +
          (isError ? ` ${s["input--error"]}` : "") +
          (className ? ` ${className}` : "")
        }
        value={inputValue}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        ref={mergeRefs(ref, inputRef)}
      />
    );
  }
);

export default Input;
