import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AssignedCustomerCard from "components/AssignedCustomerCard";
import Loader from "components/Loader";
import iconAutoAgents from "assets/images/logo-gray.svg";
import serviceUsers from "services/users";
import s from "./index.module.scss";

const AssignedCustomers = () => {
  const userId = useSelector((state) => state.user.id);

  const [assignedCustomers, setAssignedCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setAssignedCustomers({});
    serviceUsers
      .getAssignedCustomers(userId)
      .then((data) => setAssignedCustomers(data))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className={s["assigned-customers"]}>
      {isLoading ? (
        <></>
      ) : (
        <>
          {assignedCustomers.length !== 0 ? (
            <div className={s["assigned-customers__grid"]}>
              {assignedCustomers.map((assignedCustomer) => (
                <AssignedCustomerCard
                  assignedCustomer={assignedCustomer}
                  key={assignedCustomer.id}
                />
              ))}
            </div>
          ) : (
            <>
              <div
                className={
                  s["assigned-customers__title"] +
                  " " +
                  s["assigned-customers__title--empty"]
                }
              >
                You have no assigned customers yet
              </div>
              <img
                className={s["assigned-customers_icon-autoagents"]}
                src={iconAutoAgents}
                alt="AutoAgents icon"
              />
            </>
          )}
        </>
      )}
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default AssignedCustomers;
