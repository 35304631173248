import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import serviceWatchLists from "services/watch-lists";
import iconLogoWhite from "assets/images/logo-white.svg";
import iconPrevPage from "assets/icons/prev-page.svg";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const WatchListVehicleDetails = () => {
  const [vehicle, setVehicle] = useState({});

  const history = useHistory();

  const { watchListId, vehicleId } = useParams();

  const handleClickGoBack = () =>
    history.push(`/public-watch-lists/${watchListId}`);

  const {
    build: { year = null, make = null, model = null, trim = null } = {},
    mileage = null,
  } = vehicle;

  useEffect(() => {
    serviceWatchLists.getPublicVehicle(watchListId, vehicleId).then(setVehicle);
  }, [watchListId, vehicleId]);

  return (
    <div className={s["watch-list-vehicle-details"]}>
      <div className={s["watch-list-vehicle-details__controls-wrapper"]}>
        <img
          className={s["watch-list-vehicle-details__logo"]}
          src={iconLogoWhite}
          alt="logo"
        />
        <img
          className={s["watch-list-vehicle-details__icon"]}
          src={iconPrevPage}
          alt="Prev page"
          onClick={handleClickGoBack}
        />
      </div>
      <div className={s["watch-list-vehicle-details__info-wrapper"]}>
        <p className={s["watch-list-vehicle-details__text"]}>{year}</p>
        <p
          className={
            s["watch-list-vehicle-details__text"] +
            " " +
            s["watch-list-vehicle-details__text--bold"]
          }
        >
          &nbsp;{make} {model} {trim}
        </p>
        {typeof mileage === "number" && (
          <p className={s["watch-list-vehicle-details__text"]}>
            &nbsp;{utilFormatter.prettyString(parseInt(mileage), " ")} km
          </p>
        )}
      </div>
    </div>
  );
};

export default WatchListVehicleDetails;
