import { useState, useEffect } from "react";
import AddComment from "./AddComment";
import SingleActivity from "./SingleActivity/";
import serviceVehicles from "services/vehicles";
import s from "./index.module.scss";

const Activity = ({ vehicleId = "", vin = "" }) => {
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    const fetchActivities = async () => {
      serviceVehicles.getActivity(vehicleId).then(setActivity);
    };

    fetchActivities();

    const interval = setInterval(fetchActivities, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleUpdateActivity = (activity) => setActivity(activity);

  const handleRemoveActivity = (event) =>
    setActivity(activity.filter((el) => el.id !== event.id));

  const handleEditActivity = (event) => {
    const updatedActivity = activity.map((el) => {
      if (el.id !== event.id) {
        return el;
      }
      return event;
    });
    setActivity(updatedActivity);
  };

  return (
    <div className={s[`activity`]}>
      <div className={s[`activity__comment`]}>
        <AddComment vehicleId={vehicleId} onUpdate={handleUpdateActivity} />
      </div>
      {activity.map((event) => (
        <SingleActivity
          event={event}
          vehicleId={vehicleId}
          vin={vin}
          onEdit={handleEditActivity}
          onRemove={handleRemoveActivity}
          key={event.id}
        />
      ))}
    </div>
  );
};

export default Activity;
