import { useEffect, useRef, memo } from "react";
import { Link } from "react-router-dom";

import useVehiclesListTableRow from "hooks/useVehiclesListTableRow";

import VehicleListTablePriceInput from "components/Tables/VehicleListTable/VehicleListTablePriceInput";

import iconWipBadge from "assets/icons/wip-badge-big-icon.svg";

import utilConstants from "utils/constants";
import utilFormatter from "utils/formatter";
import utilComparisons from "utils/comparisons";

import s from "./index.module.scss";

const compareProps = (prevProps, nextProps) => {
  return utilComparisons.isEqualByValue(prevProps, nextProps);
};

const VehiclesListTableRow = ({ vehicle = {}, vehicleRepository }) => {
  const {
    to = "",
    id = "",
    year = "",
    make = "",
    model = "",
    trim = "",
    vin = null,
    mileage = null,
    days_on_market: daysOnMarket = null,
    price = null,
    our_offered_price: offeredPrice = null,
    lowest_offered_price: lowestOfferedPrice = null,
    source: sourceName = "",
    thumbnail = {},
    work_in_progress: workInProgress = false,
    delisted = false,
  } = vehicle;
  const {
    lastAccessedVehicleId,
    isThumbnailLoadingError,
    onClickVehicleLinkCell,
    onThumbnailLoadingError,
    onOfferedPriceChange,
  } = useVehiclesListTableRow(vehicleRepository);

  const vehicleRowRef = useRef();

  const handleClickVehicleLinkCell = () => {
    onClickVehicleLinkCell(id);
  };

  const handleOfferedPriceChange = (id, updatedFields) => {
    onOfferedPriceChange(id, updatedFields);
  };

  useEffect(() => {
    if (id === lastAccessedVehicleId) {
      const vehicleRowRootElement = vehicleRowRef.current;

      if (vehicleRowRootElement) {
        vehicleRowRootElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, []);

  return (
    <div
      className={s["vehicle"] + (delisted ? " " + s["vehicle--delisted"] : "")}
      ref={vehicleRowRef}
    >
      <Link
        className={s["vehicle__link"]}
        to={to}
        onClick={handleClickVehicleLinkCell}
      >
        <div className={s["vehicle__cell"] + " " + s["vehicle__cell--source"]}>
          <div className={s["vehicle__images-wrapper"]}>
            {Object.keys(utilConstants.sources).includes(sourceName) && (
              <div className={s["vehicle__source-wrapper"]}>
                <img
                  className={s["vehicle__source-icon"]}
                  src={utilConstants.sources[sourceName].imgData.src}
                  srcSet={utilConstants.sources[sourceName].imgData.srcSet}
                  alt={utilConstants.sources[sourceName].imgData.alt}
                />
              </div>
            )}
            {thumbnail?.link && !isThumbnailLoadingError ? (
              <img
                className={s["vehicle__thumbnail"]}
                src={thumbnail.link}
                alt="thumbnail"
                onError={onThumbnailLoadingError}
              />
            ) : (
              <div className={s["vehicle__thumbnail-placeholder"]}></div>
            )}
            {workInProgress && (
              <img
                className={s["vehicle__wip-icon"]}
                src={iconWipBadge}
                alt="wip icon"
              />
            )}
          </div>
        </div>
        <div
          className={
            s["vehicle__cell"] +
            " " +
            s["vehicle__cell--ymmt"] +
            (delisted ? " " + s["vehicle__cell--delisted"] : "")
          }
        >
          <span className={s["vehicle__text"] + " " + s["vehicle__text--ymmt"]}>
            {year ? `${year} ` : ""}
            <span
              className={s["vehicle__text"] + " " + s["vehicle__text--bold"]}
            >
              {[make, model, trim].join(" ")}
            </span>
          </span>
          {delisted ? (
            <div className={s["vehicle__delisted"]}>
              <p className={s["vehicle__delisted-badge"]}>Delisted</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={s["vehicle__cell"]}>
          {typeof mileage === "number" && (
            <span className={s["vehicle__text"]}>
              {utilFormatter.prettyString(mileage, " ")}
            </span>
          )}
        </div>
        <div className={s["vehicle__cell"] + " " + s["vehicle__cell--vin"]}>
          {vin && (
            <span
              className={s["vehicle__text"] + " " + s["vehicle__text--vin"]}
            >
              {vin}
            </span>
          )}
        </div>
        <div className={s["vehicle__cell"]}>
          {typeof daysOnMarket === "number" && (
            <span className={s["vehicle__text"]}>
              {utilFormatter.prettyString(daysOnMarket, " ")}
            </span>
          )}
        </div>
        <div className={s["vehicle__cell"]}>
          {typeof price === "number" && (
            <span className={s["vehicle__text"]}>
              {utilFormatter.prettyString(price, " ")}
            </span>
          )}
        </div>
      </Link>
      <div className={s["vehicle__inputs"]}>
        <div className={s["vehicle__cell"] + " " + s["vehicle__cell--input"]}>
          <VehicleListTablePriceInput
            vehicleId={id}
            className={s["vehicle__price-input"]}
            placeholder="000 000"
            price={offeredPrice}
            priceType="our_offered_price"
            onPriceChange={handleOfferedPriceChange}
            disabled={delisted}
          />
        </div>
        <div className={s["vehicle__cell"] + " " + s["vehicle__cell--input"]}>
          <VehicleListTablePriceInput
            vehicleId={id}
            className={s["vehicle__price-input"]}
            placeholder="000 000"
            price={lowestOfferedPrice}
            priceType="lowest_offered_price"
            onPriceChange={handleOfferedPriceChange}
            disabled={delisted}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(VehiclesListTableRow, compareProps);
