import InputRange from "react-input-range";
import s from "./index.module.scss";

const RangeInput = ({
  maxValue = {},
  minValue = {},
  step = {},
  value = {},
  onChange = () => {},
}) => {
  return (
    <InputRange
      classNames={s}
      maxValue={maxValue}
      minValue={minValue}
      step={step}
      value={value}
      onChange={onChange}
    />
  );
};

export default RangeInput;
