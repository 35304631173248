import Input from "components/inputs/Input";
import UnfilledButton from "components/buttons/UnfilledButton";
import s from "./index.module.scss";

const CreateAndAddToWatchList = ({
  title = "",
  name = "",
  isDisabled = false,
  onChangeTitle = () => {},
  onChangeName = () => {},
  onDone = () => {},
}) => (
  <div className={s["create-and-add-to-watch-list"]}>
    <p className={s["create-and-add-to-watch-list__text"]}>
      Create new watchlist
    </p>
    <Input
      className={s["create-and-add-to-watch-list__input"]}
      placeholder="Title"
      value={title}
      onChange={onChangeTitle}
    />
    <Input
      className={s["create-and-add-to-watch-list__input"]}
      placeholder="Customer’s Name"
      value={name}
      onChange={onChangeName}
    />
    <UnfilledButton
      className={s["create-and-add-to-watch-list__button"]}
      disabled={isDisabled}
      onClick={onDone}
    >
      Create and Add to watchlist
    </UnfilledButton>
  </div>
);

export default CreateAndAddToWatchList;
