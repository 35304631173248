import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FilledButton from "components/buttons/FilledButton";
import CreateEditUserModal from "components/modals/CreateEditUserModal";
import imageLogo from "assets/images/logo.svg";
import iconExit from "assets/icons/exit.svg";
import s from "./index.module.scss";

const AdminHeader = ({ role = null, roleType = {} }) => {
  const firstName = useSelector((state) => state.user.firstName);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleClickPlus = () => setIsOpenModal(true);

  return (
    <>
      <header className={s["admin-header"]}>
        <div
          className={`${s["admin-header__wrapper"]}
        ${
          role === roleType.customer ? s["admin-header__wrapper--customer"] : ""
        }
        `}
        >
          <Link className={s["admin-header__logo-link"]} to="/cabinet">
            <img
              className={s["admin-header__logo"]}
              src={imageLogo}
              alt="Logo"
            />
          </Link>
          {role === roleType.salesRep && (
            <FilledButton
              className={s["admin-header__plus"]}
              onClick={handleClickPlus}
            >
              <div
                className={
                  s["admin-header__plus-line"] +
                  " " +
                  s["admin-header__plus-line--horizontal"]
                }
              ></div>
              <div
                className={
                  s["admin-header__plus-line"] +
                  " " +
                  s["admin-header__plus-line--vertical"]
                }
              ></div>
            </FilledButton>
          )}

          <div className={s["admin-header__greeting-wrapper"]}>
            <p className={s["admin-header__greeting"]}>
              {firstName && `Hello, ${firstName}!`}
            </p>
            <Link className={s["admin-header__logout-link"]} to="/logout">
              <img
                className={s["admin-header__exit-icon"]}
                src={iconExit}
                alt="Exit"
              />
            </Link>
          </div>
        </div>
      </header>
      <div className={s["admin-header__mock"]}></div>
      <CreateEditUserModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </>
  );
};

export default AdminHeader;
