import serviceApi from "services/api";
import utilConstants from "utils/constants";

const baseURL = "vehicles";

const getVehiclePageParams = (
  zipCodeValue,
  sortingValue,
  filtersValue,
  searchTextValue,
  isExtendedFilteringValue,
  pageNumberValue,
  pageSizeValue
) => {
  const zipCode = {};
  if (zipCodeValue) {
    zipCode.zip_code = zipCodeValue;
  }

  const sorting = {
    sort_field: sortingValue.field,
    sort_order: sortingValue.order,
  };

  const filters = {
    year_from: filtersValue.year.from,
    year_to: filtersValue.year.to,
    vehicles_type: filtersValue.isRegularSources ? "regular" : "powersports",
  };

  if (filtersValue.distanceRange) {
    filters.max_distance = filtersValue.distanceRange;
  }

  if (filtersValue.transmissionType) {
    filters.transmission = filtersValue.transmissionType;
  }

  if (filtersValue.fuelType) {
    filters.fuel_type = filtersValue.fuelType;
  }

  if (filtersValue.colors) {
    filters.colors = filtersValue.colors.join(",");
  }

  if (isExtendedFilteringValue) {
    filters.progress_state = filtersValue.progressState;
    filters.source_names =
      filtersValue.sources.length === Object.keys(utilConstants.sources).length
        ? "all"
        : filtersValue.sources.join(",");
    filters.required_values = filtersValue.requiredValues.join(",");
    filters.include_values = filtersValue.includeValues.join(",");
  }

  if (filtersValue.mileage.from) {
    filters.mileage_from = filtersValue.mileage.from;
  }

  if (filtersValue.mileage.to) {
    filters.mileage_to = filtersValue.mileage.to;
  }

  if (filtersValue.price.from) {
    filters.price_from = filtersValue.price.from;
  }

  if (filtersValue.price.to) {
    filters.price_to = filtersValue.price.to;
  }

  if (searchTextValue) {
    filters.search_text = searchTextValue;
  }

  const paging = {
    page: pageNumberValue,
    page_size: pageSizeValue,
  };

  return {
    paging,
    zipCode,
    sorting,
    filters,
  };
};

const getVehiclesPage = (
  zipCode,
  sorting,
  filters,
  searchText,
  isExtendedFiltering,
  pageNumber,
  pageSize,
  searchedAt
) => {
  const vehiclePageParams = getVehiclePageParams(
    zipCode,
    sorting,
    filters,
    searchText,
    isExtendedFiltering,
    pageNumber,
    pageSize
  );

  return serviceApi.get(baseURL, {
    ...vehiclePageParams.zipCode,
    ...vehiclePageParams.paging,
    ...vehiclePageParams.filters,
    ...vehiclePageParams.sorting,
    ...(isExtendedFiltering && { searched_at: searchedAt }),
  });
};

const getVehicle = (id, zipCode) => {
  return serviceApi.get(`${baseURL}/${id}`, {
    zip_code: zipCode,
  });
};

const getCsv = (
  zipCode,
  sorting,
  filters,
  searchText,
  isExtendedFiltering,
  csvType
) => {
  const vehiclePageParams = getVehiclePageParams(
    zipCode,
    sorting,
    filters,
    searchText,
    isExtendedFiltering
  );

  return serviceApi.getFile(`${baseURL}/csv`, {
    ...vehiclePageParams.zipCode,
    ...vehiclePageParams.filters,
    ...vehiclePageParams.sorting,

    csv_type: csvType,
  });
};

const updateVehicle = (vehicleId, updatedFields) =>
  serviceApi.patch(`${baseURL}/${vehicleId}`, updatedFields);

const addComment = (vehicleId, comment) =>
  serviceApi.post(`${baseURL}/${vehicleId}/comments`, { text: comment });

const getConversations = (vehicleId) =>
  serviceApi.get(`${baseURL}/${vehicleId}/conversations`);

const getConversation = (vehicleId, userId) =>
  serviceApi.get(`${baseURL}/${vehicleId}/conversations/${userId}`);

const vehiclesGateway = {
  getVehiclesPage,
  getVehicle,
  getCsv,
  updateVehicle,
  addComment,
  getConversations,
  getConversation,
};

export default vehiclesGateway;
