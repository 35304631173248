import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const VehiclePrice = ({ price = null, delisted = false }) => {
  if (!price) {
    return <></>;
  }

  return (
    <div className={s["vehicle-price"]}>
      <p
        className={
          s["vehicle-price__price"] +
          (delisted ? ` ${s["vehicle-price__price--delisted"]}` : "")
        }
      >
        ${utilFormatter.prettyString(price, ",")}
      </p>
      <p
        className={
          s["vehicle-price__text"] +
          (delisted ? ` ${s["vehicle-price__text--delisted"]}` : "")
        }
      >
        Estimated price including transport, inspections, cleaning and safety
        certification. <br className={s["vehicle-price__br"]} />
        Price may vary if a better deal is negotiated from seller. Plus taxes
        and licensing.
      </p>
    </div>
  );
};

export default VehiclePrice;
