import s from "./index.module.scss";

const ExpandIndicator = ({ className = "", src, isOpen = false }) => (
  <img
    src={src}
    alt="Toggle dropdown"
    className={
      s["expand-indicator"] +
      (isOpen ? ` ${s["expand-indicator--open"]}` : "") +
      (className ? ` ${className}` : "")
    }
  />
);

export default ExpandIndicator;
