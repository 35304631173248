import { useMemo } from "react";
import Select from "react-select";
import ExpandIndicator from "components/ExpandIndicator";
import iconDropdown from "assets/icons/dropdown.svg";
import s from "./index.module.scss";

const DisplayConvosSelect = ({
  options = [],
  selectedValue = "",
  onChange = () => {},
}) => {
  const selectStyles = useMemo(() => {
    return {
      control: (provided) => ({
        ...provided,
        border: "unset",
        borderBottom: "1px solid #1c1c1c !important",
        borderRadius: "unset",
        boxShadow: "unset",
        cursor: "pointer",
        width: "100%",
        height: 19,
        minHeight: "unset",
        display: "grid",
        gridTemplateColumns: "1fr 12px",
        gridColumnGap: 5,
        padding: 0,
        backgroundColor: "transparent",
      }),

      valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        fontSize: 14,
        display: "grid",
        gridTemplateTolumns: "1fr 0",
        alignItems: "center",
      }),

      singleValue: (provided) => ({
        ...provided,
        overflow: "unset",
        fontSize: 14,
        margin: 0,
      }),

      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),

      menu: (provided) => ({
        ...provided,
        margin: "2px 0 0",
        boxShadow: "unset",
        borderRadius: 0,
        zIndex: 4,
      }),

      menuList: (provided) => ({
        ...provided,
        padding: 0,
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
        border: "solid 0.5px #7f7f7f",
      }),

      option: (provided) => ({
        ...provided,
        height: 36,
        fontSize: 14,
        width: "100%",
        padding: "0 0 0 10px",
        display: "grid",
        alignContent: "center",
        color: "inherit",
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#cfdeff",
        },
      }),

      input: (provided) => ({
        ...provided,
        margin: 0,
      }),
    };
  }, []);

  return (
    <div className={s["display-convos-select"]}>
      <span className={s["display-convos-select__label"]}>Display convos</span>
      <Select
        options={options}
        value={
          options.filter(
            (selectOption) => selectOption.value === selectedValue
          )[0]
        }
        onChange={onChange}
        isSearchable={false}
        components={{
          DropdownIndicator: ({ selectProps: { menuIsOpen } }) => (
            <ExpandIndicator src={iconDropdown} isOpen={menuIsOpen} />
          ),
        }}
        styles={selectStyles}
      />
    </div>
  );
};

export default DisplayConvosSelect;
