import imageSourceWhosaleExpress from "assets/images/sources/wholesale-express.png";
import imageSourceWhosaleExpress2x from "assets/images/sources/wholesale-express@2x.png";
import imageSourceWhosaleExpress3x from "assets/images/sources/wholesale-express@3x.png";
import imageSourceAuction123 from "assets/images/sources/auction123.png";
import imageSourceAuction1232x from "assets/images/sources/auction123@2x.png";
import imageSourceAuction1233x from "assets/images/sources/auction123@3x.png";
import imageSourceKijiji from "assets/images/sources/kijiji.png";
import imageSourceKijiji2x from "assets/images/sources/kijiji@2x.png";
import imageSourceKijiji3x from "assets/images/sources/kijiji@3x.png";
import imageSourceLespac from "assets/images/sources/lespac.png";
import imageSourceLespac2x from "assets/images/sources/lespac@2x.png";
import imageSourceLespac3x from "assets/images/sources/lespac@3x.png";
import imageSourceAutotrader from "assets/images/sources/autotrader.png";
import imageSourceAutotrader2x from "assets/images/sources/autotrader@2x.png";
import imageSourceAutotrader3x from "assets/images/sources/autotrader@3x.png";
import imageSourceCraigslist from "assets/images/sources/craigslist.png";
import imageSourceCraigslist2x from "assets/images/sources/craigslist@2x.png";
import imageSourceCraigslist3x from "assets/images/sources/craigslist@3x.png";
import imageSourceLeasecosts from "assets/images/sources/leasecosts.png";
import imageSourceLeasecosts2x from "assets/images/sources/leasecosts@2x.png";
import imageSourceLeasecosts3x from "assets/images/sources/leasecosts@3x.png";
import imageSourceFacebookMarketplace from "assets/images/sources/facebook-marketplace.png";
import imageSourceFacebookMarketplace2x from "assets/images/sources/facebook-marketplace@2x.png";
import imageSourceFacebookMarketplace3x from "assets/images/sources/facebook-marketplace@3x.png";
import imageSourceCastanet from "assets/images/sources/castanet.png";
import imageSourceCastanet2x from "assets/images/sources/castanet@2x.png";
import imageSourceCastanet3x from "assets/images/sources/castanet@3x.png";

const roles = {
  customer: {
    name: "customer",
    isLoadVehicleStats: false,
    isExtendedFiltering: false,
    isAbleToAddToWatchList: false,
  },
  employee: {
    name: "employee",
    isLoadVehicleStats: true,
    isExtendedFiltering: true,
    isAbleToAddToWatchList: true,
  },
  admin: {
    name: "admin",
    isLoadVehicleStats: false,
    isExtendedFiltering: false,
    isAbleToAddToWatchList: false,
  },
};

const sortingVehiclesOptions = [
  { value: { field: "created_at", order: "desc" }, label: "newest first" },
  { value: { field: "price", order: "asc" }, label: "price: low to high" },
  { value: { field: "price", order: "desc" }, label: "price: high to low" },
  { value: { field: "year", order: "asc" }, label: "year: low to high" },
  { value: { field: "year", order: "desc" }, label: "year: high to low" },
  { value: { field: "mileage", order: "asc" }, label: "mileage: low to high" },
  { value: { field: "mileage", order: "desc" }, label: "mileage: high to low" },
  {
    value: { field: "dist", order: "asc" },
    label: "distance from postal code",
  },
];

const vehicleTransmissionOptions = [
  {
    value: "all",
    label: "all",
  },
  {
    value: "automatic",
    label: "automatic",
  },
  {
    value: "manual",
    label: "manual",
  },
  {
    value: "other",
    label: "other",
  },
];

const vehicleFuelOptions = [
  {
    value: "all",
    label: "all",
  },
  {
    value: "gasoline",
    label: "gasoline",
  },
  {
    value: "diesel",
    label: "diesel",
  },
  {
    value: "electric",
    label: "electric",
  },
  {
    value: "hybrid",
    label: "hybrid",
  },
  {
    value: "other",
    label: "other",
  },
];

const vehicleProgressStateOptions = [
  {
    value: "all",
    label: "all vehicles",
  },
  {
    value: "work_in_progress",
    label: "work in progress",
  },
  {
    value: "work_not_in_progress",
    label: "work not in progress",
  },
];

const nextYear = new Date().getFullYear() + 1;

const filtersVehiclesYearOptions = new Array(nextYear - 1980 + 1)
  .fill(0)
  .map((_, index) => ({
    value: nextYear - index,
    label: `${nextYear - index}`,
  }));

const paginationOptions = [
  { value: 10, label: "10 lines" },
  { value: 50, label: "50 lines" },
  { value: 100, label: "100 lines" },
];

const vehicleInfoTabs = [
  {
    title: "Overview",
    value: "overview",
    optional: false,
    userRoles: [roles.employee.name, roles.customer.name],
  },
  {
    title: "Seller",
    value: "dealer",
    optional: true,
    userRoles: [roles.employee.name],
  },
  {
    title: "Lead",
    value: "lead",
    optional: true,
    userRoles: [roles.employee.name],
  },
  {
    title: "Activity",
    value: "activity",
    optional: false,
    userRoles: [roles.employee.name],
  },
];

const sources = {
  "wholesale-express": {
    imgData: {
      src: imageSourceWhosaleExpress,
      srcSet: `${imageSourceWhosaleExpress2x} 2x, ${imageSourceWhosaleExpress3x} 3x`,
      alt: "WholeSale Express Logo",
    },
    isAbleToDelete: false,
    defaultTabValue: vehicleInfoTabs[0].value,
    label: "Wholesale Express",
    isConvoSupportedViaEmail: false,
    isConvoSupportedViaPhoneNumber: true,
  },
  auction123: {
    imgData: {
      src: imageSourceAuction123,
      srcSet: `${imageSourceAuction1232x} 2x, ${imageSourceAuction1233x} 3x`,
      alt: "Adesa Logo",
    },
    isAbleToDelete: false,
    defaultTabValue: vehicleInfoTabs[0].value,
    label: "Adesa",
    isConvoSupportedViaEmail: false,
    isConvoSupportedViaPhoneNumber: true,
  },
  kijiji: {
    imgData: {
      src: imageSourceKijiji,
      srcSet: `${imageSourceKijiji2x} 2x, ${imageSourceKijiji3x} 3x`,
      alt: "Kijiji Logo",
    },
    isAbleToDelete: false,
    defaultTabValue: vehicleInfoTabs[0].value,
    label: "Kijiji",
    isConvoSupportedViaEmail: false,
    isConvoSupportedViaPhoneNumber: true,
  },
  lespac: {
    imgData: {
      src: imageSourceLespac,
      srcSet: `${imageSourceLespac2x} 2x, ${imageSourceLespac3x} 3x`,
      alt: "LesPAC Logo",
    },
    isAbleToDelete: false,
    defaultTabValue: vehicleInfoTabs[0].value,
    label: "LesPAC",
    isConvoSupportedViaEmail: false,
    isConvoSupportedViaPhoneNumber: true,
  },
  autotrader: {
    imgData: {
      src: imageSourceAutotrader,
      srcSet: `${imageSourceAutotrader2x} 2x, ${imageSourceAutotrader3x} 3x`,
      alt: "Autotrader Logo",
    },
    isAbleToDelete: false,
    defaultTabValue: vehicleInfoTabs[0].value,
    label: "Autotrader.ca",
    isConvoSupportedViaEmail: true,
    isConvoSupportedViaPhoneNumber: true,
  },
  craigslist: {
    imgData: {
      src: imageSourceCraigslist,
      srcSet: `${imageSourceCraigslist2x} 2x, ${imageSourceCraigslist3x} 3x`,
      alt: "Craigslist Logo",
    },
    isAbleToDelete: false,
    defaultTabValue: vehicleInfoTabs[0].value,
    label: "Craigslist",
    isConvoSupportedViaEmail: false,
    isConvoSupportedViaPhoneNumber: true,
  },
  leasecosts: {
    imgData: {
      src: imageSourceLeasecosts,
      srcSet: `${imageSourceLeasecosts2x} 2x, ${imageSourceLeasecosts3x} 3x`,
      alt: "Leasecosts Logo",
    },
    isAbleToDelete: false,
    defaultTabValue: vehicleInfoTabs[0].value,
    label: "Leasecosts",
    isConvoSupportedViaEmail: false,
    isConvoSupportedViaPhoneNumber: true,
  },
  fb_marketplace: {
    imgData: {
      src: imageSourceFacebookMarketplace,
      srcSet: `${imageSourceFacebookMarketplace2x} 2x, ${imageSourceFacebookMarketplace3x} 3x`,
      alt: "Facebook Marketplace Logo",
    },
    isAbleToDelete: false,
    defaultTabValue: vehicleInfoTabs[0].value,
    label: "Facebook Marketplace",
    isConvoSupportedViaEmail: false,
    isConvoSupportedViaPhoneNumber: true,
  },
  castanet: {
    imgData: {
      src: imageSourceCastanet,
      srcSet: `${imageSourceCastanet2x} 2x, ${imageSourceCastanet3x} 3x`,
      alt: "Castanet Logo",
    },
    isAbleToDelete: false,
    defaultTabValue: vehicleInfoTabs[0].value,
    label: "Castanet",
    isConvoSupportedViaEmail: false,
    isConvoSupportedViaPhoneNumber: true,
  },
};

const regularSources = {
  "wholesale-express": sources["wholesale-express"],
  kijiji: sources.kijiji,
  lespac: sources.lespac,
  autotrader: sources.autotrader,
  craigslist: sources.craigslist,
  leasecosts: sources.leasecosts,
  castanet: sources.castanet,
};

const powersportsSources = {
  autotrader: sources.autotrader,
  kijiji: sources.kijiji,
};

const errorMessages = {
  emailValidationError: "Incorrect email",
  zipCodeValidationError: "Invalid postal code format",
  passwordValidationError:
    "Password must be at least 6 characters long without spaces",
  matchPasswordError: "Passwords do not match",
  passwordError: "Incorrect password",
};

const successMessages = {
  changePassword: "Password successfully changed.",
  editProfile: "Profile successfully updated.",
};

const forgotPasswordEmailFields = {
  address: "info@autoagents.io",
  subject: "I need help with resetting my password.",
};

const requiredValues = {
  vin: {
    label: "Has VIN",
  },
  phone_number: {
    label: "Has phone number",
  },
};

const includeValues = {
  delisted: {
    label: "Is active listing",
    reverse: true,
  },
};

const distanceFilter = {
  maxDistance: 1000,
  minDistance: 50,
  stepForDistanceSearch: 50,
  defaultDistance: 1000,
};

const colorFilter = [
  {
    background: "#f7f7f7",
    tickColor: "black",
    border: "solid 1px #e0e0e0",
    title: "white",
    value: "white",
  },
  {
    background:
      "linear-gradient(137deg, #d0d0d0 13%, #e7e7e7 38%, #f5f5f5 56%, #c4c4c4 84%)",
    tickColor: "black",
    border: "solid 1px #e0e0e0",
    title: "silver",
    value: "silver",
  },
  {
    background: "#747474",
    tickColor: "white",
    border: "border: solid 1px #707070",
    title: "grey",
    value: "grey",
  },
  {
    background: "#000",
    tickColor: "white",
    border: "none",
    title: "black",
    value: "black",
  },
  {
    background:
      "linear-gradient(137deg, #d5bd8a 13%, #eee3cf 38%, #f8f2e4 56%, #e1cfab 84%)",
    tickColor: "black",
    border: "none",
    title: "golden",
    value: "golden",
  },
  {
    background: "#d6c098",
    tickColor: "white",
    border: "none",
    title: "beige",
    value: "beige",
  },
  {
    background: "#744928",
    tickColor: "white",
    border: "none",
    title: "brown",
    value: "brown",
  },
  {
    background: "#d10009",
    tickColor: "white",
    border: "none",
    title: "red",
    value: "red",
  },
  {
    background: "#ff6c00",
    tickColor: "white",
    border: "none",
    title: "orange",
    value: "orange",
  },
  {
    background: "#f2c91f",
    tickColor: "white",
    border: "none",
    title: "yellow",
    value: "yellow",
  },
  {
    background: "#438942",
    tickColor: "white",
    border: "none",
    title: "green",
    value: "green",
  },
  {
    background: "#0f8c8c",
    tickColor: "white",
    border: "none",
    title: "teal",
    value: "cyan",
  },
  {
    background: "#0022d9",
    tickColor: "white",
    border: "none",
    title: "blue",
    value: "blue",
  },
  {
    background: "#4e1796",
    tickColor: "white",
    border: "none",
    title: "purple",
    value: "purple",
  },
];

const exportCsvButtons = [
  {
    key: "general",
    name: "to general CSV",
  },
  {
    key: "accutrade",
    name: "to AccuTrade CSV",
  },
];

const convosVehiclesExportCsvButtons = [
  {
    key: "general",
    name: "to general CSV",
  },
  {
    key: "accutrade",
    name: "to AccuTrade CSV",
  },
];

const autoConvoNotSupportedTypes = ["powersports"];

const displayConvosSelectOptions = [
  { value: "unread", label: "unread" },
  { value: "sinceYesterday", label: "since yesterday" },
  { value: "past7Days", label: "for the past 7 days" },
  { value: "all", label: "all" },
];

const util = {
  sortingVehiclesOptions,
  vehicleTransmissionOptions,
  filtersVehiclesYearOptions,
  vehicleFuelOptions,
  vehicleProgressStateOptions,
  paginationOptions,
  sources,
  regularSources,
  powersportsSources,
  vehicleInfoTabs,
  errorMessages,
  successMessages,
  forgotPasswordEmailFields,
  roles,
  requiredValues,
  includeValues,
  distanceFilter,
  colorFilter,
  exportCsvButtons,
  convosVehiclesExportCsvButtons,
  autoConvoNotSupportedTypes,
  displayConvosSelectOptions,
};

export default util;
