import { createSlice } from "@reduxjs/toolkit";
import hotlistStateStorageGateway from "gateways/storage/hotlistStateStorage";

const isGridView = hotlistStateStorageGateway.getIsGridView();

export const hotlistSlice = createSlice({
  name: "hotlist",
  initialState: {
    page: 0,
    pageSize: 60,
    pageCount: 1,
    isLoading: true,
    isGridView: isGridView !== null ? JSON.parse(isGridView) : true,
    vehicles: [],
    lastAccessedVehicleId: "",
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsGridView: (state, action) => {
      state.isGridView = action.payload;
    },
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
    setVehicleFields: (state, action) => {
      state.vehicles = state.vehicles.map((vehicle) => {
        if (vehicle.id === action.payload.id) {
          return {
            ...vehicle,
            ...action.payload.updatedFields,
            work_in_progress: true,
          };
        }
        return vehicle;
      });
    },
    setLastAccessedVehicleId: (state, action) => {
      state.lastAccessedVehicleId = action.payload;
    },
    resetVehiclesAndPaginationState: (state) => {
      state.page = 0;
      state.pageSize = 60;
      state.pageCount = 1;
      state.isLoading = true;
      state.vehicles = [];
      state.lastAccessedVehicleId = "";
    },
    resetHotlistState: (state) => {
      state.page = 0;
      state.pageSize = 60;
      state.pageCount = 1;
      state.isLoading = true;
      state.isGridView = true;
      state.vehicles = [];
      state.lastAccessedVehicleId = "";
    },
  },
});

export const {
  setPage,
  setPageCount,
  setIsLoading,
  setIsGridView,
  setVehicles,
  setVehicleFields,
  setLastAccessedVehicleId,
  resetVehiclesAndPaginationState,
  resetHotlistState,
} = hotlistSlice.actions;

export default hotlistSlice.reducer;
