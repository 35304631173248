import { useState, useEffect, useRef } from "react";

import utilConstants from "utils/constants";
import serviceVehicles from "services/vehicles";

import s from "./index.module.scss";

const VehiclesExportCsvButton = ({ children = <></> }) => {
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const csvExportButtonsListRef = useRef(null);
  const csvExportButtonRef = useRef(null);

  const handleClickExportCsv = () => {
    setIsVisible((current) => !current);
  };

  const handleGetCsv = (csvType) => {
    if (isLoadingCsv) {
      return;
    }

    setIsLoadingCsv(true);
    serviceVehicles.getCsv(csvType).finally(() => {
      setIsLoadingCsv(false);
      setIsVisible(false);
    });
  };

  useEffect(() => {
    if (!isVisible) return;

    const handleBodyClick = (e) => {
      if (e.target === csvExportButtonRef.current) return;

      if (!csvExportButtonsListRef.current?.contains(e.target)) {
        setIsVisible(false);
      }
    };

    document.body.addEventListener("click", handleBodyClick, true);
    return () =>
      document.body.removeEventListener("click", handleBodyClick, true);
  }, [isVisible]);

  return (
    <div className={s["export-csv"]}>
      <button
        className={s["export-csv__btn"]}
        onClick={handleClickExportCsv}
        disabled={isLoadingCsv}
        ref={csvExportButtonRef}
      >
        {children}
      </button>
      <ul
        ref={csvExportButtonsListRef}
        className={
          s["export-csv__list"] +
          (isVisible ? ` ${s["export-csv__list--visible"]}` : "")
        }
      >
        {utilConstants.exportCsvButtons.map((button) => {
          return (
            <li
              key={button.key}
              className={
                s["export-csv__list-item"] +
                (isLoadingCsv ? ` ${s["export-csv__list-item--disabled"]}` : "")
              }
              onClick={() => handleGetCsv(button.key)}
            >
              {button.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default VehiclesExportCsvButton;
