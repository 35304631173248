import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import utilConstants from "utils/constants";
import toaster from "utils/toaster";
import Convo from "components/Convo";
import Switch from "components/Switch";
import StartNewConvoModal from "components/modals/StartNewConvoModal";
import ConfirmModal from "components/modals/ConfirmModal";
import iconOut from "../../assets/icons/out.svg";
import penIcon from "../../assets/icons/pen.svg";
import saveIcon from "../../assets/icons/icon-save.svg";
import deleteIcon from "../../assets/icons/cross-black-bold.svg";
import serviceVehicles from "services/vehicles";
import serviceAutoConvos from "services/autoConvos";
import { setIsLoading, setSeller } from "slicers/conversation";
import s from "./index.module.scss";

const Seller = ({
  seller,
  isOthersConversations = false,
  vehicleType = null,
}) => {
  const vehicleMessages = useSelector(
    (state) => state.conversations.vehicleMessages
  );

  const isLoading = useSelector((state) => state.conversation.isLoading);
  const twilioPhoneNumber = useSelector(
    (state) => state.user.twilioPhoneNumber
  );

  const storedSeller = useSelector((state) =>
    Object.keys(state.conversation.seller).length
      ? state.conversation.seller
      : seller
  );

  const [vehicleId, setVehicleId] = useState(seller.vehicleId);
  const [isOpenStartNewConvoModal, setIsOpenStartNewConvoModal] =
    useState(false);
  const [isOpenStartAutoConvoModal, setIsOpenStartAutoConvoModal] =
    useState(false);
  const [isAutoConvo, setIsAutoConvo] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);

  const [localPhoneNumber, setLocalPhoneNumber] = useState(
    storedSeller.phoneNumber || ""
  );
  const [isInputError, setIsInputError] = useState(false);

  const inputRef = useRef(null);

  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const messageType = searchParams.get("message_type");

  const isConvoSupportedViaEmail =
    utilConstants.sources[storedSeller.vehicleSource]?.isConvoSupportedViaEmail;

  const isConvoSupportedViaPhoneNumber =
    utilConstants.sources[storedSeller.vehicleSource]
      ?.isConvoSupportedViaPhoneNumber &&
    storedSeller.phoneNumber &&
    twilioPhoneNumber;

  const isConvoSupported =
    isConvoSupportedViaEmail || isConvoSupportedViaPhoneNumber;

  const [isConvoViaPhoneNumber, setConvoViaPhoneNumber] = useState(
    storedSeller.vehicleSource === "autotrader"
      ? isConvoSupportedViaEmail &&
          isConvoSupportedViaPhoneNumber &&
          messageType !== "email"
      : true
  );

  const isViewAllConvosVisible = isConvoSupported && isOthersConversations;

  const isStartNewConvoVisible =
    isConvoSupported &&
    vehicleMessages.length &&
    !isLoading &&
    !isConvoViaPhoneNumber &&
    !seller.delisted &&
    !isAutoConvo;

  const isAutoConvoSwitchVisible =
    isConvoSupportedViaPhoneNumber &&
    isConvoViaPhoneNumber &&
    !utilConstants.autoConvoNotSupportedTypes.includes(vehicleType);

  const isConvoSwitchVisible =
    isConvoSupportedViaEmail && isConvoSupportedViaPhoneNumber;

  const isRightSideVisible =
    isViewAllConvosVisible ||
    isStartNewConvoVisible ||
    isConvoSwitchVisible ||
    isAutoConvoSwitchVisible;

  const handleCloseStartNewConvoModal = async (isAgreed) => {
    setIsOpenStartNewConvoModal(false);

    if (isAgreed) {
      setVehicleId("");
      await serviceVehicles.deleteConvo(vehicleId);
      setVehicleId(seller.vehicleId);
    }
  };

  const handleCloseStartAutoConvoModal = () => {
    setIsOpenStartAutoConvoModal(false);
  };

  const handleAutoConvoSwitchClick = () => {
    if (isLoading) return;

    if (isAutoConvo) {
      dispatch(setIsLoading(true));
      handleStopAutoConvo().finally(() => dispatch(setIsLoading(false)));
      return;
    }

    setIsOpenStartAutoConvoModal(true);
    return;
  };

  const handleConvoSwitchOptionsClick = (option) => {
    if (isLoading) return;

    const isDesktop = matchMedia("(hover: hover) and (pointer: fine)").matches;
    if (!isDesktop) {
      setConvoViaPhoneNumber((current) => !current);
      return;
    }

    const handlers = {
      sms: () => {
        setConvoViaPhoneNumber((current) => {
          if (current) return current;
          return !current;
        });
      },
      email: () => {
        setConvoViaPhoneNumber((current) => {
          if (current) return !current;
          return current;
        });
      },
    };

    switch (option) {
      case "sms":
        handlers["sms"]();
        return;
      case "email":
        handlers["email"]();
        return;
      default:
        return;
    }
  };

  const handleClickAddPhoneNumber = () => {
    setIsEditingMode(true);
  };

  const handleClickEditPhoneNumber = () => {
    setIsEditingMode(true);
  };

  const handleChangePhoneNumber = ({ target: { value } }) => {
    setIsInputError(value.length === 0);
    setLocalPhoneNumber(value);
  };

  const handleClickExitEditingModeButton = () => {
    setLocalPhoneNumber(storedSeller.phoneNumber || "");
    setIsEditingMode(false);
    setIsInputError(false);
  };

  const handleClickSavePhoneNumber = () => {
    if (isInputError) return;
    if (isLoading) return;

    if (localPhoneNumber.length === 0) {
      setIsInputError(true);
      return;
    }

    if (localPhoneNumber === storedSeller.phoneNumber) {
      setIsEditingMode(false);
      return;
    }

    dispatch(setIsLoading(true));
    serviceVehicles
      .updateVehicle(vehicleId, { phone_number: localPhoneNumber })
      .then((vehicle) => {
        const updatedPhoneNumber = vehicle?.dealer?.phone_number
          ? vehicle.dealer.phone_number
          : "";
        setIsEditingMode(false);
        dispatch(
          setSeller({ ...storedSeller, phoneNumber: updatedPhoneNumber })
        );
        setLocalPhoneNumber(updatedPhoneNumber);
      })
      .catch(() => {
        setIsInputError(true);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const handleStartAutoConvo = () =>
    serviceAutoConvos
      .start(vehicleId)
      .then(() => {
        setIsAutoConvo(true);
      })
      .catch(() =>
        toaster.displayErrorBottom(
          "Failed to start auto convo, please try again."
        )
      );

  const handleStopAutoConvo = () =>
    serviceAutoConvos
      .stop(vehicleId)
      .then(() => {
        setIsAutoConvo(false);
      })
      .catch(() =>
        toaster.displayErrorBottom(
          "Failed to stop auto convo, please try again."
        )
      );

  const handleConfirmStartAutoConvo = () => handleStartAutoConvo();

  useEffect(() => {
    if (!isEditingMode) return;
    inputRef.current.focus();
  }, [isEditingMode]);

  useEffect(() => {
    if (!isAutoConvoSwitchVisible) return;

    const fetchAutoConvoStatus = () =>
      serviceAutoConvos
        .getStatus(vehicleId)
        .then((autoConvoStatus) => setIsAutoConvo(autoConvoStatus.is_active))
        .catch(() => {});

    fetchAutoConvoStatus();

    const interval = setInterval(fetchAutoConvoStatus, 1000 * 60);

    return () => clearInterval(interval);
  }, [isAutoConvoSwitchVisible]);

  return (
    <div className={s["seller"]}>
      <div
        className={
          s["seller__info"] +
          (isConvoSupported ? " " + s["seller__info--convo-visible"] : "")
        }
      >
        <div
          className={
            s["seller__info-content"] +
            (isRightSideVisible
              ? ""
              : " " + s["seller__info-content--fullscreen"])
          }
        >
          <div>
            <div
              className={
                s["seller__info-content-list"] +
                " " +
                s["seller__info-content-list--left"] +
                (isRightSideVisible
                  ? ""
                  : " " + s["seller__info-content-list--left-fullscreen"])
              }
            >
              <div className={s["seller__info-content-list-item"]}>
                {storedSeller.vdpUrl && (
                  <a
                    className={
                      s["seller__link"] + " " + s["seller__link--vdp-url"]
                    }
                    href={storedSeller.vdpUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className={s["seller__link-text"]}>Original ad</p>
                    <img
                      className={s["seller__external_link-icon"]}
                      src={iconOut}
                      alt="link"
                    />
                  </a>
                )}
              </div>
              {storedSeller.dealerName && (
                <div className={s["seller__info-content-list-item"]}>
                  {storedSeller.dealerName && (
                    <p
                      className={
                        s["seller__info-text"] +
                        " " +
                        s["seller__info-text--dealer-name"]
                      }
                    >
                      {storedSeller.dealerName}
                    </p>
                  )}
                </div>
              )}
              <div className={s["seller__info-content-list-item"]}>
                {storedSeller.phoneNumber || isEditingMode ? (
                  <div
                    className={
                      s["seller__info-phone-number-wrapper"] +
                      (isEditingMode
                        ? " " +
                          s[
                            "seller__info-phone-number-wrapper--edit-phone-number"
                          ]
                        : "")
                    }
                  >
                    {isEditingMode ? (
                      <>
                        <input
                          className={
                            s["seller__info-phone-number-edit-input"] +
                            (isInputError
                              ? " " +
                                s["seller__info-phone-number-edit-input--error"]
                              : "")
                          }
                          value={localPhoneNumber}
                          onChange={handleChangePhoneNumber}
                          ref={inputRef}
                        />
                        <div className={s["seller__phone-number-btns"]}>
                          <button
                            className={s["seller__phone-number-btn"]}
                            onClick={handleClickSavePhoneNumber}
                            disabled={isLoading || isInputError}
                          >
                            <img
                              className={s["seller__btn-icon"]}
                              alt="save phone number"
                              src={saveIcon}
                            />
                          </button>
                          <button
                            className={s["seller__phone-number-btn"]}
                            onClick={handleClickExitEditingModeButton}
                            disabled={isLoading}
                          >
                            <img
                              className={s["seller__btn-icon"]}
                              alt="exit phone number editing mode"
                              src={deleteIcon}
                            />
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <a
                          className={s["seller__info-phone-number-link"]}
                          href={`tel:${storedSeller.phoneNumber}`}
                        >
                          <span className={s["seller__info-text"]}>
                            {storedSeller.phoneNumber}
                          </span>
                        </a>
                        <button
                          className={s["seller__phone-number-btn"]}
                          onClick={handleClickEditPhoneNumber}
                          disabled={isLoading}
                        >
                          <img
                            className={s["seller__btn-icon"]}
                            alt="edit phone number"
                            src={penIcon}
                          />
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  <button
                    className={
                      s["seller__phone-number-btn"] +
                      " " +
                      s["seller__phone-number-btn--add-btn"]
                    }
                    onClick={handleClickAddPhoneNumber}
                  >
                    Add phone number
                  </button>
                )}
              </div>
              <img
                className={s["seller__vehicle-source"]}
                {...utilConstants.sources[storedSeller.vehicleSource]?.imgData}
              />
            </div>
          </div>
          {isRightSideVisible ? (
            <div>
              <div
                className={
                  s["seller__info-content-list"] +
                  " " +
                  s["seller__info-content-list--right"]
                }
              >
                {isStartNewConvoVisible || isViewAllConvosVisible ? (
                  <div
                    className={
                      s["seller__info-content-list-item"] +
                      (isStartNewConvoVisible && isViewAllConvosVisible
                        ? " " + s["seller__info-content-list-item--buttons"]
                        : "")
                    }
                  >
                    {isStartNewConvoVisible ? (
                      <button
                        className={
                          s["seller__button"] +
                          " " +
                          s["seller__button--new-convo"]
                        }
                        onClick={() => setIsOpenStartNewConvoModal(true)}
                      >
                        Start new
                      </button>
                    ) : (
                      <></>
                    )}
                    {isViewAllConvosVisible ? (
                      <Link
                        className={
                          s["seller__link"] +
                          " " +
                          s["seller__link--view-all-convos"]
                        }
                        to={`/employee/vehicles/${vehicleId}/all-convos`}
                      >
                        <p className={s["seller__link-text"]}>All convos</p>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <>
                    <div className={s["seller__info-content-list-item"]}></div>
                  </>
                )}

                {isAutoConvoSwitchVisible ? (
                  <div className={s["seller__info-content-list-item"]}>
                    <div
                      className={
                        s["seller__switch-wrapper"] +
                        " " +
                        s["seller__switch-wrapper--autoconvo"]
                      }
                    >
                      <span
                        className={s["seller__switch-option"]}
                        onClick={handleAutoConvoSwitchClick}
                      >
                        Auto Convo
                      </span>
                      <Switch
                        isChecked={isAutoConvo}
                        onChange={handleAutoConvoSwitchClick}
                        backgroundColor={isAutoConvo ? "#5c57ff" : "#9a9a9a"}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={s["seller__info-content-list-item"]}></div>
                )}

                {isConvoSwitchVisible && (
                  <div className={s["seller__info-content-list-item"]}>
                    <div className={s["seller__switch-wrapper"]}>
                      <span
                        className={s["seller__switch-option"]}
                        onClick={() => handleConvoSwitchOptionsClick("sms")}
                      >
                        via SMS
                      </span>
                      <Switch
                        isChecked={!isConvoViaPhoneNumber}
                        onChange={() => {
                          if (isLoading) return;
                          setConvoViaPhoneNumber((current) => !current);
                        }}
                        backgroundColor="#5c57ff"
                      />
                      <span
                        className={s["seller__switch-option"]}
                        onClick={() => handleConvoSwitchOptionsClick("email")}
                      >
                        via Email
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {isConvoSupported ? (
        <Convo
          vehicleId={vehicleId}
          vehicleSource={storedSeller.vehicleSource}
          delisted={storedSeller.delisted}
          isConvoViaPhoneNumber={isConvoViaPhoneNumber}
          isAutoConvo={isAutoConvo}
        />
      ) : (
        <></>
      )}
      <StartNewConvoModal
        isOpen={isOpenStartNewConvoModal}
        onClose={handleCloseStartNewConvoModal}
      />
      <ConfirmModal
        text={
          <>
            Are you sure you want to start
            <br />
            an automatic convo?
          </>
        }
        isOpen={isOpenStartAutoConvoModal}
        onClose={handleCloseStartAutoConvoModal}
        onConfirm={handleConfirmStartAutoConvo}
      />
    </div>
  );
};

export default Seller;
