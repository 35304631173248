import AsyncSelect from "react-select/async";
import ExpandIndicator from "components/ExpandIndicator";
import iconDropDown from "assets/icons/dropdown-blue.svg";

const SimpleSelect = ({
  placeholder = "",
  defaultValue,
  onChange,
  options = {},
}) => {
  const selectStyles = {
    input: (styles) => ({
      ...styles,
      overflow: "hidden",
      marginLeft: 0,
    }),
    control: (styles) => ({
      ...styles,
      height: 27,
      border: "none",
      boxShadow: "none",
      fontSize: 14,
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    option: (styles) => ({
      ...styles,
      fontSize: 14,
      flexWrap: "wrap",
      overflow: "hidden",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: 14,
      color: "#8d8d8d",
      lineHeight: 1.71,
      textAlign: "left",
      marginLeft: 0,
    }),
    container: (styles) => ({
      ...styles,
      borderBottom: "solid 1px #f7f7f7",
      maxWidth: 307,
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: 0,
      letterSpacing: 0.4,
    }),
    singleValue: (styles) => ({
      ...styles,
      marginLeft: 0,
    }),
    listBox: (styles) => ({
      ...styles,
    }),
  };

  return (
    <AsyncSelect
      defaultOptions={options}
      defaultInputValue={defaultValue}
      styles={selectStyles}
      placeholder={placeholder}
      onChange={(value) => onChange(value.value)}
      components={{
        DropdownIndicator: ({ selectProps: { menuIsOpen } }) => (
          <ExpandIndicator src={iconDropDown} isOpen={menuIsOpen} />
        ),
      }}
    />
  );
};

export default SimpleSelect;
