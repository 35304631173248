import iconLogo from "assets/images/logo-gray.svg";
import s from "./index.module.scss";

const VehiclesNotFoundPlaceholder = () => (
  <div className={s["vehicles-not-found-placeholder"]}>
    <p className={s["vehicles-not-found-placeholder__title"]}>
      Nothing was found
    </p>
    <img
      className={s["vehicles-not-found-placeholder__image"]}
      src={iconLogo}
      alt="Logo"
    />
  </div>
);

export default VehiclesNotFoundPlaceholder;
