import { useState, useEffect } from "react";
import Loader from "components/Loader";
import SavedSearchCard from "components/SavedSearchCard";
import VehiclesNotFoundPlaceholder from "components/VehiclesNotFoundPlaceholder";
import serviceSavedSearches from "services/saved-searches";
import savedSearchRepository from "repositories/savedSearchRepository";
import s from "./index.module.scss";

const MySavedSearches = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [savedSearches, setSaveSearches] = useState([]);

  const isNothingWasFound = !isLoading && savedSearches.length === 0;

  useEffect(() => {
    serviceSavedSearches
      .getMySavedSearches()
      .then(({ items }) => setSaveSearches(items))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    savedSearchRepository.resetState();
  }, []);

  return (
    <div className={s["my-saved-searches"]}>
      <p className={s["my-saved-searches__title"]}>My Saved Searches</p>
      <div className={s["my-saved-searches__grid"]}>
        {savedSearches.map((savedSearch) => (
          <SavedSearchCard savedSearch={savedSearch} key={savedSearch.id} />
        ))}
      </div>
      {isNothingWasFound && <VehiclesNotFoundPlaceholder />}
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default MySavedSearches;
