import { useState, useEffect } from "react";
import AddDescriptionNoteModal from "components/modals/AddDescriptionNoteModal";
import AddToWatchListModal from "components/modals/AddToWatchListModal";

const AddToWatchListModals = ({
  isOpen = false,
  id = "",
  onClose = () => {},
}) => {
  const [stage, setStage] = useState(0);
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    if (isOpen) {
      setStage(1);
      return;
    }
    setStage(0);
  }, [isOpen]);

  const handleClose = () => onClose();

  const increaseStage = () => setStage((stage) => stage + 1);

  const handleDoneAddDescriptionNoteModal = (description = "", note = "") => {
    setDescription(description);
    setNote(note);
    increaseStage();
  };

  const handleSkipAddDescriptionNoteModal = () => {
    setDescription("");
    setNote("");
    increaseStage();
  };

  const handleCloseAddDescriptionNoteModal = (description = "", note = "") => {
    setDescription(description);
    setNote(note);
    handleClose();
  };

  const handleDoneAddToWatchListModal = () => {
    setDescription("");
    setNote("");
  };

  const handleCloseAddToWatchListModal = () => handleClose();

  return (
    <>
      <AddDescriptionNoteModal
        isOpen={stage === 1}
        defaultDescription={description}
        defaultNote={note}
        onDone={handleDoneAddDescriptionNoteModal}
        onSkip={handleSkipAddDescriptionNoteModal}
        onClose={handleCloseAddDescriptionNoteModal}
      />
      <AddToWatchListModal
        isOpen={stage === 2}
        vehicleId={id}
        description={description}
        note={note}
        onClose={handleCloseAddToWatchListModal}
        onDone={handleDoneAddToWatchListModal}
      />
    </>
  );
};

export default AddToWatchListModals;
