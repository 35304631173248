import store from "store";

const getRole = (state) => {
  if (state) {
    return state.user.role;
  }

  const { getState } = store;
  return getState().user.role;
};

const getZipCode = (state) => {
  if (state) {
    return state.user.zipCode;
  }

  const { getState } = store;
  return getState().user.zipCode;
};

const userRepository = {
  getRole,
  getZipCode,
};

export default userRepository;
