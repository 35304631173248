import { useState, useEffect } from "react";
import iconCheckboxChecked from "assets/icons/checkbox-checked.svg";
import iconCheckboxUnchecked from "assets/icons/checkbox-unchecked.svg";
import iconCheckboxCheckedBold from "assets/icons/checkbox-checked-bold.svg";
import iconCheckboxUncheckedBold from "assets/icons/checkbox-unchecked-bold.svg";
import s from "./index.module.scss";

const Checkbox = ({
  label = "",
  isChecked = false,
  isBold = false,
  className = "",
  onChange = () => {},
}) => {
  const [localIsChecked, setLocalIsChecked] = useState(false);

  useEffect(() => {
    setLocalIsChecked(isChecked);
  }, [isChecked]);

  const handleClick = () => {
    setLocalIsChecked(!localIsChecked);
    onChange(!localIsChecked);
  };

  return (
    <div
      className={s["checkbox"] + (className ? " " + className : "")}
      onClick={handleClick}
    >
      {isBold ? (
        <img
          className={s["checkbox__icon"]}
          src={
            localIsChecked ? iconCheckboxCheckedBold : iconCheckboxUncheckedBold
          }
          alt={label}
        />
      ) : (
        <img
          className={s["checkbox__icon"]}
          src={localIsChecked ? iconCheckboxChecked : iconCheckboxUnchecked}
          alt={label}
        />
      )}
      <p
        className={
          s["checkbox__text"] + (isBold ? ` ${s["checkbox__text--bold"]}` : "")
        }
      >
        {label}
      </p>
    </div>
  );
};

export default Checkbox;
