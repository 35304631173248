import store from "store";
import {
  resetVehiclesStateWithFilters,
  resetVehiclesStateWithoutFilters,
  setIsGridView,
  setIsLoading,
  setLastAccessedVehicleId,
  setPage,
  setPageCount,
  setVehicles,
  setVehicleFields,
  setVehicleWorkInProgress,
  setStats,
  setSearchText,
  setLastFilterApplyingTimestamp,
  setZipCode,
  setSorting,
  setFilters,
  selectAllVehicles,
  selectVehicle,
  unselectAllVehicles,
  unselectVehicle,
} from "slicers/vehicles";

const getIsGridView = (state) => {
  if (state) {
    return state.vehicles.isGridView;
  }

  const { getState } = store;
  return getState().vehicles.isGridView;
};

const getLastAccessedVehicleId = (state) => {
  if (state) {
    return state.vehicles.lastAccessedVehicleId;
  }

  const { getState } = store;
  return getState().vehicles.lastAccessedVehicleId;
};

const getVehicles = (state) => {
  const { getState } = store;

  const currentState = state ? state : getState();

  const selectedVehicleIds = currentState.vehicles.selectedVehicleIds;
  const vehicles = currentState.vehicles.vehicles.map((vehicle) => {
    return {
      ...vehicle,
      isSelected: selectedVehicleIds.includes(vehicle.id),
    };
  });
  return vehicles;
};

const getPageNumber = (state) => {
  if (state) {
    return state.vehicles.page;
  }

  const { getState } = store;
  return getState().vehicles.page;
};

const getPageSize = (state) => {
  if (state) {
    return state.vehicles.pageSize;
  }

  const { getState } = store;
  return getState().vehicles.pageSize;
};

const getPageCount = (state) => {
  if (state) {
    return state.vehicles.pageCount;
  }

  const { getState } = store;
  return getState().vehicles.pageCount;
};

const getLoadingState = (state) => {
  if (state) {
    return state.vehicles.isLoading;
  }

  const { getState } = store;
  return getState().vehicles.isLoading;
};

const getZipCode = (state) => {
  if (state) {
    return state.vehicles.zipCode;
  }

  const { getState } = store;
  return getState().vehicles.zipCode;
};

const getSorting = (state) => {
  if (state) {
    return state.vehicles.sorting;
  }

  const { getState } = store;
  return getState().vehicles.sorting;
};

const getFilters = (state) => {
  if (state) {
    return state.vehicles.filters;
  }

  const { getState } = store;
  return getState().vehicles.filters;
};

const getSearchText = (state) => {
  if (state) {
    return state.vehicles.searchText;
  }

  const { getState } = store;
  return getState().vehicles.searchText;
};

const getSearchedAtTimestamp = (state) => {
  if (state) {
    return state.vehicles.lastFilterApplyingTimestamp;
  }

  const { getState } = store;
  return getState().vehicles.lastFilterApplyingTimestamp;
};

const getIsAllVehiclesSelected = (state) => {
  const { getState } = store;

  const currentState = state ? state : getState();

  if (!currentState.vehicles.vehicles.length) return false;

  if (!currentState.vehicles.selectedVehicleIds.length) return false;

  return (
    currentState.vehicles.vehicles.length ===
    currentState.vehicles.selectedVehicleIds.length
  );
};

const getSelectedVehicleIds = (state) => {
  const { getState } = store;

  const currentState = state ? state : getState();

  return currentState.vehicles.selectedVehicleIds;
};

const getStats = (state) => {
  const { getState } = store;

  const currentState = state ? state : getState();

  return currentState.vehicles.stats;
};

const updateIsGridView = (isGridView) =>
  store.dispatch(setIsGridView(isGridView));

const updateLoadingState = (isLoading) =>
  store.dispatch(setIsLoading(isLoading));

const updateLastAccessedVehicleId = (lastAccessedVehicleId) =>
  store.dispatch(setLastAccessedVehicleId(lastAccessedVehicleId));

const updateLastFilterApplyingTimestamp = (filterApplyingTimestamp) =>
  store.dispatch(setLastFilterApplyingTimestamp(filterApplyingTimestamp));

const updatePageNumber = (pageNumber) => store.dispatch(setPage(pageNumber));

const updatePageCount = (pageCount) => store.dispatch(setPageCount(pageCount));

const updateStats = (stats) => store.dispatch(setStats(stats));

const updateSearchText = (searchText) =>
  store.dispatch(setSearchText(searchText));

const updateZipCode = (zipCode) => store.dispatch(setZipCode(zipCode));

const updateSorting = (sorting) => store.dispatch(setSorting(sorting));

const updateFilters = (filters) => store.dispatch(setFilters(filters));

const resetVehicles = () => store.dispatch(resetVehiclesStateWithoutFilters());

const updateVehicles = (vehicles) => store.dispatch(setVehicles(vehicles));

const updateVehicleFields = (id, updatedFields) =>
  store.dispatch(setVehicleFields({ id, updatedFields }));

const updateVehicleWorkInProgress = (id) =>
  store.dispatch(setVehicleWorkInProgress({ id }));

const appendVehicles = (vehicles) =>
  store.dispatch(setVehicles([...getVehicles(), ...vehicles]));

const resetState = () => store.dispatch(resetVehiclesStateWithFilters());

const _selectAllVehicles = () => {
  const vehiclesIds = getVehicles().map((vehicle) => vehicle.id);
  store.dispatch(selectAllVehicles(vehiclesIds));
};

const _selectVehicle = (id) => {
  store.dispatch(selectVehicle(id));
};

const _unselectVehicle = (id) => store.dispatch(unselectVehicle(id));

const _unselectAllVehicles = () => store.dispatch(unselectAllVehicles());

const vehicleRepository = {
  getIsGridView,
  getLastAccessedVehicleId,
  getVehicles,
  getPageNumber,
  getPageSize,
  getPageCount,
  getLoadingState,
  getZipCode,
  getSorting,
  getFilters,
  getSearchText,
  getSearchedAtTimestamp,
  getIsAllVehiclesSelected,
  getSelectedVehicleIds,
  getStats,
  updateIsGridView,
  updateLoadingState,
  updateLastAccessedVehicleId,
  updateLastFilterApplyingTimestamp,
  updatePageNumber,
  updatePageCount,
  updateStats,
  updateSearchText,
  updateZipCode,
  updateSorting,
  updateFilters,
  resetVehicles,
  updateVehicles,
  updateVehicleFields,
  updateVehicleWorkInProgress,
  appendVehicles,
  resetState,
  selectAllVehicles: _selectAllVehicles,
  selectVehicle: _selectVehicle,
  unselectVehicle: _unselectVehicle,
  unselectAllVehicles: _unselectAllVehicles,
};

export default vehicleRepository;
