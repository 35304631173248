import { createSlice } from "@reduxjs/toolkit";

export const customersSlice = createSlice({
  name: "customers",
  initialState: {
    pageCustomer: 1,
    pageSize: 10,
    pageCountCustomer: 1,
    isLoading: false,
    customers: [],
  },
  reducers: {
    setPageCustomer: (state, action) => {
      state.pageCustomer = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setPageCountCustomer: (state, action) => {
      state.pageCountCustomer = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    resetState: (state, action) => {
      state.pageCustomer = 1;
      state.pageSize = 10;
      state.pageCountCustomer = 1;
      state.isLoading = false;
      state.customers = [];
    },
  },
});

export const {
  setPageCustomer,
  setPageSize,
  setPageCountCustomer,
  setIsLoading,
  setCustomers,
  resetState,
} = customersSlice.actions;

export default customersSlice.reducer;
