import DefaultVehiclesFilters from "components/DefaultVehiclesFilters";
import VehiclePager from "components/VehiclePager";
import CustomerVehicleCard from "components/VehicleCard/customer";
import serviceVehicles from "services/vehicles";
import vehicleRepository from "repositories/vehicleRepository";
import userRepository from "repositories/userRepository";
import s from "./index.module.scss";

const Vehicles = () => {
  return (
    <div className={s["vehicles"]}>
      <DefaultVehiclesFilters
        userRepository={userRepository}
        vehicleRepository={vehicleRepository}
        vehicleService={serviceVehicles}
      />
      <VehiclePager
        vehicleService={serviceVehicles}
        vehicleRepository={vehicleRepository}
        baseUrl="/customer/vehicles/"
        cardComponent={CustomerVehicleCard}
      />
    </div>
  );
};

export default Vehicles;
