import s from "./index.module.scss";

const VehicleEmployeeDescription = ({ employeeDescription = null }) => {
  if (!employeeDescription) {
    return <></>;
  }

  return (
    <div className={s["vehicle-employee-description"]}>
      <p className={s["vehicle-employee-description__title"]}>
        Notes from your Agent:
      </p>
      <p className={s["vehicle-employee-description__text"]}>
        {employeeDescription}
      </p>
    </div>
  );
};

export default VehicleEmployeeDescription;
