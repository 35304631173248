import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useVehicleCard = (vehicleRepository = null) => {
  const [isThumbnailLoadingError, setIsImageLoadingError] = useState(false);

  const onThumbnailLoadingError = useCallback(
    () => setIsImageLoadingError(true),
    []
  );

  const lastAccessedVehicleId = useSelector((state) =>
    vehicleRepository ? vehicleRepository.getLastAccessedVehicleId(state) : ""
  );

  const history = useHistory();

  const onClickCard = useCallback(
    (id, vehicleDetailsLink) => {
      vehicleRepository.updateLastAccessedVehicleId(id);
      history.push(vehicleDetailsLink);
    },
    [history, vehicleRepository]
  );

  return {
    lastAccessedVehicleId,
    isThumbnailLoadingError,
    onClickCard,
    onThumbnailLoadingError,
  };
};

export default useVehicleCard;
