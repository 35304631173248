import { useEffect, useState } from "react";

import iconWhiteTick from "assets/icons/tick-white.svg";

import s from "./index.module.scss";

const VehicleCheckbox = ({
  isChecked = false,
  title = "",
  labelClassName = "",
  titleClassName = "",
  wrapperClassName = "",
  checkmarkClassName = "",
  onChange = () => {},
}) => {
  const [localIsChecked, setLocalIsChecked] = useState(false);

  useEffect(() => {
    setLocalIsChecked(isChecked);
  }, [isChecked]);

  const handleChange = () => {
    const updatedIsChecked = !localIsChecked;
    setLocalIsChecked(updatedIsChecked);
    onChange(updatedIsChecked);
  };

  return (
    <label
      className={
        s["vehicle-checkbox"] + (labelClassName ? " " + labelClassName : "")
      }
    >
      <div
        className={
          s["vehicle-checkbox__wrapper"] +
          (wrapperClassName ? " " + wrapperClassName : "")
        }
      >
        <input
          type="checkbox"
          className={s["vehicle-checkbox__input"]}
          checked={localIsChecked}
          onChange={handleChange}
        />
        <span
          className={
            s["vehicle-checkbox__checkmark"] +
            (checkmarkClassName ? " " + checkmarkClassName : "")
          }
        ></span>
        <img
          className={s["vehicle-checkbox__checkmark-icon"]}
          src={iconWhiteTick}
          alt="Check mark"
        />
      </div>
      {title ? (
        <span
          className={
            s["vehicle-checkbox__text"] +
            (titleClassName ? " " + titleClassName : "")
          }
        >
          {title}
        </span>
      ) : (
        ""
      )}
    </label>
  );
};

export default VehicleCheckbox;
