import { useState, forwardRef } from "react";
import Input from "components/inputs/Input";
import iconEye from "assets/icons/eye.svg";
import iconEyeOff from "assets/icons/eye-off.svg";
import s from "./index.module.scss";

const PasswordInput = forwardRef((props, ref) => {
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);

  return (
    <div className={s["password-input"]}>
      <Input
        {...props}
        placeholder={props.placeholder}
        className={
          s["password-input__input"] +
          (props.className ? ` ${props.className}` : "")
        }
        type={isHiddenPassword ? "password" : "text"}
        ref={ref}
      />
      <img
        src={isHiddenPassword ? iconEye : iconEyeOff}
        className={s["password-input__eye"]}
        onClick={() => setIsHiddenPassword(!isHiddenPassword)}
        alt="Eye"
      />
    </div>
  );
});

export default PasswordInput;
