import { useState } from "react";
import { useParams } from "react-router-dom";
import Avatar from "components/Avatar";
import FilledButton from "components/buttons/FilledButton";
import UnassignCustomerModal from "components/modals/UnassignCustomerModal";
import iconMapPoint from "assets/icons/map-point.svg";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const AssignedCustomerProfile = ({
  customerDetails: {
    avatar = null,
    email = "",
    first_name: firstName = "",
    last_name: lastName = "",
    phone_number: phoneNumber = null,
    zip_code: zipCode = "",
    event_at: lastAction = "",
  },
}) => {
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);

  const { customerId } = useParams();

  const handleClickUnassignCustomer = () => {
    setIsOpenDetailsModal(true);
  };

  return (
    <div className={s["assigned-customer-profile__wrapper"]}>
      <div className={s["assigned-customer-profile"]}>
        <div className={s["assigned-customer-profile__info"]}>
          <Avatar
            className={s["assigned-customer-profile__avatar"]}
            avatar={avatar}
          />
          <div
            className={
              s["assigned-customer-profile__text"] +
              " " +
              s["assigned-customer-profile__text--bold"]
            }
          >
            {firstName} {lastName}
          </div>
        </div>
        <p
          className={
            s["assigned-customer-profile__text"] +
            " " +
            s["assigned-customer-profile__text--email"]
          }
        >
          {email}
        </p>
        {phoneNumber && (
          <p
            className={
              s["assigned-customer-profile__text"] +
              " " +
              s["assigned-customer-profile__text--phone-number"]
            }
          >
            {phoneNumber}
          </p>
        )}
        <div className={s["assigned-customer-profile__zip-code-wrapper"]}>
          <img
            className={s["assigned-customer-profile__map-point"]}
            src={iconMapPoint}
            alt="Distance to vehicle"
          />
          <p className={s["assigned-customer-profile__text"]}>
            {utilFormatter.prettyString(zipCode, " ", 3)}
          </p>
        </div>
        <p
          className={
            s["assigned-customer-profile__text"] +
            " " +
            s["assigned-customer-profile__text--date"]
          }
        >
          {utilFormatter.prettyDate(lastAction)}
        </p>
      </div>
      <FilledButton
        className={s["assigned-customer-profile__button"]}
        onClick={handleClickUnassignCustomer}
      >
        Unassign
      </FilledButton>
      <UnassignCustomerModal
        isOpen={isOpenDetailsModal}
        firstName={firstName}
        lastName={lastName}
        onClose={() => setIsOpenDetailsModal(false)}
        customerId={customerId}
      />
    </div>
  );
};

export default AssignedCustomerProfile;
