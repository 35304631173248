import { createSlice } from "@reduxjs/toolkit";

export const activitiesSlice = createSlice({
  name: "activities",
  initialState: {
    hasEvents: false,
    hasUnread: false,
  },
  reducers: {
    setHasUnread: (state, action) => {
      state.hasUnread = action.payload;
    },
    setHasEvents: (state, action) => {
      state.hasEvents = action.payload;
    },
    resetActivities: (state, action) => {
      state.hasUnread = false;
      state.hasEvents = false;
    },
  },
});

export const { setHasUnread, setHasEvents, resetActivities } =
  activitiesSlice.actions;

export default activitiesSlice.reducer;
