import { useState } from "react";
import Modal from "components/Modal";
import FilledButton from "components/buttons/FilledButton";
import iconCrossBlack from "assets/icons/cross-black.svg";
import s from "./index.module.scss";

const ConfirmModal = ({
  text = <></>,
  isOpen = false,
  onClose = () => {},
  onConfirm = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = (isConfirmed = false) => {
    onClose(isConfirmed);
  };

  const handleClickConfirmButton = () => {
    setIsLoading(true);
    onConfirm()
      .then(() => handleClose(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose(false)}>
      <div className={s["confirm-modal"]}>
        <img
          className={s["confirm-modal__cross"]}
          src={iconCrossBlack}
          alt="Close"
          onClick={() => handleClose(false)}
        />
        <p className={s["confirm-modal__text"]}>{text}</p>
        <FilledButton
          className={s["confirm-modal__button"]}
          onClick={handleClickConfirmButton}
          disabled={isLoading}
        >
          Yes, I am sure
        </FilledButton>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
