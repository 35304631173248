import { useState, useEffect } from "react";
import Modal from "components/Modal";
import Input from "components/inputs/Input";
import SimpleInput from "components/inputs/SimpleInput";
import AvatarUploader from "components/AvatarUploader";
import UnfilledButton from "components/buttons/UnfilledButton";
import FilledButton from "components/buttons/FilledButton";
import SimpleSelect from "components/inputs/SimpleSelect";
import servicePassword from "services/password";
import serviceUsers from "services/users";
import utilFormatter from "utils/formatter";
import utilValidator from "utils/validator";
import s from "./index.module.scss";

const EditCustomerModal = ({
  isOpen = false,
  salesReps = [],
  defaultValues: {
    defaultZipCode = "",
    defaultFirstName = "",
    defaultLastName = "",
    defaultEmail = "",
    defaultPhoneNumber = "",
    defaultSalesRep = null,
    defaultAvatar = null,
  } = {},
  customerId = "",
  onClose = () => {},
}) => {
  const [zipCode, setZipCode] = useState(defaultZipCode);
  const [firstName, setFirstName] = useState(defaultFirstName);
  const [lastName, setLastName] = useState(defaultLastName);
  const [email, setEmail] = useState(defaultEmail);
  const [salesRepId, setSalesRepId] = useState(defaultSalesRep);
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isDisabledCancelButton = isLoading;
  const isDisabledSaveButton =
    !firstName || !lastName || !email || !zipCode || isLoading;

  const reset = () => {
    setZipCode(defaultZipCode);
    setFirstName(defaultFirstName);
    setLastName(defaultLastName);
    setEmail(defaultEmail);
    setSalesRepId(defaultSalesRep);
    setEmailError("");
    setZipCodeError("");
    setPassword("");
    setAvatar(defaultAvatar);
  };

  useEffect(reset, [
    defaultZipCode,
    defaultFirstName,
    defaultLastName,
    defaultEmail,
    defaultSalesRep,
    defaultAvatar,
  ]);

  const setErrorText = {
    zip_code: setZipCodeError,
    email: setEmailError,
  };

  const resetErrorState = () => {
    setEmailError("");
    setPasswordError("");
    setZipCodeError("");
  };

  const handleChangeZipCode = (value) => {
    setZipCode(utilFormatter.prettyZip(value));
  };

  const handleClose = () => {
    reset();
    resetErrorState();
    onClose();
  };

  const handleClickCancel = () => {
    if (isDisabledCancelButton) {
      return;
    }

    handleClose();
  };

  const handleClickGeneratePassword = () =>
    setPassword(servicePassword.generate(16));

  const errorHandler = (data) => {
    const errorKeys = Object.keys(data);
    for (const key of errorKeys) {
      data[key].forEach((value) => {
        setErrorText[key](value.message);
      });
    }
  };

  const handleEditCustomer = async () => {
    if (isDisabledSaveButton) {
      return;
    }

    resetErrorState();

    const trimmedEmail = email.trim();
    setEmail(trimmedEmail);

    const emailErrorMessage = utilValidator.validateEmailAndGetError(email);
    if (email !== defaultEmail && emailErrorMessage) {
      setEmailError(emailErrorMessage);
    }

    const zipCodeErrorMessage =
      utilValidator.validateZipCodeAndGetError(zipCode);
    if (zipCodeErrorMessage) {
      setZipCodeError(zipCodeErrorMessage);
    }

    let passwordErrorMessage;

    if (password) {
      passwordErrorMessage =
        utilValidator.validatePasswordAndGetError(password);
      setPasswordError(passwordErrorMessage);
    }

    const errorsMsg = [
      emailErrorMessage,
      zipCodeErrorMessage,
      passwordErrorMessage,
    ].filter((value) => !!value);

    if (errorsMsg.length !== 0) return;

    setIsLoading(true);
    serviceUsers
      .updateCustomer(
        trimmedEmail,
        password,
        "",
        firstName,
        lastName,
        zipCode,
        avatar,
        customerId,
        "",
        salesRepId
      )
      .then(() => {
        serviceUsers.getCustomersPage();
        handleClose();
      })
      .catch((error) => {
        errorHandler(error.response.data);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["edit-customer-modal"]}>
        <div className={s["edit-customer-modal__title-wrapper"]}>
          <span className={s["edit-customer-modal__title"]}>Edit Customer</span>
        </div>
        <div className={s["edit-customer-modal__form-wrapper"]}>
          <div className={s["edit-customer-modal__inputs-wrapper"]}>
            <SimpleInput
              value={firstName}
              className={s["edit-customer-modal__input--first-name"]}
              placeholder="First name"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <SimpleInput
              value={lastName}
              placeholder="Last name"
              onChange={(e) => setLastName(e.target.value)}
            />
            <div>
              <Input
                className={
                  s["edit-customer-modal__input--zip-code"] +
                  (zipCodeError
                    ? ` ${s["edit-customer-modal__input--zip-code--error"]}`
                    : "")
                }
                placeholder="Postal code"
                error={zipCodeError}
                spliter=" "
                chunkSize={3}
                value={zipCode}
                onChange={(e) => {
                  setZipCodeError("");
                  handleChangeZipCode(e);
                }}
              />
              {zipCodeError && (
                <p className={s["edit-customer-modal__input--error"]}>
                  {zipCodeError}
                </p>
              )}
            </div>
            <SimpleSelect
              defaultValue={
                defaultSalesRep
                  ? salesReps.find((item) => item.value === defaultSalesRep)
                      .label
                  : ""
              }
              placeholder="Assign Sales Rep"
              options={salesReps}
              onChange={(e) => {
                setSalesRepId(e);
              }}
            />
            <SimpleInput
              value={email}
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
              error={emailError}
            />
            {defaultPhoneNumber && (
              <SimpleInput
                value={defaultPhoneNumber}
                disabled
                placeholder="Phone number"
              />
            )}
            <SimpleInput
              value={password}
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError("");
              }}
              error={passwordError}
            />
            <p
              className={s["edit-customer-modal__password-link"]}
              onClick={handleClickGeneratePassword}
            >
              Generate password
            </p>
          </div>
          <div className={s["edit-customer-modal__avatar-wrapper"]}>
            <AvatarUploader
              className={s["edit-customer-modal__avatar"]}
              avatar={avatar}
              onChange={(avatar) => setAvatar(avatar)}
            />
            <span className={s["edit-customer-modal__avatar__text"]}>
              Upload photo
            </span>
          </div>
          <div className={s["edit-customer-modal__buttons-wrapper"]}>
            <UnfilledButton
              className={s["edit-customer-modal__button"]}
              disabled={isDisabledCancelButton}
              onClick={handleClickCancel}
            >
              Cancel
            </UnfilledButton>
            <FilledButton
              className={s["edit-customer-modal__button"]}
              disabled={isDisabledSaveButton}
              onClick={handleEditCustomer}
            >
              Save
            </FilledButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditCustomerModal;
