import s from "./index.module.scss";

const ListViewIcon = ({ className = "", isDisabled = true }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 11"
      className={className}
    >
      <g transform="translate(-1098 -85)">
        <rect
          data-name="Rectangle 2712"
          width="18"
          height="2"
          rx="1"
          transform="translate(1098 85)"
          className={
            s["icon-rect"] + (isDisabled ? " " + s["icon-rect--disabled"] : "")
          }
        />
        <rect
          data-name="Rectangle 2713"
          width="18"
          height="2"
          rx="1"
          transform="translate(1098 88)"
          className={
            s["icon-rect"] + (isDisabled ? " " + s["icon-rect--disabled"] : "")
          }
        />
        <rect
          data-name="Rectangle 2714"
          width="18"
          height="2"
          rx="1"
          transform="translate(1098 91)"
          className={
            s["icon-rect"] + (isDisabled ? " " + s["icon-rect--disabled"] : "")
          }
        />
        <rect
          data-name="Rectangle 2715"
          width="18"
          height="2"
          rx="1"
          transform="translate(1098 94)"
          className={
            s["icon-rect"] + (isDisabled ? " " + s["icon-rect--disabled"] : "")
          }
        />
      </g>
    </svg>
  );
};

export default ListViewIcon;
