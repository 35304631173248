import utilFormatter from "utils/formatter";
import iconAvatar from "assets/icons/avatar-placeholder.svg";
import s from "./index.module.scss";

const RemovedFromWatchList = ({ event = {} }) => {
  const {
    event_at: eventAt = "",
    user: {
      avatar = {},
      first_name: firstName = "",
      last_name: lastName = "",
    } = {},
    watchlist: { client_name: clientName = "", title = "" } = {},
  } = event;

  return (
    <div className={s["event-wrapper"]}>
      <img
        className={s["event-avatar"]}
        src={avatar?.link || iconAvatar}
        alt="Avatar"
      />
      <div className={s["event"]}>
        <p className={s["event__text"]}>
          <span
            className={s["event__name"]}
          >{`${firstName} ${lastName} `}</span>
          removed from
          <span className={s["event__client-name"]}>{` ${clientName}’s `}</span>
          wachlist
          <span className={s["event__watchlist-name"]}>{` ${title}`}</span>
        </p>
        <div className={s["event__date"]}>
          {utilFormatter.prettyDateTimeJsx(eventAt)}
        </div>
      </div>
    </div>
  );
};

export default RemovedFromWatchList;
