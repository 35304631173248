const getIsGridView = () => localStorage.getItem("isSavedSearchGridView");

const saveIsGridView = (value) =>
  localStorage.setItem("isSavedSearchGridView", value);

const removeIsGridView = () => localStorage.removeItem("isSavedSearchGridView");

const savedSearchStateStorageGateway = {
  getIsGridView,
  saveIsGridView,
  removeIsGridView,
};

export default savedSearchStateStorageGateway;
