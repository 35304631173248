import VehicleCard from "components/VehicleCard";
import s from "./index.module.scss";

const PublicWatchListGrid = ({
  vehicles = [],
  cardComponent = null,
  cardProps = {},
}) => {
  const CardComponent = cardComponent || VehicleCard;

  return (
    <div className={s["public-watch-list-grid"]}>
      {vehicles.map((vehicle) => (
        <CardComponent vehicle={vehicle} key={vehicle.to} {...cardProps} />
      ))}
    </div>
  );
};

export default PublicWatchListGrid;
