import isEqual from "lodash.isequal";

const isEqualByValue = (prevValue, currentValue) => {
  return isEqual(prevValue, currentValue);
};

const comparisons = {
  isEqualByValue,
};

export default comparisons;
