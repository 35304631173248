import AddedToWatchList from "./AddedToWatchList";
import Comment from "./Comment";
import RemovedFromWatchList from "./RemovedFromWatchList";
import StartedConversation from "./StartedConversation";
import StartedAutoConvo from "./StartedAutoConvo";
import StoppedAutoConvo from "./StoppedAutoConvo";
import AddedSellerPhoneNumber from "./AddedSellerPhoneNumber";
import UpdatedSellerPhoneNumber from "./UpdatedSellerPhoneNumber";
import AddedUpdatedVehicleAdditionalPrice from "./AddedUpdatedVehicleAdditionalPrice";
import RemovedVehicleAdditionalPrice from "./RemovedVehicleAdditionalPrice";

const components = {
  added_vehicle_comment: Comment,
  added_to_watchlist: AddedToWatchList,
  removed_from_watchlist: RemovedFromWatchList,
  started_conversation: StartedConversation,
  started_autoconvo: StartedAutoConvo,
  stopped_autoconvo: StoppedAutoConvo,
  added_seller_phone_number: AddedSellerPhoneNumber,
  updated_seller_phone_number: UpdatedSellerPhoneNumber,
  updated_vehicle_additional_price: AddedUpdatedVehicleAdditionalPrice,
  added_vehicle_additional_price: AddedUpdatedVehicleAdditionalPrice,
  removed_vehicle_additional_price: RemovedVehicleAdditionalPrice,
};

const SingleActivity = ({
  event = {},
  vehicleId = "",
  vin = "",
  onEdit = () => {},
  onRemove = () => {},
}) => {
  const Component = components[event.type];

  if (!Component) {
    return <></>;
  }

  return (
    <Component
      event={event}
      vehicleId={vehicleId}
      vin={vin}
      onEdit={onEdit}
      onRemove={onRemove}
    />
  );
};

export default SingleActivity;
