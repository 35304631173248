import { useState, useEffect } from "react";
import VehicleGrid from "components/VehicleGrid";
import WatchListDetailsVehicleCard from "components/VehicleCard/customer/WatchListDetails";
import Loader from "components/Loader";
import DeleteVehicleFromWatchListModal from "components/modals/DeleteVehicleFromWatchListModal";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const WatchList = () => {
  const [vehicles, setVehicles] = useState([]);
  const [vehicleIdToRemove, setVehicleIdToRemove] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [watchListId, setWatchListId] = useState(null);

  const vehicleToRemove = vehicleIdToRemove
    ? vehicles.filter((vehicle) => vehicle.id === vehicleIdToRemove)[0]
    : {};

  async function getWatchListIdAndVehicles() {
    try {
      const watchlists = await serviceWatchLists.searchMe("");

      const watchListId = watchlists[0].id;

      const data = await serviceWatchLists.vehicles(watchListId);

      setWatchListId(watchListId);

      setVehicles(
        data.map((vehicle) => ({
          to: `/customer/vehicles/${vehicle.id}`,
          id: vehicle.id,
          thumbnail: vehicle.thumbnail,
          year: vehicle.build.year,
          make: vehicle.build.make,
          model: vehicle.build.model,
          trim: vehicle.build.trim,
          mileage: vehicle.mileage,
          price: vehicle.price,
          delisted: vehicle.delisted,
          onRemove: () => handleRemoveVehicle(vehicle.id),
        }))
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getWatchListIdAndVehicles();
  }, []);

  const handleRemoveVehicle = (vehicleId) => setVehicleIdToRemove(vehicleId);

  const handleCloseModal = (isDeleted = false) => {
    if (isDeleted) {
      setVehicles((vehicles) =>
        vehicles.filter((vehicle) => vehicle.id !== vehicleToRemove.id)
      );
    }
    setVehicleIdToRemove("");
  };

  return (
    <div className={s["watch-list-details"]}>
      {isLoading ? (
        <></>
      ) : (
        <>
          <div className={s["watch-list__title"]}>
            <span
              className={
                s["watch-list-details__text"] +
                " " +
                s["watch-list-details__text--bold"]
              }
            >
              My Watchlist
            </span>
            <span className={s["watch-list-details__text"]}>
              &nbsp;(up to 6 slots for selected vehicles)
            </span>
          </div>
          {vehicles.length !== 0 ? (
            <VehicleGrid
              vehicles={vehicles}
              cardComponent={WatchListDetailsVehicleCard}
            />
          ) : (
            <div className={s["watch-list-details__stub"]}>
              <p
                className={
                  s["watch-list-details__text"] +
                  " " +
                  s["watch-list-details__text--stub"]
                }
              >
                Your watchlist is empty.
              </p>
              <p
                className={
                  s["watch-list-details__text"] +
                  " " +
                  s["watch-list-details__text--stub"]
                }
              >
                Add vehicles to your watchlist <br />
                by using
                <strong> “heart”</strong> icon on vehicle details page.
              </p>
            </div>
          )}
        </>
      )}
      <Loader isOpen={isLoading} />
      <DeleteVehicleFromWatchListModal
        isOpen={!!vehicleIdToRemove}
        onClose={handleCloseModal}
        text={`${vehicleToRemove?.make ? vehicleToRemove.make : ""} ${
          vehicleToRemove?.model ? ` ${vehicleToRemove.model}` : ""
        } ${vehicleToRemove?.trim ? ` ${vehicleToRemove.trim}` : ""}`}
        watchListId={watchListId}
        vehicleId={vehicleIdToRemove}
      />
    </div>
  );
};

export default WatchList;
