import s from "./index.module.scss";

const VehicleReaction = ({
  isActive = false,
  activeIcon = null,
  inactiveIcon = null,
  onClick = () => {},
}) => {
  return (
    <img
      src={isActive ? activeIcon : inactiveIcon}
      alt="React"
      className={s["vehicle-reaction"]}
      onClick={onClick}
    />
  );
};

export default VehicleReaction;
