import { memo } from "react";
import { useHistory } from "react-router-dom";
import ConvosCheckbox from "components/inputs/ConvosCheckbox";
import utilFormatter from "utils/formatter";
import iconAvatarPlaceholder from "assets/icons/avatar-placeholder.svg";
import utilComparisons from "utils/comparisons";
import s from "./index.module.scss";

const ConversationFactory = ({
  conversation = {},
  onChangeConversationVehicleSelection = () => {},
}) => {
  if (conversation.vehicleId) {
    return (
      <Conversation
        conversation={conversation}
        onChangeConversationVehicleSelection={
          onChangeConversationVehicleSelection
        }
        canBeSelected
        vehicleLink={
          "/employee/vehicles/" +
          conversation.vehicleId +
          `?message_type=${conversation.messageType}`
        }
      />
    );
  }

  return <Conversation conversation={conversation} canBeSelected={false} />;
};

const compareProps = (prevProps, nextProps) => {
  return utilComparisons.isEqualByValue(prevProps, nextProps);
};

const Conversation = memo(
  ({
    conversation = {},
    onChangeConversationVehicleSelection = () => {},
    canBeSelected = false,
    vehicleLink = "",
  }) => {
    const {
      contact = "",
      title = "",
      lastMessageText = "",
      media = [],
      createdAt = "",
      isDeleted = false,
      isDelisted = false,
      imgData = {},
      isUnread = false,
      isUserSender = false,
      isSelected = false,
    } = conversation;

    const history = useHistory();

    const wrapperClassName =
      s["conversation"] +
      (isUnread ? ` ${s["conversation--unread"]}` : "") +
      (media.length > 0 ? ` ${s["conversation--media-last-message"]}` : "");

    const date = utilFormatter.prettyDate(createdAt);
    const time = utilFormatter.prettyTime(createdAt);

    const imgAttrs = imgData.src ? imgData : { src: iconAvatarPlaceholder };

    return (
      <div className={wrapperClassName}>
        <div className={s["conversation__non-clickable-side"]}>
          {canBeSelected ? (
            <div className={s["conversation__checkbox-wrapper"]}>
              <ConvosCheckbox
                isChecked={isSelected}
                onChange={() =>
                  onChangeConversationVehicleSelection(isSelected, conversation)
                }
                labelClassName={s["conversation__checkbox-label"]}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div
          className={
            s["conversation__clickable-side"] +
            (vehicleLink ? " " + s["conversation__clickable-side--link"] : "")
          }
          onClick={() => (vehicleLink ? history.push(vehicleLink) : null)}
        >
          <div className={s["conversation__label"]}>Contact</div>
          <div className={s["conversation__value-date"]}>{date}</div>
          <div
            className={
              s["conversation__value"] +
              " " +
              s["conversation__value-contact"] +
              (isUnread ? ` ${s["conversation__value--bold"]}` : "") +
              (media.length > 0
                ? ` ${s["value-car__text--media-last-message"]}`
                : "")
            }
          >
            {contact}
          </div>
          <div className={s["conversation__label"]}>Vehicle</div>
          <div className={s["conversation__value-car"]}>
            <div
              className={
                s["value-car__text"] +
                (isUnread ? ` ${s["value-car__text--bold"]}` : "") +
                (media.length > 0
                  ? ` ${s["value-car__text--media-last-message"]}`
                  : "")
              }
            >
              {title}
            </div>
            {isDeleted && (
              <div
                className={
                  s["value-car__badge"] + " " + s["value-car__badge--deleted"]
                }
              >
                DELETED
              </div>
            )}
            {isDelisted && (
              <div
                className={
                  s["value-car__badge"] + " " + s["value-car__badge--delisted"]
                }
              >
                DELISTED
              </div>
            )}
          </div>
          <div className={s["conversation__label"]}>Last Message</div>
          <div
            className={
              s["conversation__value"] +
              " " +
              s["conversation__value-last-message"]
            }
          >
            <img
              className={
                s["conversation__value-last-message__icon-source"] +
                (isUserSender
                  ? ` ${s["conversation__value-last-message__icon-source--rounded"]}`
                  : "")
              }
              {...imgAttrs}
            />
            {media.length > 0 ? (
              <div className={s["conversation__value-last-image"]}>
                <img
                  alt="attached media"
                  src={media[media.length - 1].link}
                  className={s["conversation__message-image"]}
                />
              </div>
            ) : (
              <div
                className={
                  s["conversation__value-last-message__text"] +
                  (isUnread
                    ? ` ${s["conversation__value-last-message__text--bold"]}`
                    : "")
                }
              >
                {lastMessageText}
              </div>
            )}
          </div>
          <div className={s["conversation__label"]}>Last Message Date</div>
          <div
            className={
              s["conversation__value"] +
              " " +
              s["conversation__value-date-time"] +
              (isUnread ? ` ${s["conversation__value--bold"]}` : "")
            }
          >
            {`${date} ${time}`}
          </div>
        </div>
      </div>
    );
  },
  compareProps
);

export default ConversationFactory;
