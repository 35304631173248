import { useState, useEffect } from "react";
import VehicleReaction from "components/VehicleReaction";
import iconVehicleReactionLikeOn from "assets/icons/vehicle-reaction-like-on.svg";
import iconVehicleReactionLikeOff from "assets/icons/vehicle-reaction-like-off.svg";
import iconVehicleReactionDislikeOn from "assets/icons/vehicle-reaction-dislike-on.svg";
import iconVehicleReactionDislikeOff from "assets/icons/vehicle-reaction-dislike-off.svg";
import serviceWatchLists from "services/watch-lists";

const VehicleReactions = ({
  reaction: defaultReaction = null,
  watchListId = "",
  vehicleId = "",
}) => {
  const [reaction, setReaction] = useState(defaultReaction);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setReaction(defaultReaction);
  }, [defaultReaction]);

  const addReaction = (e, reactionFunc) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    reactionFunc(watchListId, vehicleId)
      .then((vehicle) => setReaction(vehicle.reaction))
      .finally(() => setIsLoading(false));
  };

  const handleLike = (e) => addReaction(e, serviceWatchLists.likeVehicle);

  const handleDislike = (e) => addReaction(e, serviceWatchLists.dislikeVehicle);

  return (
    <>
      <VehicleReaction
        isActive={reaction === "like"}
        activeIcon={iconVehicleReactionLikeOn}
        inactiveIcon={iconVehicleReactionLikeOff}
        onClick={handleLike}
      />
      <VehicleReaction
        isActive={reaction === "dislike"}
        activeIcon={iconVehicleReactionDislikeOn}
        inactiveIcon={iconVehicleReactionDislikeOff}
        onClick={handleDislike}
      />
    </>
  );
};

export default VehicleReactions;
