import Modal from "components/Modal";
import Avatar from "components/Avatar";
import iconCrossBlackBold from "assets/icons/cross-black-bold.svg";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const CustomerDetailsModal = ({
  salesReps = [],
  isOpen = false,
  onClose = () => {},
  customer: {
    profile: {
      first_name: firstName = "",
      last_name: lastName = "",
      avatar = {},
      phone_number = "",
      zip_code = "",
      employee_id = "",
    } = {},
    email = "",
  } = {},
}) => {
  const handleClose = () => onClose();

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={s["customer-details-modal"]}>
        <div className={s["customer-details-modal__title-wrapper"]}>
          <p className={s["customer-details-modal__title"]}>
            Customer's Details
          </p>
          <img
            className={s["customer-details-modal__cross-icon"]}
            src={iconCrossBlackBold}
            alt="Close"
            onClick={handleClose}
          />
        </div>
        <div className={s["customer-details-modal__form-wrapper"]}>
          <div className={s["customer-details-modal__form-avatar"]}>
            <Avatar avatar={avatar} />
          </div>
          <p className={s["customer-details-modal__form-text"]}>{firstName}</p>
          <p className={s["customer-details-modal__form-text"]}>{lastName}</p>
          <p className={s["customer-details-modal__form-text"]}>
            {utilFormatter.prettyString(zip_code, " ", 3)}
          </p>
          {employee_id && (
            <p className={s["customer-details-modal__form-text"]}>
              Sales Rep:&nbsp;
              {employee_id &&
                salesReps.find((item) => item.value === employee_id).label}
            </p>
          )}
          <p className={s["customer-details-modal__form-text"]}>{email}</p>
          {phone_number && (
            <p className={s["customer-details-modal__form-text"]}>
              {phone_number}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CustomerDetailsModal;
