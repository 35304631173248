import { useEffect, useState } from "react";

import ExpandIndicator from "components/ExpandIndicator";
import Checkbox from "components/inputs/Checkbox";
import Input from "components/inputs/Input";

import expandIndicatorIcon from "./../../assets/icons/expand-indicator.svg";

import s from "./index.module.scss";

const InfoFromSeller = ({
  infoFromSeller,
  onHasAccidentsChanged = () => {},
  onIsSmokedChanged = () => {},
  onIsPriceNegotiableChanged = () => {},
  onAccidentsDetailsChanged = () => {},
  onVINChanged = () => {},
  onTrimChanged = () => {},
  onMoneyOwedOnCarChanged = () => {},
  onDamagesOnCarChanged = () => {},
  onReasonToSellChanged = () => {},
  onUrgencyOfSaleChanged = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [localHasAccidents, setLocalHasAccidents] = useState(
    infoFromSeller.hasAccidents || false
  );
  const [localAccidentsDetails, setLocalAccidentsDetails] = useState(
    infoFromSeller.accidentsDetails || ""
  );
  const [localVIN, setLocalVIN] = useState(infoFromSeller.vin || "");
  const [localTrim, setLocalTrim] = useState(infoFromSeller.trim || "");
  const [localMoneyOwedOnCar, setLocalMoneyOwedOnCar] = useState(
    infoFromSeller.moneyOwedOnCar || ""
  );
  const [localDamagesOnCar, setLocalDamagesOnCar] = useState(
    infoFromSeller.damagesOnCar || ""
  );
  const [localReasonToSell, setLocalReasonToSell] = useState(
    infoFromSeller.reasonToSell || ""
  );
  const [localUrgencyOfSale, setLocalUrgencyOfSale] = useState(
    infoFromSeller.urgencyOfSale || ""
  );
  const [localIsSmoked, setLocalIsSmoked] = useState(
    infoFromSeller.isSmoked || false
  );
  const [localIsPriceNegotiable, setLocalIsPriceNegotiable] = useState(
    infoFromSeller.isPriceNegotiable || false
  );

  const handleClickTitle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleChangeHasAccidents = (isChecked) => {
    setLocalHasAccidents(isChecked);
    onHasAccidentsChanged(isChecked);
  };

  const handleChangeIsSmoked = (isChecked) => {
    setLocalIsSmoked(isChecked);
    onIsSmokedChanged(isChecked);
  };

  const handleChangeIsPriceNegotiable = (isChecked) => {
    setLocalIsPriceNegotiable(isChecked);
    onIsPriceNegotiableChanged(isChecked);
  };

  const handleBlurTrim = () => {
    if (!localTrim && !infoFromSeller.trim) {
      return;
    }
    if (localTrim === infoFromSeller.trim) {
      return;
    }
    onTrimChanged(localTrim);
  };

  const handleBlurVIN = () => {
    if (!localVIN && !infoFromSeller.vin) {
      return;
    }
    if (localVIN === infoFromSeller.vin) {
      return;
    }
    onVINChanged(localVIN);
  };

  const handleBlurAccidentsDetails = () => {
    if (!localAccidentsDetails && !infoFromSeller.accidentsDetails) {
      return;
    }
    if (localAccidentsDetails === infoFromSeller.accidentsDetails) {
      return;
    }
    onAccidentsDetailsChanged(localAccidentsDetails);
  };

  const handleBlurMoneyOwedOnCar = () => {
    if (!localMoneyOwedOnCar && !infoFromSeller.moneyOwedOnCar) {
      return;
    }
    if (localMoneyOwedOnCar === infoFromSeller.moneyOwedOnCar) {
      return;
    }
    onMoneyOwedOnCarChanged(localMoneyOwedOnCar);
  };

  const handleBlurDamagesOnCar = () => {
    if (!localDamagesOnCar && !infoFromSeller.damagesOnCar) {
      return;
    }
    if (localDamagesOnCar === infoFromSeller.damagesOnCar) {
      return;
    }
    onDamagesOnCarChanged(localDamagesOnCar);
  };

  const handleBlurReasonToSell = () => {
    if (!localReasonToSell && !infoFromSeller.reasonToSell) {
      return;
    }
    if (localReasonToSell === infoFromSeller.reasonToSell) {
      return;
    }
    onReasonToSellChanged(localReasonToSell);
  };

  const handleBlurUrgencyOfSale = () => {
    if (!localUrgencyOfSale && !infoFromSeller.urgencyOfSale) {
      return;
    }
    if (localUrgencyOfSale === infoFromSeller.urgencyOfSale) {
      return;
    }
    onUrgencyOfSaleChanged(localUrgencyOfSale);
  };

  const updateState = (updatedInfoFromSeller) => {
    setLocalHasAccidents(updatedInfoFromSeller.hasAccidents || false);
    setLocalAccidentsDetails(updatedInfoFromSeller.accidentsDetails || "");
    setLocalVIN(updatedInfoFromSeller.vin || "");
    setLocalTrim(updatedInfoFromSeller.trim || "");
    setLocalMoneyOwedOnCar(updatedInfoFromSeller.moneyOwedOnCar || "");
    setLocalDamagesOnCar(updatedInfoFromSeller.damagesOnCar || "");
    setLocalReasonToSell(updatedInfoFromSeller.reasonToSell || "");
    setLocalUrgencyOfSale(updatedInfoFromSeller.urgencyOfSale || "");
    setLocalIsSmoked(updatedInfoFromSeller.isSmoked || false);
    setLocalIsPriceNegotiable(updatedInfoFromSeller.isPriceNegotiable || false);
  };

  useEffect(() => {
    updateState(infoFromSeller);
  }, [infoFromSeller]);

  return (
    <section className={s["info-from-seller"]}>
      <div className={s["info-from-seller__title"]} onClick={handleClickTitle}>
        <p>
          <strong>Info from Seller</strong> (editable)
        </p>
        <ExpandIndicator src={expandIndicatorIcon} isOpen={isExpanded} />
      </div>
      <div
        className={
          s["info-from-seller_contents-wrapper"] +
          (isExpanded
            ? ` ${s["info-from-seller_contents-wrapper-expanded"]}`
            : "")
        }
      >
        <div className={s["info-from-seller__contents"]}>
          <p
            className={
              s["info-from-seller__contents-text"] +
              " " +
              s["info-from-seller__contents-firstrow"]
            }
          >
            Accidents
          </p>
          <div className={s["info-from-seller__contents-firstrow"]}>
            <Checkbox
              isChecked={localHasAccidents}
              onChange={handleChangeHasAccidents}
            />
          </div>
          <p className={s["info-from-seller__contents-text"]}>
            Accidents Details
          </p>
          <div>
            <Input
              className={s["info-from-seller__contents__input"]}
              placeholder="Fill in"
              value={localAccidentsDetails}
              onChange={(value) => setLocalAccidentsDetails(value)}
              onBlur={handleBlurAccidentsDetails}
            />
          </div>
          <p className={s["info-from-seller__contents-text"]}>VIN</p>
          <div>
            <Input
              className={s["info-from-seller__contents__input"]}
              placeholder="Fill in"
              value={localVIN}
              onChange={(value) => setLocalVIN(value)}
              onBlur={handleBlurVIN}
            />
          </div>
          <p className={s["info-from-seller__contents-text"]}>Trim</p>
          <div>
            <Input
              className={s["info-from-seller__contents__input"]}
              placeholder="Fill in"
              value={localTrim}
              onChange={(value) => setLocalTrim(value)}
              onBlur={handleBlurTrim}
            />
          </div>
          <p className={s["info-from-seller__contents-text"]}>
            Money owed on car
          </p>
          <div>
            <Input
              className={s["info-from-seller__contents__input"]}
              placeholder="Fill in"
              value={localMoneyOwedOnCar}
              onChange={(value) => setLocalMoneyOwedOnCar(value)}
              onBlur={handleBlurMoneyOwedOnCar}
            />
          </div>
          <p className={s["info-from-seller__contents-text"]}>Damages on car</p>
          <div>
            <Input
              className={s["info-from-seller__contents__input"]}
              placeholder="Fill in"
              value={localDamagesOnCar}
              onChange={(value) => setLocalDamagesOnCar(value)}
              onBlur={handleBlurDamagesOnCar}
            />
          </div>
          <p className={s["info-from-seller__contents-text"]}>Reason to sell</p>
          <div>
            <Input
              className={s["info-from-seller__contents__input"]}
              placeholder="Fill in"
              value={localReasonToSell}
              onChange={(value) => setLocalReasonToSell(value)}
              onBlur={handleBlurReasonToSell}
            />
          </div>
          <p className={s["info-from-seller__contents-text"]}>
            Urgency of sale
          </p>
          <div>
            <Input
              className={s["info-from-seller__contents__input"]}
              placeholder="Fill in"
              value={localUrgencyOfSale}
              onChange={(value) => setLocalUrgencyOfSale(value)}
              onBlur={handleBlurUrgencyOfSale}
            />
          </div>
          <p className={s["info-from-seller__contents-text"]}>Smoked</p>
          <div>
            <Checkbox
              isChecked={localIsSmoked}
              onChange={handleChangeIsSmoked}
            />
          </div>
          <p className={s["info-from-seller__contents-text"]}>
            Price is negotiable
          </p>
          <div>
            <Checkbox
              isChecked={localIsPriceNegotiable}
              onChange={handleChangeIsPriceNegotiable}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoFromSeller;
