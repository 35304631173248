import serviceApi from "services/api";

const baseURL = "savedsearches";

const getSavedSearches = (userId, pageNumber, pageSize) => {
  const paging = {
    page: pageNumber,
    page_size: pageSize,
  };

  return serviceApi.get(`${baseURL}`, {
    user_id: userId,
    ...paging,
  });
};

const getSavedSearch = (savedSearchId) =>
  serviceApi.get(`${baseURL}/${savedSearchId}`);

const addSavedSearch = (
  title,
  zipCode,
  sorting,
  filters,
  searchText,
  thumbnail
) =>
  serviceApi.post(`${baseURL}`, {
    title: title,
    query: {
      zipCode,
      sorting,
      filters,
      searchText,
    },
    thumbnail,
  });

const updateSavedSearch = (
  savedSearchId,
  title,
  zipCode,
  sorting,
  filters,
  searchText,
  thumbnail
) =>
  serviceApi.put(`${baseURL}/${savedSearchId}`, {
    title,
    query: {
      zipCode,
      sorting,
      filters,
      searchText,
    },
    thumbnail,
  });

const deleteSavedSearch = (savedSearchId) =>
  serviceApi.delete(`${baseURL}/${savedSearchId}`);

const startAutoConvos = (savedSearchId, isForceStart) =>
  serviceApi.post(`${baseURL}/${savedSearchId}/start_autoconvo`, {
    is_force_start: isForceStart,
  });

const stopAutoConvos = (savedSearchId) =>
  serviceApi.post(`${baseURL}/${savedSearchId}/stop_autoconvo`);

const savedSearchesGateway = {
  getSavedSearches,
  getSavedSearch,
  addSavedSearch,
  updateSavedSearch,
  deleteSavedSearch,
  startAutoConvos,
  stopAutoConvos,
};

export default savedSearchesGateway;
