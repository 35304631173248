import Loader from "components/Loader";
import VehicleFavorite from "components/VehicleFavorite";
import FilledButton from "components/buttons/FilledButton";

import utilFormatter from "utils/formatter";

import iconMapPoint from "assets/icons/map-point.svg";

import s from "./index.module.scss";

const MainInfoCustomer = ({ vehicle = {}, isLoading = false }) => {
  const {
    build: { year = "", make = "", model = "", trim = "" } = {},
    delisted = false,
    mileage = null,
    dist = null,
    price = null,
  } = vehicle;

  const creditApplicationURL = "https://www.ratedrop.ca/credit-application";

  const isMarginPriceRow =
    typeof mileage !== "number" && typeof dist !== "number";

  const handleClickCreditApplication = () =>
    window.open(creditApplicationURL, "_blank");

  return (
    <div className={s["customer-main-info__main"]}>
      {isLoading ? (
        <></>
      ) : (
        <>
          {" "}
          <div className={s["customer-main-info__row"]}>
            <span className={s["customer-main-info__text"]}>{year}</span>
            {delisted && (
              <p className={s["customer-main-info__badge"]}>Delisted</p>
            )}
          </div>
          <div>
            <span
              className={
                s["customer-main-info__text"] +
                " " +
                s["customer-main-info__text--bold"]
              }
            >
              {make}
            </span>
            &nbsp;
            <span
              className={
                s["customer-main-info__text"] +
                " " +
                s["customer-main-info__text--bold"]
              }
            >
              {model}
            </span>
            &nbsp;
            <span
              className={
                s["customer-main-info__text"] +
                " " +
                s["customer-main-info__text--bold"]
              }
            >
              {trim}
            </span>
          </div>
          {typeof mileage === "number" && (
            <div className={s["customer-main-info__row"]}>
              <p className={s["customer-main-info__text"]}>
                {utilFormatter.prettyString(parseInt(mileage), " ")} km
              </p>
            </div>
          )}
          <div className={s["customer-main-info__row"]}>
            <div className={s["customer-main-info__details"]}>
              {typeof dist === "number" && (
                <div className={s["customer-main-info__distance-wrapper"]}>
                  <img
                    className={s["customer-main-info__icon-map-point"]}
                    src={iconMapPoint}
                    alt="Distance to vehicle"
                  />
                  <p
                    className={
                      s["customer-main-info__text"] +
                      " " +
                      s["customer-main-info__text--distance"]
                    }
                  >
                    {utilFormatter.prettyString(parseInt(dist), " ")} km
                  </p>
                </div>
              )}
              <div className={s["customer-main-info__vehicle-favorite"]}>
                <VehicleFavorite />
              </div>
            </div>
          </div>
          <div
            className={
              s["customer-main-info__row"] +
              (isMarginPriceRow
                ? ` ${s["customer-main-info__row--margin"]}`
                : "")
            }
          >
            <div className={s["customer-main-info__wrapper"]}>
              {price ? (
                <p
                  className={
                    s["customer-main-info__text"] +
                    " " +
                    s["customer-main-info__text--price"]
                  }
                >
                  {typeof price === "number" &&
                    "$" + utilFormatter.prettyString(price, ",")}
                </p>
              ) : (
                <p
                  className={
                    s["customer-main-info__text"] +
                    " " +
                    s["customer-main-info__text--price-disclaimer"]
                  }
                >
                  This vehicle was, or is, listed on an auction so there is no
                  asking price. If you think the vehicle is one you would be
                  interested in, the pricing and availability would need to be
                  confirmed. Add to your watchlist if it meets your needs as a
                  vehicle, and we will confirm details for you.
                </p>
              )}
            </div>
          </div>
          {!delisted && (
            <div
              className={
                s["customer-main-info__row"] +
                " " +
                s["customer-main-info__row-credit"]
              }
            >
              <FilledButton
                className={s["customer-main-info__input-credit"]}
                onClick={handleClickCreditApplication}
              >
                Fill out a credit application
              </FilledButton>
            </div>
          )}
        </>
      )}
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default MainInfoCustomer;
