import VehiclesListTableRow from "components/Tables/VehicleListTable/VehicleListTableRow";
import s from "./index.module.scss";

const VehicleListTable = ({
  vehicles = [],
  rowComponent = null,
  vehicleRepository,
}) => {
  if (vehicles.length === 0) return <></>;

  const RowComponent = rowComponent || VehiclesListTableRow;

  return (
    <div className={s["vehicle-list-table-wrapper"]}>
      <div className={s["vehicle-list-table"]}>
        <div className={s["vehicle-list-table__header"]}>
          <div className={s["vehicle-list-table__left-side-fields"]}>
            <span
              className={
                s["vehicle-list-table__text"] +
                " " +
                s["vehicle-list-table__text--source"] +
                " " +
                s["vehicle-list-table__text--left"]
              }
            >
              Source
            </span>
            <span
              className={
                s["vehicle-list-table__text"] +
                " " +
                s["vehicle-list-table__text--left"]
              }
            >
              YMMT
            </span>
            <span className={s["vehicle-list-table__text"]}>Mileage (km)</span>
            <span className={s["vehicle-list-table__text"]}>VIN</span>
            <span className={s["vehicle-list-table__text"]}>
              On market (days)
            </span>
            <span className={s["vehicle-list-table__text"]}>
              Asking price ($)
            </span>
          </div>
          <div className={s["vehicle-list-table__right-side-fields"]}>
            <span
              className={
                s["vehicle-list-table__text"] +
                " " +
                s["vehicle-list-table__text--offered-price"]
              }
            >
              Our offered price ($)
            </span>
            <span
              className={
                s["vehicle-list-table__text"] +
                " " +
                s["vehicle-list-table__text--lowest-offered-price"]
              }
            >
              Their lowest offered price ($)
            </span>
          </div>
        </div>
        <div className={s["vehicle-list-table__vehicles"]}>
          {vehicles.map((vehicle) => (
            <RowComponent
              key={vehicle.id}
              vehicle={vehicle}
              vehicleRepository={vehicleRepository}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VehicleListTable;
