import utilConstants from "utils/constants";

const vehicleInfoTabs = (vehicle, userRole) =>
  utilConstants.vehicleInfoTabs.filter((vehicleInfoTab) => {
    if (vehicleInfoTab.userRoles.includes(userRole)) {
      if (!vehicleInfoTab.optional) {
        return true;
      }

      if (vehicle[vehicleInfoTab.value]) {
        return true;
      }
    }
    return false;
  });

const util = {
  vehicleInfoTabs,
};

export default util;
