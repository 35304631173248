import { configureStore } from "@reduxjs/toolkit";
import reducerAuth from "slicers/auth";
import reducerUser from "slicers/user";
import reducerUsers from "slicers/users";
import reducerVehicles from "slicers/vehicles";
import reducerCustomers from "slicers/customers";
import reducerConversations from "slicers/conversations";
import reducerConversation from "slicers/conversation";
import reducerActivities from "slicers/activities";
import reducerHotlist from "slicers/hotlist";
import reducerSavedSearch from "slicers/savedsearch";

export default configureStore({
  reducer: {
    auth: reducerAuth,
    user: reducerUser,
    users: reducerUsers,
    vehicles: reducerVehicles,
    customers: reducerCustomers,
    conversations: reducerConversations,
    conversation: reducerConversation,
    activities: reducerActivities,
    hotlist: reducerHotlist,
    savedsearch: reducerSavedSearch,
  },
});
