import { useState } from "react";
import { Link } from "react-router-dom";
import s from "./index.module.scss";

const WatchListCard = ({
  watchList: {
    thumbnail = null,
    client_name: clientName = "",
    title = "",
    number_of_vehicles: numberOfVehicles = "",
    id = "",
  } = {},
}) => {
  const [isErrorImageLoading, setIsErrorImageLoading] = useState(false);

  const handleErrorThumbnail = () => setIsErrorImageLoading(true);

  return (
    <Link className={s["watch-list-card"]} to={`/employee/watch-lists/${id}`}>
      {thumbnail?.link && !isErrorImageLoading ? (
        <img
          className={s["watch-list-card__thumbnail"]}
          src={thumbnail.link}
          alt="Watchlists thumbnail"
          onError={handleErrorThumbnail}
        />
      ) : (
        <div className={s["watch-list-card__thumbnail-placeholder"]}></div>
      )}
      <div className={s["watch-list-card__info"]}>
        <p
          className={
            s["watch-list-card__text"] + " " + s["watch-list-card__text--bold"]
          }
        >
          {title}
          <span className={s["watch-list-card__text--green"]}>
            {" "}
            ({numberOfVehicles})
          </span>
        </p>
        <div className={s["watch-list-card__text"]}>{clientName}</div>
      </div>
    </Link>
  );
};

export default WatchListCard;
