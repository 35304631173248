import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import iconAvatar from "assets/icons/avatar-placeholder.svg";
import iconSave from "assets/icons/icon-save.svg";
import iconSaveDisabled from "assets/icons/icon-save-disabled.svg";
import iconTrash from "assets/icons/trash-grey.svg";
import iconPen from "assets/icons/pen-grey.svg";
import iconCross from "assets/icons/cross-black-bold.svg";
import serviceVehicles from "services/vehicles";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const Editor = ({ event = {}, vehicleId = "", onRemove = () => {} }) => {
  const {
    comment: { text = "", id: commentId = "" } = "",
    event_at: eventAt = "",
    user: { avatar = {} } = "",
  } = event;

  const [defaultValue, setDefaultValue] = useState(text);
  const [value, setValue] = useState(text);
  const [isLoading, setIsLoading] = useState(false);
  const [opportunityChange, setOpportunityChange] = useState(false);

  const isAbleToSave = !isLoading && text !== value && value.trim();

  const textareaRef = useRef();

  const resize = () => {
    const textarea = textareaRef.current;

    if (!textarea) {
      return;
    }

    textarea.style.height = `max-content`;
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(resize, [value]);

  const handleChange = (e) => setValue(e.target.value);

  const handleClickSave = () => {
    if (!isAbleToSave) {
      return;
    }
    setIsLoading(true);
    if (!value) {
      return;
    }
    serviceVehicles
      .changeComment(vehicleId, commentId, value.trim())
      .then(() => {
        setDefaultValue(value.trim());
        setValue(value.trim());
        toast.success("Comment saved", {
          position: "bottom-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
        setOpportunityChange(false);
      });
  };

  const handleClickTrash = () => {
    setIsLoading(true);
    serviceVehicles
      .removeComment(vehicleId, commentId)
      .then(() => {
        toast.success("Сomment removed", {
          position: "bottom-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        onRemove(event);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleClickFocus = () => {
    setOpportunityChange(true);
    textareaRef.current.setSelectionRange(value.length, value.length);
    textareaRef.current.focus();
  };

  const handleClickClose = () => {
    setValue(defaultValue);
    setOpportunityChange(false);
  };

  return (
    <div className={s["comment-wrapper"]}>
      <img
        className={s["comment-avatar"]}
        src={avatar?.link || iconAvatar}
        alt="Avatar"
      />
      <div className={s["comment"]}>
        <textarea
          className={s["comment__textarea"]}
          placeholder="Some comment here"
          value={value}
          onChange={handleChange}
          rows="1"
          ref={textareaRef}
          readOnly={!opportunityChange}
        ></textarea>
        {opportunityChange ? (
          <div className={s["comment__icons"]}>
            <img
              className={s["comment__icon"]}
              src={isAbleToSave ? iconSave : iconSaveDisabled}
              alt="Save notes"
              onClick={handleClickSave}
            />
            <img
              className={s["comment__icon"]}
              src={iconCross}
              alt="Close"
              onClick={handleClickClose}
            />
          </div>
        ) : (
          <div className={s["comment__icons"]}>
            <img
              className={s["comment__icon"]}
              src={iconPen}
              alt="Change"
              onClick={handleClickFocus}
            />
            <img
              className={s["comment__icon"]}
              src={iconTrash}
              alt="Trash"
              onClick={handleClickTrash}
            />
          </div>
        )}
        <div className={s["comment__date"] + " " + s["comment__date--my"]}>
          {utilFormatter.prettyDateTimeJsx(eventAt)}
        </div>
      </div>
    </div>
  );
};

export default Editor;
