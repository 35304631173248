import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UsersTable from "components/Tables/UsersTable";
import Pagination from "components/Pagination";
import serviceUsers from "services/users";
import { setPage, setPageSize, resetState } from "slicers/users";
import s from "./index.module.scss";

const Users = ({ setRole, roleType = {} }) => {
  const users = useSelector((state) => state.users.users);
  const page = useSelector((state) => state.users.page);
  const pageSize = useSelector((state) => state.users.pageSize);
  const isLoading = useSelector((state) => state.users.isLoading);
  const pageCount = useSelector((state) => state.users.pageCount);

  const dispatch = useDispatch();

  useEffect(() => {
    serviceUsers.getEmployeesPage();
    setRole(roleType.salesRep);
  }, [page, pageSize, setRole, roleType.salesRep]);

  useEffect(() => () => dispatch(resetState()), [dispatch]);

  const handleChangePage = (page) => dispatch(setPage(page));

  const handleChangePageSize = (pageSize) => {
    dispatch(setPageSize(pageSize));
    dispatch(setPage(1));
  };

  return (
    <div className={s["users"]}>
      <UsersTable users={users} role={roleType.salesRep} roleType={roleType} />
      <Pagination
        page={page}
        pageSize={pageSize}
        pageCount={pageCount}
        isLoading={isLoading}
        onChangePage={handleChangePage}
        onChangePageSize={handleChangePageSize}
      />
    </div>
  );
};

export default Users;
