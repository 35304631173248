import { toast } from "react-toastify";

const bottomOptions = {
  position: "bottom-right",
  autoClose: 2000,
  closeOnClick: true,
  pauseOnHover: true,
};

const displaySuccess = (message) => {
  toast.success(message);
};

const displaySuccessBottom = (message) => {
  toast.success(message, bottomOptions);
};

const displayError = (message) => {
  toast.error(message);
};

const displayErrorBottom = (message) => {
  toast.error(message, bottomOptions);
};

const toaster = {
  displaySuccess,
  displaySuccessBottom,
  displayError,
  displayErrorBottom,
};

export default toaster;
