import { useState, useEffect } from "react";
import iconAvatarPlaceholder from "assets/icons/avatar-placeholder.svg";
import s from "./index.module.scss";

const Avatar = ({ className = "", avatar = null }) => {
  const [src, setSrc] = useState(iconAvatarPlaceholder);

  useEffect(() => {
    if (avatar instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(avatar);
      reader.onloadend = () => setSrc(reader.result);
      return;
    }

    if (typeof avatar?.link === "string") {
      setSrc(avatar.link);
      return;
    }

    setSrc(iconAvatarPlaceholder);
  }, [avatar]);

  const handleAvatarError = () => setSrc(iconAvatarPlaceholder);

  return (
    <img
      className={s["avatar"] + (className ? ` ${className}` : "")}
      src={src}
      alt="User avatar"
      onError={handleAvatarError}
    />
  );
};

export default Avatar;
