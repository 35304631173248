import { useState } from "react";

export const useInput = (defaultValue = "") => {
  const [value, setValue] = useState(defaultValue);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const controller = {
    value,
    setValue,
    isError,
    setIsError,
    errorText,
    setErrorText,
  };

  return controller;
};
