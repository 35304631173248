import serviceApi from "services/api";

const baseURL = "hotlist";

const getVehiclePageParams = (pageNumberValue, pageSizeValue) => {
  return {
    page: pageNumberValue,
    page_size: pageSizeValue,
  };
};

const getVehiclesPage = (pageNumber, pageSize) => {
  const vehiclePageParams = getVehiclePageParams(pageNumber, pageSize);

  return serviceApi.get(baseURL, vehiclePageParams);
};

const hotlistGateway = {
  getVehiclesPage,
};

export default hotlistGateway;
