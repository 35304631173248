import reloadIcon from "assets/icons/reload.svg";
import s from "./index.module.scss";

const ReloadPageButton = ({ onClick = () => {} }) => {
  return (
    <button className={s["hotlist__reload-btn"]} onClick={onClick}>
      <img
        alt="reload-icon"
        src={reloadIcon}
        className={s["hotlist__reload-icon"]}
      />
    </button>
  );
};

export default ReloadPageButton;
