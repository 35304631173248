import s from "./index.module.scss";

const SimpleInput = ({
  wrapperClassName = "",
  className = "",
  value = "",
  disabled = false,
  placeholder = "",
  error = "",
  onChange = () => {},
  onKeyDown = () => {},
}) => (
  <div
    className={
      s["simple-input"] + (wrapperClassName ? ` ${wrapperClassName}` : "")
    }
  >
    <input
      type="text"
      disabled={disabled}
      className={
        s["simple-input__input"] +
        (error ? ` ${s["simple-input__input--error"]}` : "") +
        (className ? ` ${className}` : "")
      }
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
    {error && <p className={s["simple-input__error"]}>{error}</p>}
  </div>
);

export default SimpleInput;
