import { createRef } from "react";
import Avatar from "components/Avatar";
import UploadPhoto from "components/inputs/UploadPhoto";
import s from "./index.module.scss";

const AvatarUploader = ({
  className = "",
  avatar = null,
  onChange = () => {},
}) => {
  const inputRef = createRef();

  const handleClick = () => inputRef.current.click();

  return (
    <div
      className={s["avatar-uploader"] + (className ? ` ${className}` : "")}
      onClick={handleClick}
    >
      <Avatar avatar={avatar} />
      <UploadPhoto onClick={onChange} inputRef={inputRef} />
    </div>
  );
};

export default AvatarUploader;
