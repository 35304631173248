import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({
  render = () => {},
  rule = () => {},
  redirectTo = "",
  ...rest
}) => {
  return (
    <Route
      render={() => {
        if (!rule()) {
          return <Redirect to={redirectTo} />;
        }
        return render();
      }}
      {...rest}
    />
  );
};

export default ProtectedRoute;
