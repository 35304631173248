import Modal from "components/Modal";
import FilledButton from "components/buttons/FilledButton";
import iconCrossBlack from "assets/icons/cross-black.svg";
import s from "./index.module.scss";

const StartNewConvoModal = ({ isOpen = false, onClose = () => {} }) => {
  const handleClose = (isAgreed = false) => {
    onClose(isAgreed);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose(false)}>
      <div className={s["start-new-convo-modal"]}>
        <img
          className={s["start-new-convo-modal__cross"]}
          src={iconCrossBlack}
          alt="Close"
          onClick={() => handleClose(false)}
        />
        <p className={s["start-new-convo-modal__text"]}>
          Current conversation will be
          <br />
          removed. Are you sure you want
          <br />
          to start a new convo?
        </p>
        <FilledButton
          className={s["start-new-convo-modal__button"]}
          onClick={() => handleClose(true)}
        >
          Start new convo
        </FilledButton>
      </div>
    </Modal>
  );
};

export default StartNewConvoModal;
