import { useState, useCallback } from "react";
import { useSelector } from "react-redux";

const useVehiclesListTableRow = (vehicleRepository) => {
  const lastAccessedVehicleId = useSelector((state) =>
    vehicleRepository ? vehicleRepository.getLastAccessedVehicleId(state) : ""
  );

  const [isThumbnailLoadingError, setIsThumbnailLoadingError] = useState(false);

  const onClickVehicleLinkCell = useCallback(
    (id) => {
      vehicleRepository.updateLastAccessedVehicleId(id);
    },
    [vehicleRepository]
  );

  const onThumbnailLoadingError = useCallback(
    () => setIsThumbnailLoadingError(true),
    []
  );

  const onOfferedPriceChange = useCallback(
    (id, updatedFields) => {
      vehicleRepository.updateVehicleFields(id, updatedFields);
    },
    [vehicleRepository]
  );

  return {
    lastAccessedVehicleId,
    isThumbnailLoadingError,
    onClickVehicleLinkCell,
    onOfferedPriceChange,
    onThumbnailLoadingError,
  };
};

export default useVehiclesListTableRow;
