import { Route, Redirect } from "react-router-dom";
import serviceAuth from "services/auth";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      render={() =>
        serviceAuth.isAuthed() ? <Redirect to="/cabinet" /> : <Component />
      }
      {...rest}
    />
  );
};

export default PublicRoute;
