import { useLocation, useHistory } from "react-router-dom";
import iconAvatarPlaceholder from "assets/icons/avatar-placeholder.svg";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const OtherConversation = ({ conversation = {} }) => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);

  const handleClickConversation = () => {
    queryParams.set("user_id", userId);
    history.replace({ search: queryParams.toString() });
  };

  const {
    user: {
      first_name: firstName = "",
      last_name: lastName = "",
      id: userId = "",
    } = {},
    lastMessageText = "",
    media = [],
    createdAt = "",
    imgData = {},
    isUserSender = false,
  } = conversation;

  const date = utilFormatter.prettyDate(createdAt);
  const time = utilFormatter.prettyTime(createdAt);

  const imgAttrs = imgData.src ? imgData : { src: iconAvatarPlaceholder };

  return (
    <div className={s["other-conversation"]} onClick={handleClickConversation}>
      <div className={s["other-conversation__value-date"]}>{date}</div>
      <div className={s["other-conversation__label"]}>Sales Rep</div>
      <div
        className={
          s["other-conversation__value"] +
          " " +
          s["other-conversation__sales-rep"]
        }
      >
        {firstName} {lastName}
      </div>
      <div className={s["other-conversation__label"]}>Last Message</div>
      <div
        className={
          s["other-conversation__value"] +
          " " +
          s["other-conversation__last-message-wrapper"]
        }
      >
        <img
          className={
            s["other-conversation__img"] +
            (isUserSender ? ` ${s["other-conversation__img--rounded"]}` : "")
          }
          {...imgAttrs}
        />
        {media.length > 0 ? (
          <div className={s["other-conversation__value-last-image"]}>
            <img
              alt="attached media"
              src={media[media.length - 1].link}
              className={s["other-conversation__message-image"]}
            />
          </div>
        ) : (
          <div className={s["other-conversation__last-message"]}>
            {lastMessageText}
          </div>
        )}
      </div>
      <div className={s["other-conversation__label"]}>Date</div>
      <div
        className={
          s["other-conversation__value"] +
          " " +
          s["other-conversation__datetime"]
        }
      >
        {`${date} ${time}`}
      </div>
    </div>
  );
};

export default OtherConversation;
