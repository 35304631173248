import { createSlice } from "@reduxjs/toolkit";

export const conversationsSlice = createSlice({
  name: "conversations",
  initialState: {
    hasUnread: false,
    vehicleMessages: [],
  },
  reducers: {
    setHasUnread: (state, action) => {
      state.hasUnread = action.payload;
    },
    setVehicleMessages: (state, action) => {
      state.vehicleMessages = action.payload;
    },
    clearVehicleMessages: (state) => {
      state.vehicleMessages = [];
    },
  },
});

export const { setHasUnread, setVehicleMessages, clearVehicleMessages } =
  conversationsSlice.actions;

export default conversationsSlice.reducer;
