import { useSelector } from "react-redux";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import Avatar from "components/Avatar";
import imageLogoFull from "assets/images/logo-autoagents-white.svg";
import imageLogoMobile from "assets/images/logo-white.svg";
import imagePrevWhite from "assets/icons/prev-page.svg";
import iconSignOutWhite from "assets/icons/exit-white.svg";
import iconFavoriteWatchLists from "assets/icons/favorite-watch-lists.svg";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const CustomerHeader = ({ isHiddenProtected = false }) => {
  const avatar = useSelector((state) => state.user.avatar);
  const firstName = useSelector((state) => state.user.firstName);

  const location = useLocation();

  return (
    <>
      <header className={s["customer-header"]}>
        <div className={s["customer-header__wrapper-content"]}>
          <div className={s["customer-header__left-side"]}>
            <Link className={s["customer-header__logo-link"]} to="">
              <img
                className={s["customer-header__logo-mobile"]}
                src={imageLogoMobile}
                alt="AutoAgents Logo"
              />
              <img
                className={s["customer-header__logo"]}
                src={imageLogoFull}
                alt="AutoAgents Logo"
              />
            </Link>
            <Switch>
              <Route exact path="/customer/vehicles">
                <></>
              </Route>
              <Route>
                <Link to={utilFormatter.removeLastDirectory(location.pathname)}>
                  <img
                    className={s["customer-header__prev-page-icon"]}
                    src={imagePrevWhite}
                    alt="Back"
                  />
                </Link>
              </Route>
            </Switch>
          </div>
          <div
            className={
              s["customer-header__right-side"] +
              " " +
              (isHiddenProtected
                ? ` ${s["customer-header__right-side--stripped"]}`
                : "")
            }
          >
            {!isHiddenProtected && (
              <Link
                className={s["customer-header__watch-lists-link"]}
                to="/customer/watch-list"
              >
                <img
                  className={s["customer-header__watch-lists-icon"]}
                  src={iconFavoriteWatchLists}
                  alt="My Watchlist"
                  title="My Watchlist"
                />
              </Link>
            )}
            <Link className={s["customer-header__info"]} to="/customer/profile">
              <p className={s["customer-header__greeting"]}>
                {firstName && `Hello, ${firstName}!`}
              </p>
              <Avatar
                className={s["customer-header__avatar"]}
                avatar={avatar}
              />
            </Link>

            <Link className={s["customer-header__logout-link"]} to="/logout">
              <img
                className={s["customer-header__sign-out-icon"]}
                src={iconSignOutWhite}
                alt="Sign Out"
                title="Sign Out"
              />
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};
export default CustomerHeader;
