import { useState } from "react";

import SaveSearchModal from "components/modals/SaveSearchModal";
import ServiceSaveSearch from "services/saved-searches";

import toaster from "utils/toaster";

import s from "./index.module.scss";

const SaveSearchButton = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleClickSaveSearch = () => {
    setIsOpenModal(true);
  };

  const handleCloseSaveSearch = () => {
    setIsOpenModal(false);
  };

  const handleDoneSaveSearch = (title) => {
    ServiceSaveSearch.addSavedSearch(title)
      .then(() => {
        toaster.displaySuccessBottom("Search saved");
      })
      .catch(() => {
        toaster.displayErrorBottom("Something went wrong. Please try again");
      })
      .finally(() => setIsOpenModal(false));
  };

  return (
    <div className={s["save-search"]}>
      <button className={s["save-search__btn"]} onClick={handleClickSaveSearch}>
        save search
      </button>
      <SaveSearchModal
        isOpen={isOpenModal}
        onClose={handleCloseSaveSearch}
        onDone={handleDoneSaveSearch}
      />
    </div>
  );
};

export default SaveSearchButton;
