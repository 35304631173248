import errorText from "utils/constants";

const validateEmailAndGetError = (email) => {
  const isValid = /\S+@\S+\.\S+/.test(email);

  if (!isValid) {
    return errorText.errorMessages.emailValidationError;
  }
  return "";
};

const validateZipCodeAndGetError = (zipCode) => {
  const isValid = /[a-zA-Z][0-9][a-zA-Z][0-9][a-zA-Z][0-9]/.test(zipCode);

  if (!isValid) {
    return errorText.errorMessages.zipCodeValidationError;
  }
  return "";
};

const validatePasswordAndGetError = (value) => {
  const isWhiteSpace = value.includes(" ");

  if (value.length < 6 || isWhiteSpace) {
    return errorText.errorMessages.passwordValidationError;
  }
  return "";
};

const validatePasswordConfirmationAndGetError = (password1, password2) => {
  const isMatch = password1 === password2;

  if (!isMatch) {
    return errorText.errorMessages.matchPasswordError;
  }
  return "";
};

const validator = {
  validateEmailAndGetError,
  validateZipCodeAndGetError,
  validatePasswordAndGetError,
  validatePasswordConfirmationAndGetError,
};

export default validator;
