import { useCallback } from "react";
import { useSelector } from "react-redux";

const useVehiclesSelection = (vehicleRepository = null, isEnabled = true) => {
  const isAllVehiclesSelected = useSelector((state) =>
    isEnabled ? vehicleRepository.getIsAllVehiclesSelected(state) : false
  );

  const onChangeVehiclesSelection = useCallback(
    (isChecked) => {
      if (isChecked) {
        vehicleRepository.selectAllVehicles();
        return;
      }

      vehicleRepository.unselectAllVehicles();
      return;
    },
    [vehicleRepository]
  );

  const onChangeVehicleSelection = useCallback(
    (isChecked, id) => {
      if (isChecked) {
        return vehicleRepository.selectVehicle(id);
      }

      return vehicleRepository.unselectVehicle(id);
    },
    [vehicleRepository]
  );

  return {
    isAllVehiclesSelected,
    onChangeVehiclesSelection,
    onChangeVehicleSelection,
  };
};

export default useVehiclesSelection;
