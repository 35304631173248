import { Route, Redirect } from "react-router-dom";
import serviceAuth from "services/auth";

const PrivateRoute = ({
  component: Component,
  roles = [],
  allowAllRoles = false,
  ...rest
}) => {
  return (
    <Route
      render={() => {
        if (!serviceAuth.isAuthed()) {
          return <Redirect to="/sign-in" />;
        }
        if (!allowAllRoles && !roles.includes(serviceAuth.getUserRole())) {
          return <Redirect to="/cabinet" />;
        }
        return <Component />;
      }}
      {...rest}
    />
  );
};

export default PrivateRoute;
