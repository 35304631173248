import serviceApi from "services/api";

const baseURL = "watchlists";

const getWatchlists = (userId, pageNumber, pageSize) => {
  const paging = {
    page: pageNumber,
    page_size: pageSize,
  };

  return serviceApi.get(`${baseURL}`, {
    user_id: userId,
    ...paging,
  });
};

const addVehicleToWatchlist = (watchListId, vehicleIds, addons) => {
  return serviceApi.post(`${baseURL}/${watchListId}/vehicles`, {
    vehicles_id: vehicleIds,
    ...addons,
  });
};

const watchlistsGateway = {
  getWatchlists,
  addVehicleToWatchlist,
};

export default watchlistsGateway;
