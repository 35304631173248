import { Link } from "react-router-dom";

import useVehicleCard from "hooks/useVehicleCard";

import iconTrashRedSmall from "assets/icons/trash-red-small.svg";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const WatchListDetailsVehicleCard = ({
  vehicle: {
    to = "",
    linkParams = {},
    thumbnail = null,
    year = null,
    make = "",
    model = "",
    trim = "",
    mileage = null,
    price = null,
    delisted = false,
    onRemove = () => {},
  } = {},
}) => {
  const { isThumbnailLoadingError, onThumbnailLoadingError } = useVehicleCard();

  const handleClickRemove = (e) => {
    e.preventDefault();
    onRemove();
  };

  return (
    <Link
      className={s["watch-list-details-vehicle-card"]}
      to={to}
      {...linkParams}
    >
      {thumbnail?.link && !isThumbnailLoadingError ? (
        <img
          className={s["watch-list-details-vehicle-card__photo"]}
          src={thumbnail.link}
          alt="Watchlists thumbnail"
          onError={onThumbnailLoadingError}
        />
      ) : (
        <div
          className={s["watch-list-details-vehicle-card__placeholder"]}
        ></div>
      )}
      <div
        className={s["watch-list-details-vehicle-card__description-wrapper"]}
      >
        <div
          className={
            s["watch-list-details-vehicle-card__description"] +
            " " +
            s["watch-list-details-vehicle-card__description--left-side"]
          }
        >
          <p
            className={
              s["watch-list-details-vehicle-card__text"] +
              " " +
              s["watch-list-details-vehicle-card__text--left-side"]
            }
          >
            {typeof year === "number" && year}
          </p>
          <p
            className={
              s["watch-list-details-vehicle-card__text"] +
              " " +
              s["watch-list-details-vehicle-card__text--left-side"] +
              " " +
              s["watch-list-details-vehicle-card__text--bold"]
            }
          >
            {make} {model} {trim}
          </p>
          <p
            className={
              s["watch-list-details-vehicle-card__text"] +
              " " +
              s["watch-list-details-vehicle-card__text--left-side"]
            }
          >
            {typeof mileage === "number" &&
              utilFormatter.prettyString(parseInt(mileage), " ") + " km"}
          </p>
        </div>
        <div
          className={
            s["watch-list-details-vehicle-card__description"] +
            " " +
            s["watch-list-details-vehicle-card__description--right-side"]
          }
        >
          {!!price && typeof price === "number" && (
            <p
              className={
                s["watch-list-details-vehicle-card__text"] +
                " " +
                s["watch-list-details-vehicle-card__text--right-side"] +
                " " +
                s["watch-list-details-vehicle-card__text--price"]
              }
            >
              {"$" + utilFormatter.prettyString(price, ",")}
            </p>
          )}
        </div>
      </div>
      {delisted && (
        <div className={s["watch-list-details-vehicle-card__delisted"]}>
          <p className={s["watch-list-details-vehicle-card__badge"]}>
            Delisted
          </p>
        </div>
      )}
      <img
        className={s["watch-list-details-vehicle-card__trash-icon"]}
        src={iconTrashRedSmall}
        alt="Remove"
        onClick={handleClickRemove}
      />
    </Link>
  );
};

export default WatchListDetailsVehicleCard;
