import { useState, useEffect } from "react";
import WatchListCard from "components/WatchListCard";
import Loader from "components/Loader";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const WatchLists = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [watchLists, setWatchLists] = useState([]);

  useEffect(
    () =>
      serviceWatchLists
        .getMyWatchlists()
        .then(({ items }) => setWatchLists(items))
        .finally(() => setIsLoading(false)),
    []
  );

  return (
    <div className={s["watch-lists"]}>
      <p className={s["watch-lists__title"]}>My Watchlists</p>
      <div className={s["watch-lists__grid"]}>
        {watchLists.map((watchList) => (
          <WatchListCard watchList={watchList} key={watchList.id} />
        ))}
      </div>
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default WatchLists;
