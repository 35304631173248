import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import serviceAuth from "services/auth";

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    serviceAuth.logout().then(() => history.push("/"));
  });

  return <></>;
};

export default Logout;
