import Modal from "components/Modal";
import FilledButton from "components/buttons/FilledButton";
import iconCrossBlack from "assets/icons/cross-black.svg";
import s from "./index.module.scss";

const DeleteModal = ({
  isOpen = false,
  onClose = () => {},
  onConfirm = () => {},
  ...props
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={s["delete-modal"]}>
        <img
          className={s["delete-modal__cross"]}
          src={iconCrossBlack}
          alt="Close"
          onClick={onClose}
        />
        <p className={s["delete-modal__text"]}>
          Are you sure you want to remove
          <br />
          <span className={s["delete-modal__text"]}>{props.children}?</span>
        </p>
        <FilledButton className={s["delete-modal__button"]} onClick={onConfirm}>
          Remove
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DeleteModal;
