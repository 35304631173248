import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import AssignedCustomers from "pages/employee/customers/my";
import Unassigned from "pages/employee/customers/unassigned";
import s from "./index.module.scss";

const Customers = () => {
  return (
    <div className={s["customers"]}>
      <div className={s["customers__nav"]}>
        <NavLink
          className={s["customers__link"]}
          to="/employee/customers/my"
          activeClassName={s["customers__link--active"]}
        >
          My Assigned Customers
        </NavLink>
        <NavLink
          className={s["customers__link"]}
          to="/employee/customers/unassigned"
          activeClassName={s["customers__link--active"]}
        >
          Not Assigned Customers
        </NavLink>
      </div>

      <Switch>
        <Route
          path="/employee/customers/my"
          render={() => <AssignedCustomers />}
          exact
        />
        <Route
          path="/employee/customers/unassigned"
          render={() => <Unassigned />}
          exact
        />
        <Route render={() => <Redirect to="/employee/customers/my" />} />
      </Switch>
    </div>
  );
};

export default Customers;
