import { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "components/Loader";
import PublicRoute from "components/routes/PublicRoute";
import PrivateRoute from "components/routes/PrivateRoute";
import SignIn from "pages/sign-in";
import Logout from "pages/logout";
import Cabinet from "pages/cabinet";
import Admin from "pages/admin";
import Employee from "pages/employee";
import PublicWatchLists from "pages/public-watch-lists";
import Customer from "pages/customer";
import SignUp from "pages/sign-up";
import BaseFooter from "components/footers/BaseFooter";
import serviceAuth from "services/auth";
import utilConstants from "utils/constants";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    serviceAuth.loadTokens();
    window.addEventListener("storage", () => {
      serviceAuth.loadTokens();
    });

    serviceAuth
      .refresh()
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Loader isOpen />;
  }

  return (
    <>
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/sign-in" />} />
        <Route
          path="/customer/watch-lists/:watchListId"
          render={(props) => (
            <Redirect
              to={`/public-watch-lists/${props.match.params.watchListId}`}
            />
          )}
        />
        <Route
          path="/public-watch-lists/:watchListId"
          render={() => <PublicWatchLists />}
        />
        <PublicRoute path="/sign-in" component={SignIn} />
        <PublicRoute path="/sign-up" component={SignUp} />
        <PrivateRoute path="/logout" allowAllRoles component={Logout} />
        <PrivateRoute path="/cabinet" allowAllRoles component={Cabinet} />
        <PrivateRoute
          path="/admin"
          roles={[`${utilConstants.roles.admin.name}`]}
          component={Admin}
        />
        <PrivateRoute
          path="/employee"
          roles={[`${utilConstants.roles.employee.name}`]}
          component={Employee}
        />
        <PrivateRoute
          path="/customer"
          component={Customer}
          roles={[`${utilConstants.roles.customer.name}`]}
        />
      </Switch>
      <ToastContainer />
      <BaseFooter />
    </>
  );
};

export default App;
