import { useState, useEffect, useMemo, useCallback } from "react";
import { useScrollToTop } from "hooks/scroll-to-top-hook";
import Loader from "components/Loader";
import Conversation from "components/Conversation";
import ConvosToolbar from "components/ConvosToolbar";
import ConvosCheckbox from "components/inputs/ConvosCheckbox";
import serviceConversations from "services/conversations";
import utilConstants from "utils/constants";
import iconAutoAgents from "../../../assets/images/logo-gray.svg";
import iconScrollToTop from "assets/icons/scroll-to-top.svg";
import s from "./index.module.scss";

const MyConvos = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [selectedConversationVehicleIds, setSelectedConversationVehicleIds] =
    useState([]);
  const [filters, setFilters] = useState({
    timePeriod: null,
    isUnread: true,
    isResponded: false,
  });

  const conversationVehicleIdsForSelection = useMemo(() => {
    return conversations
      .filter((conversation) => !conversation.isDeleted)
      .map((conversation) => conversation.vehicleId);
  }, [conversations]);

  const isAllConversationVehiclesSelected =
    conversationVehicleIdsForSelection.length ===
    selectedConversationVehicleIds.length;

  const { isOpenScrollToTop, handleClickScrollToTop } = useScrollToTop();

  const getConversationTimePeriodInHours = (selectedTimePeriod) => {
    const hoursSinceMidnight = new Date().getHours();

    switch (selectedTimePeriod) {
      case "sinceYesterday":
        return 1 * 24 + hoursSinceMidnight;
      case "past7Days":
        return 6 * 24 + hoursSinceMidnight;
      case "allTime":
        return 0;
      default:
        return 0;
    }
  };

  const handleSelectAllConversationVehicles = useCallback(() => {
    setSelectedConversationVehicleIds(conversationVehicleIdsForSelection);

    setConversations((prevConversations) =>
      prevConversations.map((conversation) => ({
        ...conversation,
        isSelected: true,
      }))
    );
  }, [conversationVehicleIdsForSelection]);

  const handleUnselectAllConversationVehicles = useCallback(() => {
    setSelectedConversationVehicleIds([]);

    setConversations((prevConversations) =>
      prevConversations.map((conversation) => ({
        ...conversation,
        isSelected: false,
      }))
    );
  }, []);

  const handleSelectConversationVehicle = useCallback((conversation) => {
    setSelectedConversationVehicleIds((prevSelectedConversationVehicleIds) => [
      ...prevSelectedConversationVehicleIds,
      conversation.vehicleId,
    ]);

    setConversations((prevConversations) =>
      prevConversations.map((currentConversation) => {
        if (currentConversation.id === conversation.id) {
          return {
            ...currentConversation,
            isSelected: true,
          };
        }

        return currentConversation;
      })
    );
  }, []);

  const handleUnselectConversationVehicle = useCallback((conversation) => {
    setSelectedConversationVehicleIds((prevSelectedConversationVehicleIds) =>
      prevSelectedConversationVehicleIds.filter(
        (currentConversationVehicleId) =>
          currentConversationVehicleId !== conversation.vehicleId
      )
    );

    setConversations((prevConversations) =>
      prevConversations.map((currentConversation) => {
        if (currentConversation.id === conversation.id) {
          return {
            ...currentConversation,
            isSelected: false,
          };
        }

        return currentConversation;
      })
    );
  }, []);

  const handleChangeConversationVehiclesSelection = useCallback(() => {
    if (isAllConversationVehiclesSelected) {
      handleUnselectAllConversationVehicles();
      return;
    }

    handleSelectAllConversationVehicles();
    return;
  }, [
    handleUnselectAllConversationVehicles,
    handleSelectAllConversationVehicles,
    isAllConversationVehiclesSelected,
  ]);

  const handleChangeConversationVehicleSelection = useCallback(
    (isSelected, conversation) => {
      if (isSelected) {
        handleUnselectConversationVehicle(conversation);
        return;
      }

      handleSelectConversationVehicle(conversation);
      return;
    },
    [handleUnselectConversationVehicle, handleSelectConversationVehicle]
  );

  const handleChangeFilters = (updatedFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...updatedFilters,
    }));
  };

  const handleGetCsv = (csvType) =>
    serviceConversations.getCsv(selectedConversationVehicleIds, csvType);

  useEffect(() => {
    handleUnselectAllConversationVehicles();
    setIsLoading(true);
    serviceConversations
      .get({
        ...filters,
        timePeriod: getConversationTimePeriodInHours(filters.timePeriod),
      })
      .then((conversations) => {
        const formatConversation = (conversation) => {
          const contactExtractors = {
            autotrader: () => "Autotrader Messenger",
          };
          const contact = (
            conversation.message_type === "email"
              ? contactExtractors[conversation.vehicle.source_name] ||
                  (() => "")
              : () =>
                  conversation.vehicle?.phone_number
                    ? conversation.vehicle.phone_number
                    : ""
          )();

          const sourceImgData =
            utilConstants.sources[conversation.vehicle.source_name]?.imgData ||
            {};

          const userImgData = {};
          if (conversation.user.avatar) {
            userImgData.src = conversation.user.avatar.link;
          }

          return {
            id: conversation.id,
            vehicleId: conversation.vehicle.id,
            messageType: conversation.message_type,
            contact,
            title: conversation.vehicle.heading,
            lastMessageText: conversation.text,
            media: conversation.media ? conversation.media : [],
            createdAt: conversation.created_at,
            isDeleted: conversation.vehicle.is_deleted,
            isDelisted: conversation.vehicle.is_delisted,
            imgData:
              conversation.sender === "user" ? userImgData : sourceImgData,
            isUnread: !conversation.seen_at,
            isUserSender: conversation.sender === "user",
            isSelected: false,
          };
        };

        setConversations(conversations.map(formatConversation));
      })
      .finally(() => setIsLoading(false));
  }, [filters, handleUnselectAllConversationVehicles]);

  return (
    <div className={s["my-convos"]}>
      <div className={s["my-convos__title"]}>My Convos</div>
      <ConvosToolbar
        filters={filters}
        onChangeFilters={handleChangeFilters}
        onGetCsv={handleGetCsv}
      />
      {isLoading ? (
        <></>
      ) : (
        <>
          {conversations.length ? (
            <>
              <div className={s["my-convos__table"]}>
                <div className={s["my-convos__labels"]}>
                  <div className={s["my-convos__label"]}>
                    <ConvosCheckbox
                      isChecked={isAllConversationVehiclesSelected}
                      onChange={handleChangeConversationVehiclesSelection}
                    />
                  </div>
                  <div className={s["my-convos__label"]}>Contact</div>
                  <div className={s["my-convos__label"]}>Vehicle</div>
                  <div className={s["my-convos__label"]}>Last Message</div>
                  <div
                    className={
                      s["my-convos__label"] + " " + s["my-convos__label--date"]
                    }
                  >
                    Last Message Date
                  </div>
                </div>
                {conversations.map((conversation) => (
                  <Conversation
                    conversation={conversation}
                    key={conversation.id}
                    onChangeConversationVehicleSelection={
                      handleChangeConversationVehicleSelection
                    }
                  />
                ))}
              </div>
            </>
          ) : (
            <>
              <div className={s["my-convos__empty-message-list__title"]}>
                You haven’t got any convos yet
              </div>
              <img
                className={s["my-convos__icon-autoagents"]}
                src={iconAutoAgents}
                alt=""
              />
            </>
          )}
        </>
      )}

      <img
        className={
          s["my-convos__scroll-to-top-arrow"] +
          (isOpenScrollToTop
            ? ` ${s["my-convos__scroll-to-top-arrow--open"]}`
            : "")
        }
        alt="Scroll to top"
        src={iconScrollToTop}
        onClick={handleClickScrollToTop}
      />

      <Loader isOpen={isLoading} />
    </div>
  );
};

export default MyConvos;
