import { useState, createRef, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import backgroundBlocker from "utils/backgroundBlocker";
import s from "./index.module.scss";

const ModalComponent = ({
  className = "",
  isOpening = false,
  isClosing = false,
  onClose = () => {},
  children,
}) => {
  const modalRef = createRef();

  const handleClickModalComponent = (e) => {
    if (isOpening || isClosing) {
      e.preventDefault();
    }
    if (!modalRef?.current || modalRef?.current?.contains(e.target)) {
      return;
    }
    onClose(true);
  };

  useEffect(() => {
    const handleKeyDownDocument = ({ code }) => {
      if (code !== "Escape") {
        return;
      }
      onClose(true);
    };

    document.addEventListener("keydown", handleKeyDownDocument);

    return () => document.removeEventListener("keydown", handleKeyDownDocument);
  });

  return (
    <div
      className={
        s["modal-component"] +
        (className ? ` ${className}` : "") +
        (isOpening ? ` ${s["modal-component--open"]}` : "") +
        (isClosing ? ` ${s["modal-component--close"]}` : "")
      }
      onClick={handleClickModalComponent}
    >
      <div
        ref={modalRef}
        className={
          s["modal-component__modal-wrapper"] +
          (isOpening ? ` ${s["modal-component__modal-wrapper--open"]}` : "") +
          (isClosing ? ` ${s["modal-component__modal-wrapper--close"]}` : "")
        }
      >
        {children}
      </div>
    </div>
  );
};

const Modal = ({
  className = "",
  isOpen = false,
  onClose = () => {},
  children,
}) => {
  const [isOpenStack, setIsOpenStack] = useState([]);
  const [isOpening, setIsOpening] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const isMounted = useRef(false);

  const animationDuration = parseInt(s["animation-duration"]);
  const isAnimationInProgress = isOpening || isClosing;

  useEffect(() => setIsOpenStack((i) => [...i, isOpen]), [isOpen]);

  useEffect(() => {
    if (!isMounted.current) {
      setTimeout(() => (isMounted.current = true), animationDuration);
      return;
    }
    if (isAnimationInProgress) {
      return;
    }
    if (isOpenStack.length === 1) {
      return;
    }
    if (!isOpenStack[0]) {
      setIsOpening(true);
      setTimeout(() => setIsOpening(false), animationDuration);
    } else {
      setIsClosing(true);
      setTimeout(() => setIsClosing(false), animationDuration);
    }
    setIsOpenStack(isOpenStack.slice(1));
  }, [isAnimationInProgress, isOpenStack, animationDuration]);

  useEffect(() => {
    if (isOpen) {
      backgroundBlocker.block();
      return;
    }
    backgroundBlocker.unblock();
  }, [isOpen]);

  if (isOpenStack[0] || isAnimationInProgress) {
    return createPortal(
      <ModalComponent
        className={className}
        isOpening={isOpening}
        isClosing={isClosing}
        onClose={onClose}
      >
        {children}
      </ModalComponent>,
      document.querySelector("#modal-root")
    );
  }

  return <></>;
};

export default Modal;
