import { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteWatchListModal from "components/modals/DeleteWatchListModal";
import RenameWatchListModal from "components/modals/RenameWatchListModal";
import FilledButton from "components/buttons/FilledButton";
import iconTrash from "assets/icons/trash-blue.svg";
import iconRename from "assets/icons/pen.svg";
import iconCopyWatchLists from "assets/icons/copy-blue.svg";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const WatchListsDetailsTitle = ({
  isAbleToManageWatchList = false,
  isAbleToManageAutoConvos = false,
  onClickStartAutoConvos = () => {},
  onClickStopAutoConvos = () => {},
  isStartAutoConvosButtonDisabled = false,
  isStopAutoConvosButtonsDisabled = false,
}) => {
  const [title, setTitle] = useState("");
  const [clientName, setClientName] = useState("");
  const [isOpenModalRemove, setIsOpenModalRemove] = useState(false);
  const [isOpenModalRename, setIsOpenModalRename] = useState(false);

  const { watchListId } = useParams();

  useEffect(
    () =>
      serviceWatchLists.get(watchListId).then((watchList) => {
        setTitle(watchList.title);
        setClientName(watchList.client_name);
      }),
    [watchListId]
  );

  const history = useHistory();

  const handleCloseModalRename = (title, clientName) => {
    if (title && clientName) {
      setTitle(title);
      setClientName(clientName);
    }
    setIsOpenModalRename(false);
  };

  const handleCloseModalRemove = (isDeleted = false) => {
    setIsOpenModalRemove(false);
    if (isDeleted) {
      history.push("/employee/watch-lists");
    }
  };

  const copyLink = () => {
    const currentLink = window.location.href;
    navigator.clipboard.writeText(currentLink.replace(/employee/, "customer"));
    toast.success("Link successfully copied!", {
      position: "bottom-right",
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <div className={s["watch-lists-details"]}>
      <div
        className={
          s["watch-lists-details__text-wrapper"] +
          " " +
          (!isAbleToManageWatchList
            ? s["watch-lists-details__text-wrapper--vehicle"]
            : "")
        }
      >
        <Link
          className={
            s["watch-lists-details__text"] +
            " " +
            (!isAbleToManageWatchList
              ? s["watch-lists-details__link--cursor"] +
                " " +
                s["watch-lists-details__text--vehicle"]
              : s["watch-lists-details__link"])
          }
          to={`/employee/watch-lists/${watchListId}`}
        >
          <span>Watchlist</span>
          <span className={s["watch-lists-details__text--bold"]}>
            {` ${title}`}
          </span>
          <span>{" for"}</span>
          <span className={s["watch-lists-details__text--bold"]}>
            {` ${clientName}`}
          </span>
        </Link>
        {isAbleToManageAutoConvos && (
          <div className={s["watch-lists-details__buttons"]}>
            <FilledButton
              className={
                s["watch-lists-details__button"] +
                " " +
                s["watch-lists-details__button--start-autoconvo"]
              }
              onClick={onClickStartAutoConvos}
              disabled={isStartAutoConvosButtonDisabled}
            >
              Start auto convos
            </FilledButton>
            <FilledButton
              className={
                s["watch-lists-details__button"] +
                " " +
                s["watch-lists-details__button--stop-autoconvo"]
              }
              onClick={onClickStopAutoConvos}
              disabled={isStopAutoConvosButtonsDisabled}
            >
              Stop auto convos
            </FilledButton>
          </div>
        )}
        {isAbleToManageWatchList && (
          <div className={s["watch-lists-details__icons"]}>
            <img
              className={s["watch-lists-details__rename-icon"]}
              src={iconRename}
              alt="Rename"
              onClick={() => setIsOpenModalRename(true)}
            />
            <img
              className={s["watch-lists-details__trash-icon"]}
              src={iconTrash}
              alt="Remove"
              onClick={() => setIsOpenModalRemove(true)}
            />
            <img
              className={s["watch-lists-details__copy-icon"]}
              src={iconCopyWatchLists}
              alt="Copy watchlists link"
              onClick={copyLink}
            />
          </div>
        )}
      </div>
      <RenameWatchListModal
        isOpen={isOpenModalRename}
        onClose={handleCloseModalRename}
        title={title}
        clientName={clientName}
        id={watchListId}
      />
      <DeleteWatchListModal
        isOpen={isOpenModalRemove}
        onClose={handleCloseModalRemove}
        text={`${title} ${clientName}`}
        id={watchListId}
      />
    </div>
  );
};

export default WatchListsDetailsTitle;
