import Modal from "components/Modal";
import FilledButton from "components/buttons/FilledButton";
import iconCrossBlack from "assets/icons/cross-black.svg";
import s from "./index.module.scss";

const DiscardPrevConvoWarningModal = ({
  isOpen = false,
  onClose = () => {},
}) => {
  const handleClose = (isAgreed = false) => {
    onClose(isAgreed);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose(false)}>
      <div className={s["discard-prev-convo-warning-modal"]}>
        <img
          className={s["discard-prev-convo-warning-modal__cross"]}
          src={iconCrossBlack}
          alt="Close"
          onClick={() => handleClose(false)}
        />
        <p className={s["discard-prev-convo-warning-modal__text"]}>
          Sending this message will start a new convo with the seller.
          <br />
          Are you sure?
        </p>
        <FilledButton
          className={s["discard-prev-convo-warning-modal__button"]}
          onClick={() => handleClose(true)}
        >
          Yes, I am sure
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DiscardPrevConvoWarningModal;
