import { useState, useEffect, useMemo } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MainInfo from "components/VehicleInfo/MainInfo";
import VehicleGallery from "components/VehicleGallery";
import VehicleInfo from "components/VehicleInfo";
import Loader from "components/Loader";
import AddToWatchListModals from "components/AddToWatchListModals";
import DeleteVehicleFromDatabaseModal from "components/modals/DeleteVehicleFromDatabaseModal";
import serviceVehicles from "services/vehicles";
import { setLastAccessedVehicleId } from "slicers/vehicles";
import s from "./index.module.scss";

const VehicleDetails = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [vehicle, setVehicle] = useState({});
  const [isOpenAddToWatchListModals, setIsOpenAddToWatchListModals] =
    useState(false);

  const isActivitiesEvents = useSelector((state) => state.activities.hasEvents);

  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    setVehicle({});
    setIsLoading(true);
    serviceVehicles
      .get(id, params.get("postal_code"))
      .then((data) => setVehicle(data))
      .finally(() => setIsLoading(false));
  }, [id, params]);

  const handleClickOpenModal = () => setIsOpenModal(true);

  const handleCloseModal = (isDeleted = false) => {
    setIsOpenModal(false);
    if (isDeleted) {
      dispatch(setLastAccessedVehicleId(""));
      history.push("/employee/vehicles", { refreshRequired: true });
    }
  };

  return (
    <div className={s["vehicle-details"]}>
      <div className={s["vehicle-details__main-info"]}>
        <MainInfo
          vehicle={vehicle}
          isVehicleLoading={isLoading}
          onAddToWatchList={() => setIsOpenAddToWatchListModals(true)}
          handleClickOpenModal={handleClickOpenModal}
        />
      </div>
      <div className={s["vehicle-details__vehicle-gallery"]}>
        <VehicleGallery
          photoLinks={vehicle.media}
          workInProgress={isActivitiesEvents}
        />
      </div>
      <div className={s["vehicle-details__vehicle-info"]}>
        <VehicleInfo
          vehicle={vehicle}
          onVehicleEdited={(value) => setVehicle(value)}
        />
      </div>
      <Loader isOpen={isLoading} />
      <AddToWatchListModals
        isOpen={isOpenAddToWatchListModals}
        id={vehicle.id}
        onClose={() => setIsOpenAddToWatchListModals(false)}
      />
      <DeleteVehicleFromDatabaseModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        text={`${vehicle.build?.make ? vehicle.build.make : ""} ${
          vehicle.build?.model ? ` ${vehicle.build.model}` : ""
        } ${vehicle.build?.trim ? ` ${vehicle.build.trim}` : ""}`}
        vehicleId={id}
      />
    </div>
  );
};

export default VehicleDetails;
