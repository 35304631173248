import store from "store";

const getValueFromTokenPayload = (token, key) =>
  JSON.parse(atob(token.split(".")[1]))[key];

const getUserId = (state) => {
  if (state) {
    return getValueFromTokenPayload(state.auth.access, "user_id");
  }

  const { getState } = store;
  return getValueFromTokenPayload(getState().auth.access, "user_id");
};

const authRepository = {
  getUserId,
};

export default authRepository;
