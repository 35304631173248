import store from "store";
import {
  setIsGridView,
  setLastAccessedVehicleId,
  setPage,
  setPageCount,
  setIsLoading,
  setVehicles,
  setVehicleFields,
  resetVehiclesAndPaginationState,
  resetHotlistState,
} from "slicers/hotlist";

const getIsGridView = (state) => {
  if (state) {
    return state.hotlist.isGridView;
  }

  const { getState } = store;
  return getState().hotlist.isGridView;
};

const getLastAccessedVehicleId = (state) => {
  if (state) {
    return state.hotlist.lastAccessedVehicleId;
  }

  const { getState } = store;
  return getState().hotlist.lastAccessedVehicleId;
};

const getVehicles = (state) => {
  if (state) {
    return state.hotlist.vehicles;
  }

  const { getState } = store;
  return getState().hotlist.vehicles;
};

const getPageNumber = (state) => {
  if (state) {
    return state.hotlist.page;
  }

  const { getState } = store;
  return getState().hotlist.page;
};

const getPageCount = (state) => {
  if (state) {
    return state.hotlist.pageCount;
  }

  const { getState } = store;
  return getState().hotlist.pageCount;
};

const getPageSize = (state) => {
  if (state) {
    return state.hotlist.pageSize;
  }

  const { getState } = store;
  return getState().hotlist.pageSize;
};

const getLoadingState = (state) => {
  if (state) {
    return state.hotlist.isLoading;
  }

  const { getState } = store;
  return getState().hotlist.isLoading;
};

const getZipCode = (state) => "";

const updateIsGridView = (isGridView) =>
  store.dispatch(setIsGridView(isGridView));

const updateLastAccessedVehicleId = (lastAccessedVehicleId) =>
  store.dispatch(setLastAccessedVehicleId(lastAccessedVehicleId));

const updatePageNumber = (pageNumber) => store.dispatch(setPage(pageNumber));

const updatePageCount = (pageCount) => store.dispatch(setPageCount(pageCount));

const updateLoadingState = (isLoading) =>
  store.dispatch(setIsLoading(isLoading));

const updateVehicles = (vehicles) => store.dispatch(setVehicles(vehicles));

const updateVehicleFields = (id, updatedFields) =>
  store.dispatch(setVehicleFields({ id, updatedFields }));

const appendVehicles = (vehicles) =>
  store.dispatch(setVehicles([...getVehicles(), ...vehicles]));

const resetVehicles = () => store.dispatch(resetVehiclesAndPaginationState());

const resetState = () => store.dispatch(resetHotlistState());

const hotlistVehicleRepository = {
  getIsGridView,
  getLastAccessedVehicleId,
  getVehicles,
  getPageNumber,
  getPageCount,
  getPageSize,
  getLoadingState,
  getZipCode,
  updateIsGridView,
  updateLastAccessedVehicleId,
  updatePageNumber,
  updatePageCount,
  updateLoadingState,
  updateVehicles,
  updateVehicleFields,
  appendVehicles,
  resetVehicles,
  resetState,
};

export default hotlistVehicleRepository;
