import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useInput } from "hooks/input.hook";
import { toast } from "react-toastify";
import CustomerHeader from "components/headers/CustomerHeader";
import AvatarUploader from "components/AvatarUploader";
import SimpleInput from "components/inputs/SimpleInput";
import Input from "components/inputs/Input";
import FilledButton from "components/buttons/FilledButton";
import UnfilledButton from "components/buttons/UnfilledButton";
import PasswordInput from "components/inputs/PasswordInput";
import UploadPhotoButton from "components/buttons/UploadPhotoButton";
import Loader from "components/Loader";
import utilFormatter from "utils/formatter";
import utilValidator from "utils/validator";
import constantText from "utils/constants";
import serviceUsers from "services/users";
import "react-toastify/dist/ReactToastify.css";
import s from "./index.module.scss";

const CustomerProfile = () => {
  const firstNameController = useInput("");
  const lastNameController = useInput("");
  const emailController = useInput("");
  const phoneNumberController = useInput("");
  const zipCodeController = useInput("");
  const defaultPasswordController = useInput("");
  const newPasswordController = useInput("");
  const confirmPasswordController = useInput("");
  const avatarController = useInput("");

  const [defaultValue, setDefaultValue] = useState(() => () => {});

  const customerId = useSelector((state) => state.user.id);

  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const handleChangeZipCode = (value) => {
    zipCodeController.setValue(utilFormatter.prettyZip(value));
  };

  const reset = () => {
    firstNameController.setValue(firstNameController.value);
    lastNameController.setValue(lastNameController.value);
    zipCodeController.setValue(zipCodeController.value);
    emailController.setValue(emailController.value);
    phoneNumberController.setValue(phoneNumberController.value);
    avatarController.setValue(avatarController.value);
    defaultPasswordController.setValue("");
    newPasswordController.setValue("");
    confirmPasswordController.setValue("");
  };

  const isSaveButtonDisabled =
    !firstNameController.value ||
    !lastNameController.value ||
    !emailController.value ||
    !zipCodeController.value ||
    isLoading;

  const resetErrorMessages = () => {
    emailController.setErrorText("");
    defaultPasswordController.setErrorText("");
    newPasswordController.setErrorText("");
    confirmPasswordController.setErrorText("");
    zipCodeController.setErrorText("");
  };

  const setErrorText = {
    zip_code: zipCodeController.setErrorText,
    email: emailController.setErrorText,
    old_password: defaultPasswordController.setErrorText,
  };

  const errorHandler = (data) => {
    const errorKeys = Object.keys(data);
    for (const key of errorKeys) {
      data[key].forEach((value) => {
        setErrorText[key](value.message);
      });
    }
  };

  const validatePasswordFields = () => {
    const defaultPasswordErrorMessage =
      utilValidator.validatePasswordAndGetError(
        defaultPasswordController.value
      );

    const newPasswordErrorMessage = utilValidator.validatePasswordAndGetError(
      newPasswordController.value
    );

    const passwordMatchErrorMessage =
      utilValidator.validatePasswordConfirmationAndGetError(
        newPasswordController.value,
        confirmPasswordController.value
      );

    let passwordFieldsValid = true;

    if (defaultPasswordErrorMessage) {
      defaultPasswordController.setErrorText(defaultPasswordErrorMessage);
      passwordFieldsValid = false;
    }

    if (newPasswordErrorMessage) {
      newPasswordController.setErrorText(newPasswordErrorMessage);
      passwordFieldsValid = false;
    }

    if (passwordMatchErrorMessage) {
      confirmPasswordController.setErrorText(passwordMatchErrorMessage);
      passwordFieldsValid = false;
    }

    return passwordFieldsValid;
  };

  const resetDefaultState = (
    firstName,
    lastName,
    zipCode,
    email,
    phoneNumber,
    avatar
  ) => {
    const defaultFirstName = firstName;
    const defaultLastName = lastName;
    const defaultZipCode = zipCode;
    const defaultEmail = email;
    const defaultPhoneNumber = phoneNumber;
    const defaultAvatar = avatar;

    return () => {
      history.push("/customer/vehicles");
      firstNameController.setValue(defaultFirstName);
      lastNameController.setValue(defaultLastName);
      zipCodeController.setValue(defaultZipCode);
      emailController.setValue(defaultEmail);
      phoneNumberController.setValue(defaultPhoneNumber);
      avatarController.setValue(defaultAvatar);
    };
  };

  useEffect(() => {
    serviceUsers
      .getMe()
      .then((data) => {
        firstNameController.setValue(data.profile.first_name);
        lastNameController.setValue(data.profile.last_name);
        zipCodeController.setValue(data.profile.zip_code);
        emailController.setValue(data.email);
        phoneNumberController.setValue(data.profile.phone_number);
        avatarController.setValue(data.profile.avatar);

        const default_value = resetDefaultState(
          data.profile.first_name,
          data.profile.last_name,
          data.profile.zip_code,
          data.email,
          data.profile.phone_number,
          data.profile.avatar
        );

        setDefaultValue(() => default_value);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleSaveProfile = async () => {
    resetErrorMessages();

    emailController.setValue(emailController.value.trim());

    const emailErrorMessage = utilValidator.validateEmailAndGetError(
      emailController.value
    );

    if (emailErrorMessage) emailController.setErrorText(emailErrorMessage);

    const zipCodeErrorMessage = utilValidator.validateZipCodeAndGetError(
      zipCodeController.value
    );

    if (zipCodeErrorMessage)
      zipCodeController.setErrorText(zipCodeErrorMessage);

    const errorsMsg = [emailErrorMessage, zipCodeErrorMessage].filter(
      (value) => !!value
    );

    if (defaultPasswordController.value) {
      if (!validatePasswordFields()) return;
    }

    if (errorsMsg.length !== 0) {
      return;
    }

    setIsLoading(true);

    await serviceUsers
      .updateCustomer(
        emailController.value,
        newPasswordController.value,
        defaultPasswordController.value,
        firstNameController.value,
        lastNameController.value,
        zipCodeController.value,
        avatarController.value,
        customerId,
        phoneNumberController.value
      )
      .then(() => {
        reset();
        toast.success(constantText.successMessages.editProfile, {
          position: "bottom-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        serviceUsers.getMe();
      })
      .catch((error) => {
        errorHandler(error.response.data);
      })

      .finally(() => setIsLoading(false));
  };
  return (
    <>
      <CustomerHeader />
      <div className={s["customer-profile"]}>
        <div className={s["customer-profile__details-wrapper"]}>
          <div className={s["customer-profile__avatar-wrapper"]}>
            <AvatarUploader
              className={s["customer-profile__avatar"]}
              avatar={avatarController.value}
              onChange={(avatar) => avatarController.setValue(avatar)}
            />
            <UploadPhotoButton
              onClick={(avatar) => avatarController.setValue(avatar)}
            />
          </div>
          <SimpleInput
            value={firstNameController.value}
            placeholder="First name"
            onChange={(e) => firstNameController.setValue(e.target.value)}
          />
          <SimpleInput
            value={lastNameController.value}
            placeholder="Last name"
            onChange={(e) => lastNameController.setValue(e.target.value)}
          />
          <div className={s["customer-profile__input-wrapper"]}>
            <Input
              className={`${s["customer-profile__zip-code"]}
            ${
              zipCodeController.errorText
                ? s["customer-profile__zip-code--error"]
                : ""
            }
            `}
              value={zipCodeController.value}
              spliter=" "
              chunkSize={3}
              placeholder="Postal code"
              onChange={(e) => {
                zipCodeController.setErrorText("");
                handleChangeZipCode(e);
              }}
            />
            <p className={s["customer-profile__input--error"]}>
              {zipCodeController.errorText}
            </p>
          </div>
          <SimpleInput
            value={emailController.value}
            placeholder="Email"
            error={emailController.errorText}
            onChange={(e) => {
              emailController.setErrorText("");
              emailController.setValue(e.target.value);
            }}
          />
          <SimpleInput
            value={phoneNumberController.value}
            placeholder="Phone number"
            onChange={(e) => phoneNumberController.setValue(e.target.value)}
          />
          <div className={s["customer-profile__input-wrapper"]}>
            <PasswordInput
              value={defaultPasswordController.value}
              className={`${s["customer-profile__password"]}
            ${
              defaultPasswordController.errorText
                ? s["customer-profile__password--error"]
                : ""
            }
            `}
              placeholder="Current password"
              onChange={(e) => {
                defaultPasswordController.setErrorText("");
                defaultPasswordController.setValue(e);
              }}
            />
            <p className={s["customer-profile__input--error"]}>
              {defaultPasswordController.errorText}
            </p>
          </div>
          <div className={s["customer-profile__input-wrapper"]}>
            <PasswordInput
              value={newPasswordController.value}
              className={`${s["customer-profile__password"]}
            ${
              newPasswordController.errorText
                ? s["customer-profile__password--error"]
                : ""
            }
            `}
              placeholder="New password"
              onChange={(e) => {
                newPasswordController.setErrorText("");
                newPasswordController.setValue(e);
              }}
            />
            <p className={s["customer-profile__input--error"]}>
              {newPasswordController.errorText}
            </p>
          </div>
          <div className={s["customer-profile__input-wrapper"]}>
            <PasswordInput
              value={confirmPasswordController.value}
              className={`${s["customer-profile__password"]}
            ${
              confirmPasswordController.errorText
                ? s["customer-profile__password--error"]
                : ""
            }
            `}
              placeholder="Confirm new password"
              onChange={(e) => {
                confirmPasswordController.setErrorText("");
                confirmPasswordController.setValue(e);
              }}
            />
            <p className={s["customer-profile__input--error"]}>
              {confirmPasswordController.errorText}
            </p>
          </div>
          <div className={s["customer-profile__buttons-wrapper"]}>
            <UnfilledButton
              className={s["customer-profile__button"]}
              onClick={() => defaultValue()}
            >
              Cancel
            </UnfilledButton>
            <FilledButton
              className={s["customer-profile__button"]}
              disabled={isSaveButtonDisabled}
              onClick={handleSaveProfile}
            >
              Save
            </FilledButton>
          </div>
        </div>
        <Loader isOpen={isLoading} />
      </div>
    </>
  );
};

export default CustomerProfile;
