import { useState } from "react";
import Input from "components/inputs/Input";
import serviceVehicles from "services/vehicles";

const VehicleListTablePriceInput = ({
  vehicleId = "",
  className = "",
  placeholder = "000 000",
  price = "",
  priceType = "",
  onPriceChange = () => {},
  disabled = false,
}) => {
  const [value, setValue] = useState(price ? price.toString() : "");
  const [localValue, setLocalValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);

  const isPriceInvalid =
    (localValue === "" && value === "") ||
    (localValue.replace(/^0+/, "").length === 0 && localValue.length > 0);

  const handleChange = (value) => {
    setLocalValue((currentStateValue) => {
      if (!currentStateValue) {
        return value.replace(/\D+/g, "").replace(/^0+/, "");
      }
      return value.replace(/\D+/g, "");
    });
    return;
  };

  const handleSavePrice = () => {
    if (isLoading) return;

    if (isPriceInvalid) {
      setLocalValue(value);
      return;
    }

    const localPrice = localValue ? parseInt(localValue) : null;
    const currentPrice = value ? parseInt(value) : null;

    if (localPrice === currentPrice) {
      setLocalValue(value);
      return;
    }

    setIsLoading(true);

    serviceVehicles
      .updateVehicle(vehicleId, { [priceType]: localPrice })
      .then((vehicle) => {
        const inputValuePrice = vehicle[priceType]
          ? vehicle[priceType].toString()
          : "";
        setValue(inputValuePrice);
        setLocalValue(inputValuePrice);
        onPriceChange(vehicleId, { [priceType]: vehicle[priceType] });
      })
      .catch(() => {
        setLocalValue(value);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Input
      className={className}
      placeholder={placeholder}
      value={localValue}
      spliter={" "}
      chunkSize={3}
      onChange={handleChange}
      onBlur={handleSavePrice}
      disabled={disabled}
    />
  );
};
export default VehicleListTablePriceInput;
