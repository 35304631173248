import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Message from "./Message";
import DiscardPrevConvoWarningModal from "components/modals/DiscardPrevConvoWarningModal";
import ConvoImagesModal from "components/modals/ConvoImagesModal";
import serviceVehicles from "services/vehicles";
import iconSend from "../../assets/icons/send.svg";
import iconDisabledSend from "../../assets/icons/disabled-send.svg";
import iconAutoAgents from "../../assets/images/logo-gray.svg";
import {
  setVehicleMessages,
  clearVehicleMessages,
} from "slicers/conversations";
import { setIsLoading } from "slicers/conversation";
import s from "./index.module.scss";

const Convo = ({
  vehicleId = "",
  delisted = false,
  isConvoViaPhoneNumber = true,
  isAutoConvo = false,
}) => {
  const isLoading = useSelector((state) => state.conversation.isLoading);
  const sellerPhoneNumber = useSelector(
    (state) => state.conversation.seller.phoneNumber
  );

  const [currentMessage, setCurrentMessage] = useState("");
  const [currentCaretPositionStart, setCurrentCaretPositionStart] =
    useState(null);
  const [messages, setMessages] = useState([]);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [isOpenConvoImagesModal, setIsOpenConvoImagesModal] = useState(false);
  const [convoImages, setConvoImages] = useState([]);

  const textareaRef = useRef();
  const messageListWrapperRef = useRef();

  const dispatch = useDispatch();

  const messageType = isConvoViaPhoneNumber ? "sms" : "email";

  const currentConversationMessages = useRef({ messageType, messages });

  const isActiveSendMessage =
    !isLoading &&
    !!currentMessage.replaceAll(/[ \n\r]/g, "").length &&
    !isOpenWarningModal;

  const isDisableSendingMessage =
    (delisted && !isConvoViaPhoneNumber) || isAutoConvo;

  const resize = () => {
    const textarea = textareaRef.current;

    if (!textarea) {
      return;
    }

    textarea.style.height = `max-content`;
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(resize, [currentMessage]);

  useLayoutEffect(() => {
    if (!textareaRef.current) {
      return;
    }
    if (!currentCaretPositionStart) {
      return;
    }
    textareaRef.current.selectionStart = textareaRef.current.selectionEnd =
      currentCaretPositionStart + 1;
    setCurrentCaretPositionStart(null);
  }, [currentCaretPositionStart]);

  useEffect(() => {
    if (!currentConversationMessages.current) {
      return;
    }

    if (!messages) {
      return;
    }

    const messageListWrapper = messageListWrapperRef.current;
    if (!messageListWrapper) {
      return;
    }

    if (
      currentConversationMessages.current.messages.length === messages.length &&
      currentConversationMessages.current.messageType === messageType
    ) {
      currentConversationMessages.current = { messageType, messages };
      return;
    }

    currentConversationMessages.current = { messageType, messages };
    messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
  }, [messages, messageType]);

  const formatMessage = (message) => {
    return {
      id: message.id,
      createdAt: message.created_at,
      sender: {
        type: message.sender,
      },
      media: message.media ? message.media : [],
      text: message.text,
    };
  };

  useEffect(() => {
    dispatch(setIsLoading(true));
    const fetchMessages = async () =>
      serviceVehicles.getMessages(vehicleId, messageType).then((messages) => {
        messages = messages.map(formatMessage);
        setMessages(messages);
        dispatch(setVehicleMessages(messages));
      });

    fetchMessages().finally(() => dispatch(setIsLoading(false)));

    const interval = setInterval(fetchMessages, 1000 * 60);

    return () => {
      dispatch(clearVehicleMessages());
      clearInterval(interval);
    };
  }, [vehicleId, isConvoViaPhoneNumber, sellerPhoneNumber]);

  const handleChangeCurrentMessage = (e) => setCurrentMessage(e.target.value);

  const sendMessage = (discardPrev = false) => {
    dispatch(setIsLoading(true));
    let newMessages = [...messages];
    if (discardPrev) {
      newMessages = [];
    }

    serviceVehicles
      .sendMessage(vehicleId, currentMessage, messageType)
      .then((message) => {
        setMessages([...newMessages, formatMessage(message)]);
        setCurrentMessage("");
      })
      .finally(() => dispatch(setIsLoading(false)));
  };

  const handleSendMessage = () => {
    if (!isActiveSendMessage) {
      return;
    }
    if (messages.length == 1 && messageType === "email") {
      setIsOpenWarningModal(true);
      return;
    }
    sendMessage();
  };

  const handleCloseWarningModal = (isAgreed) => {
    setIsOpenWarningModal(false);

    if (!isAgreed) {
      return;
    }

    sendMessage(true);
  };

  const handleTextareaKeyDown = (e) => {
    const isEnter = e.code === "Enter";
    const isSendHelperKey = e.shiftKey || e.ctrlKey || e.metaKey;
    if (isEnter && isSendHelperKey) {
      e.preventDefault();
      handleSendMessage();
      return;
    }
  };

  return (
    <div className={s["convo"]}>
      <div
        className={s["convo__message-list-wrapper"]}
        ref={messageListWrapperRef}
      >
        {messages.length ? (
          <div className={s["convo__message-list"]}>
            {messages.map((message) => (
              <Message
                message={message}
                key={message.id}
                setConvoImages={setConvoImages}
                setIsOpenConvoImagesModal={setIsOpenConvoImagesModal}
              />
            ))}
          </div>
        ) : (
          <>
            {!isLoading && (
              <div className={s["convo__empty-message-list-wrapper"]}>
                <div className={s["convo__empty-message-list"]}>
                  {isDisableSendingMessage ? (
                    <></>
                  ) : (
                    <p className={s["convo__empty-message-list__title"]}>
                      {isConvoViaPhoneNumber
                        ? "Start New Convo via SMS"
                        : "Start New Convo via Email"}
                    </p>
                  )}
                  <img
                    className={s["convo__icon-autoagents"]}
                    src={iconAutoAgents}
                    alt=""
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {isDisableSendingMessage ? (
        <></>
      ) : (
        <div className={s["convo__message"]}>
          <textarea
            className={s["message__textarea"]}
            placeholder={
              isConvoViaPhoneNumber
                ? "Message seller via SMS"
                : "Message seller via Email"
            }
            value={currentMessage}
            onChange={handleChangeCurrentMessage}
            rows="1"
            ref={textareaRef}
            onKeyDown={handleTextareaKeyDown}
          ></textarea>
          {isActiveSendMessage ? (
            <img
              className={s["message__send"]}
              src={iconSend}
              onClick={handleSendMessage}
              alt="send"
            />
          ) : (
            <img
              className={
                s["message__send"] + " " + s["message__send--disabled"]
              }
              src={iconDisabledSend}
              alt="unable to send"
            />
          )}
        </div>
      )}
      <DiscardPrevConvoWarningModal
        isOpen={isOpenWarningModal}
        onClose={handleCloseWarningModal}
      />
      <ConvoImagesModal
        isOpen={isOpenConvoImagesModal}
        onClose={() => setIsOpenConvoImagesModal(false)}
        images={convoImages}
      />
    </div>
  );
};

export default Convo;
