import { useState, useEffect, useCallback, useRef, useMemo, memo } from "react";
import Select from "react-select";
import Input from "components/inputs/Input";
import RangeInput from "components/inputs/RangeInput";
import ExpandIndicator from "components/ExpandIndicator";
import NumberInput from "components/inputs/NumberInput";
import Checkbox from "components/inputs/Checkbox";
import ColoredCheckbox from "components/inputs/ColoredCheckbox";
import FilledButton from "components/buttons/FilledButton";
import iconSearchWhite from "assets/icons/search-white.svg";
import iconDropdown from "assets/icons/dropdown.svg";
import iconReset from "assets/icons/reset.svg";
import iconClosedVehiclesFilters from "assets/icons/closed-vehicles-filters.svg";
import iconOpenedVehiclesFilters from "assets/icons/opened-vehicles-filters.svg";
import utilConstants from "utils/constants";
import utilFormatter from "utils/formatter";
import utilValidator from "utils/validator";
import backgroundBlocker from "utils/backgroundBlocker";
import s from "./index.module.scss";
import Switch from "components/Switch";

const MemoizedSelect = memo((props) => {
  return <Select {...props} />;
});

const VehiclesFilters = ({
  isLoading = false,
  defaultZipCode = "",
  defaultFilters = {},
  defaultSearchText = "",
  defaultSorting = {},
  stats = [],
  isExtendingFiltering = false,
  ableToChangeVehiclesType = true,
  onResetFilters = () => {},
  onApplyFilters = () => {},
}) => {
  const [localZipCode, setLocalZipCode] = useState(defaultZipCode);
  const [localDistanceRange, setLocalDistanceRange] = useState(
    defaultFilters.distanceRange
  );
  const [localSearchText, setLocalSearchText] = useState(defaultSearchText);
  const [localSorting, setLocalSorting] = useState(defaultSorting);
  const [localTransmissionType, setLocalTransmissionType] = useState(
    defaultFilters.transmissionType
  );
  const [localFuelType, setLocalFuelType] = useState(defaultFilters.fuelType);
  const [localProgressState, setLocalProgressState] = useState(
    defaultFilters.progressState
  );
  const [isErrorLocalZipCode, setIsErrorLocalZipCode] = useState(false);
  const [nextSorting, setNextSorting] = useState(null);
  const [yearFrom, setYearFrom] = useState(defaultFilters.year.from);
  const [yearTo, setYearTo] = useState(defaultFilters.year.to);
  const [mileageFrom, setMileageFrom] = useState(defaultFilters.mileage.from);
  const [mileageTo, setMileageTo] = useState(defaultFilters.mileage.to);
  const [priceFrom, setPriceFrom] = useState(defaultFilters.price.from);
  const [priceTo, setPriceTo] = useState(defaultFilters.price.to);
  const [isRegularSources, setIsRegularSources] = useState(
    defaultFilters.isRegularSources
  );
  const [localSources, setLocalSources] = useState(defaultFilters.sources);
  const [localRequiredValues, setLocalRequiredValues] = useState(
    defaultFilters.requiredValues
  );
  const [localIncludeValues, setLocalIncludeValues] = useState(
    defaultFilters.includeValues
  );

  const [localColors, setLocalColors] = useState(defaultFilters.colors);

  const [isOpened, setIsOpened] = useState(false);

  const zipCodeInputRef = useRef(null);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      return;
    }
    setLocalSearchText("");
  }, []);

  useEffect(() => {
    setLocalSearchText(defaultSearchText);
  }, [defaultSearchText]);

  useEffect(() => {
    setLocalSorting(defaultSorting);
  }, [defaultSorting]);

  useEffect(() => {
    setLocalZipCode(defaultZipCode);
  }, [defaultZipCode]);

  useEffect(() => {
    setLocalDistanceRange(defaultFilters.distanceRange);
    setLocalTransmissionType(defaultFilters.transmissionType);
    setLocalFuelType(defaultFilters.fuelType);
    setLocalProgressState(defaultFilters.progressState);
    setYearFrom(defaultFilters.year.from);
    setYearTo(defaultFilters.year.to);
    setMileageFrom(defaultFilters.mileage.from);
    setMileageTo(defaultFilters.mileage.to);
    setPriceFrom(defaultFilters.price.from);
    setPriceTo(defaultFilters.price.to);
    setIsRegularSources(defaultFilters.isRegularSources);
    setLocalSources(defaultFilters.sources);
    setLocalRequiredValues(defaultFilters.requiredValues);
    setLocalIncludeValues(defaultFilters.includeValues);
    setLocalColors(defaultFilters.colors);
  }, [defaultFilters]);

  const hideScroll = useCallback(() => {
    if (isOpened) {
      backgroundBlocker.block();
      return;
    }
    backgroundBlocker.unblock();
  }, [isOpened]);

  useEffect(hideScroll, [hideScroll]);

  useEffect(() => {
    const calcScroll = () => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      if (vw < 1280) {
        hideScroll();
        return;
      }
      document.body.style.overflow = "auto";
    };

    window.addEventListener("resize", calcScroll);

    return () => window.removeEventListener("resize", calcScroll);
  }, [hideScroll]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!isOpened) {
        return;
      }
      if (e.target.closest(`.${s["vehicles-filters"]}`)) {
        return;
      }
      if (e.target.className.includes("option")) {
        return;
      }
      e.preventDefault();
      setIsOpened(false);
    };

    window.addEventListener("click", handleOutsideClick);

    return () => window.removeEventListener("click", handleOutsideClick);
  }, [isOpened]);

  const handleKeyDownLocalMonoSearchText = ({ code }) => {
    if (code === "Enter") {
      applySearchAndFilters();
    }
  };

  const handleClickSearch = () => {
    applySearchAndFilters();
  };

  const handleClickApply = () => {
    applySearchAndFilters();
  };

  const applySearchAndFilters = () => {
    const zipCodeErrorMessage =
      utilValidator.validateZipCodeAndGetError(localZipCode);

    if (localZipCode && zipCodeErrorMessage) {
      setIsErrorLocalZipCode(true);
      return;
    }

    const filters = {
      transmissionType: localTransmissionType,
      fuelType: localFuelType,
      progressState: localProgressState,
      distanceRange: localDistanceRange,
      year: { from: yearFrom, to: yearTo },
      mileage: { from: mileageFrom, to: mileageTo },
      price: { from: priceFrom, to: priceTo },
      isRegularSources,
      sources: localSources,
      colors: localColors,
      requiredValues: localRequiredValues,
      includeValues: localIncludeValues,
    };

    onApplyFilters(localSearchText, localSorting, localZipCode, filters)
      .catch(() => {
        if (localZipCode.length !== 0) {
          setIsErrorLocalZipCode(true);
        }
      })
      .finally(() => {
        setIsOpened(false);
      });
  };

  const handleChangeLocalZipCode = (value) => {
    setIsErrorLocalZipCode(false);
    setLocalZipCode(utilFormatter.prettyZip(value));
  };

  const handleKeyDownLocalZipCode = ({ code }) => {
    if (code !== "Enter") {
      return;
    }
    handleBlurLocalZipCode();
    applySearchAndFilters();
  };

  const handleBlurLocalZipCode = () => {
    if (defaultZipCode === localZipCode) {
      return;
    }

    if (localZipCode.length === 0) {
      if (localSorting.field === "dist") {
        setLocalSorting(utilConstants.sortingVehiclesOptions[0].value);
      }
      handleChangeZipCode();
      return;
    }
  };

  const handleChangeZipCode = () => {
    if (nextSorting) {
      setLocalSorting(nextSorting);
    }
    setNextSorting(null);
  };

  const handleChangeSorting = useCallback(
    ({ value }) => {
      if (isLoading) {
        return;
      }
      if (
        value.field === localSorting.field &&
        value.order === localSorting.order
      ) {
        return;
      }
      if (!localZipCode && value.field === "dist") {
        zipCodeInputRef.current.focus();
        setNextSorting(value);
        return;
      }
      setLocalSorting(value);
    },
    [isLoading, localSorting, localZipCode]
  );

  const handleChangeTransmission = useCallback(
    ({ value }) => {
      if (isLoading) {
        return;
      }

      setLocalTransmissionType(value);
    },
    [isLoading]
  );

  const handleChangeFuelType = useCallback(
    ({ value }) => {
      if (isLoading) {
        return;
      }
      setLocalFuelType(value);
    },
    [isLoading]
  );

  const handleChangeProgressState = useCallback(
    ({ value }) => {
      if (isLoading) {
        return;
      }
      setLocalProgressState(value);
    },
    [isLoading]
  );

  const selectStyles = useMemo(() => {
    return {
      control: (provided) => ({
        ...provided,
        border: "unset",
        borderBottom: "1px solid #1c1c1c !important",
        borderRadius: "unset",
        boxShadow: "unset",
        cursor: "pointer",
        width: "100%",
        height: 19,
        minHeight: "unset",
        display: "grid",
        gridTemplateColumns: "1fr 12px",
        gridColumnGap: 5,
        padding: 0,
        backgroundColor: "transparent",
      }),

      valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        fontSize: 14,
        display: "grid",
        gridTemplateTolumns: "1fr 0",
        alignItems: "center",
      }),

      singleValue: (provided) => ({
        ...provided,
        overflow: "unset",
        fontSize: 14,
        margin: 0,
      }),

      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),

      menu: (provided) => ({
        ...provided,
        margin: 0,
        boxShadow: "unset",
        borderRadius: 0,
      }),

      menuList: (provided) => ({
        ...provided,
        padding: 0,
      }),

      option: (provided) => ({
        ...provided,
        height: 36,
        fontSize: 14,
        padding: 0,
        display: "grid",
        alignContent: "center",
        color: "inherit",
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#cfdeff",
        },
      }),

      input: (provided) => ({
        ...provided,
        margin: 0,
      }),
    };
  }, []);

  const selectComponents = useMemo(() => {
    return {
      DropdownIndicator: ({ selectProps: { menuIsOpen } }) => (
        <ExpandIndicator
          className={s["vehicles-filters__dropdown-indicator"]}
          src={iconDropdown}
          isOpen={menuIsOpen}
        />
      ),
    };
  }, []);

  const recalculateYearFrom = useCallback(
    (yearTo) => (yearFrom > yearTo ? yearTo : yearFrom),
    [yearFrom]
  );

  const recalculateYearTo = useCallback(
    (yearFrom) => (yearFrom > yearTo ? yearFrom : yearTo),
    [yearTo]
  );

  const recalculateMileageFrom = (mileageTo) =>
    mileageFrom > mileageTo ? "" : mileageFrom;

  const recalculateMileageTo = (mileageFrom) =>
    mileageFrom > mileageTo ? "" : mileageTo;

  const recalculatePriceFrom = (priceTo) =>
    priceFrom > priceTo ? "" : priceFrom;

  const recalculatePriceTo = (priceFrom) =>
    priceFrom > priceTo ? "" : priceTo;

  const handleChangeYearFrom = useCallback(
    ({ value }) => {
      setYearFrom(value);
      setYearTo(recalculateYearTo(value));
    },
    [recalculateYearTo]
  );

  const handleChangeYearTo = useCallback(
    ({ value }) => {
      setYearFrom(recalculateYearFrom(value));
      setYearTo(value);
    },
    [recalculateYearFrom]
  );

  const handleChangeMileageFrom = (value) => {
    setMileageFrom(value);
    if (mileageTo && value) setMileageTo(recalculateMileageTo(value));
  };

  const handleChangeMileageTo = (value) => {
    if (mileageFrom && value) {
      setMileageFrom(recalculateMileageFrom(value));
    }
    setMileageTo(value);
  };

  const handleChangePriceFrom = (value) => {
    setPriceFrom(value);
    if (priceTo && value) setPriceTo(recalculatePriceTo(value));
  };

  const handleChangePriceTo = (value) => {
    if (priceFrom && value) setPriceFrom(recalculatePriceFrom(value));
    setPriceTo(value);
  };

  const handleChangeIsRegularSources = () =>
    setIsRegularSources(!isRegularSources);

  const regularSourcesKeys = Object.keys(utilConstants.regularSources);
  const powersportsSourcesKeys = Object.keys(utilConstants.powersportsSources);
  const currentSourcesKeys = isRegularSources
    ? regularSourcesKeys
    : powersportsSourcesKeys;
  const requiredValuesKeys = Object.keys(utilConstants.requiredValues);
  const includeValuesKeys = Object.keys(utilConstants.includeValues);

  const vehiclesCount = {};
  stats.forEach((source) => {
    vehiclesCount[source.source_name] = source.vehicles_count;
  });

  const handleChangeAllSources = (isChecked) => {
    if (isChecked) {
      setLocalSources(currentSourcesKeys);
      return;
    }
    setLocalSources([]);
  };

  const handleChangeSources = (source, isChecked) => {
    if (isChecked) {
      setLocalSources([...localSources, source]);
      return;
    }
    setLocalSources(
      localSources.filter((localSource) => localSource !== source)
    );
  };

  const handleChangeColors = (color, isChecked) => {
    if (isChecked) {
      setLocalColors([...localColors, color.value]);
      return;
    }
    setLocalColors(
      localColors.filter((localColor) => localColor !== color.value)
    );
  };

  const handleChangeIncludeValues = (includeValues, isChecked) => {
    if (utilConstants.includeValues[includeValues].reverse) {
      isChecked = !isChecked;
    }

    if (isChecked) {
      setLocalIncludeValues([...localIncludeValues, includeValues]);
      return;
    }
    setLocalIncludeValues(
      localIncludeValues.filter(
        (localIncludeValues) => localIncludeValues !== includeValues
      )
    );
  };

  const handleChangeRequiredValues = (requiredValue, isChecked) => {
    if (isChecked) {
      setLocalRequiredValues([...localRequiredValues, requiredValue]);
      return;
    }
    setLocalRequiredValues(
      localRequiredValues.filter(
        (localRequiredValue) => localRequiredValue !== requiredValue
      )
    );
  };

  const handleClickReset = () => {
    onResetFilters(isRegularSources, currentSourcesKeys);
  };

  const handleDistanceRange = (value) => {
    setLocalDistanceRange(value);
  };

  useEffect(() => {
    if (firstRender.current) {
      return;
    }
    handleClickReset();
  }, [isRegularSources]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return (
    <>
      <div
        className={
          s["vehicles-filters"] +
          (isExtendingFiltering
            ? ` ${s["vehicles-filters--employee"]}`
            : ` ${s["vehicles-filters--customer"]}`) +
          (isOpened ? "" : ` ${s["vehicles-filters--closed"]}`)
        }
      >
        {ableToChangeVehiclesType && (
          <div className={s["vehicles-filters__switch-wrapper"]}>
            <span>Auto</span>
            <Switch
              isChecked={!isRegularSources}
              backgroundColor="#5c57ff"
              onChange={handleChangeIsRegularSources}
            />
            <span>Powersports</span>
          </div>
        )}
        <div className={s["vehicles-filters__search-wrapper"]}>
          <Input
            className={
              s["vehicles-filters__input"] +
              " " +
              s["vehicles-filters__input--search"]
            }
            value={localSearchText}
            disabled={isLoading}
            onChange={(value) => setLocalSearchText(value)}
            onKeyDown={handleKeyDownLocalMonoSearchText}
          />
          <FilledButton
            className={s["vehicles-filters__button"]}
            disabled={isLoading}
            onClick={handleClickSearch}
          >
            <img src={iconSearchWhite} alt="Search" />
          </FilledButton>
        </div>

        <div className={s["vehicles-filters__sort-wrapper"]}>
          <p className={s["vehicles-filters__text"]}>Sort by</p>
          <MemoizedSelect
            options={utilConstants.sortingVehiclesOptions}
            value={
              utilConstants.sortingVehiclesOptions.filter(
                (selectOption) =>
                  selectOption.value.field === localSorting.field &&
                  selectOption.value.order === localSorting.order
              )[0]
            }
            onChange={handleChangeSorting}
            isSearchable={false}
            components={selectComponents}
            styles={selectStyles}
          />
        </div>

        <div className={s["vehicles-filters__postal-wrapper"]}>
          <p className={s["vehicles-filters__text"]}>Postal code</p>
          <Input
            className={
              s["vehicles-filters__input"] +
              " " +
              s["vehicles-filters__input--zip"] +
              (isErrorLocalZipCode
                ? ` ${s["vehicles-filters__input--error"]}`
                : "")
            }
            value={localZipCode}
            placeholder="000 000"
            disabled={isLoading}
            isError={isErrorLocalZipCode}
            spliter=" "
            chunkSize={3}
            onChange={handleChangeLocalZipCode}
            onKeyDown={handleKeyDownLocalZipCode}
            onBlur={handleBlurLocalZipCode}
            ref={zipCodeInputRef}
          />
        </div>
        {localZipCode && (
          <div className={s["vehicles-filters__slider-wrapper"]}>
            <p className={s["vehicles-filters__text"]}>
              Distance from postal code (km)
            </p>
            <div className={s["vehicles-filters__slider"]}>
              <RangeInput
                maxValue={utilConstants.distanceFilter.maxDistance}
                minValue={utilConstants.distanceFilter.minDistance}
                step={utilConstants.distanceFilter.stepForDistanceSearch}
                value={localDistanceRange}
                onChange={handleDistanceRange}
              />
            </div>
          </div>
        )}
        <div className={s["vehicles-filters__sort-wrapper"]}>
          <p className={s["vehicles-filters__text"]}>Year</p>
          <div className={s["vehicles-filters__range-wrapper"]}>
            <MemoizedSelect
              options={utilConstants.filtersVehiclesYearOptions}
              value={
                utilConstants.filtersVehiclesYearOptions.filter(
                  (selectOption) => selectOption.value === yearFrom
                )[0]
              }
              onChange={handleChangeYearFrom}
              isSearchable={false}
              components={selectComponents}
              styles={selectStyles}
            />
            <p className={s["vehicles-filters__dash"]}>—</p>
            <MemoizedSelect
              options={utilConstants.filtersVehiclesYearOptions}
              value={
                utilConstants.filtersVehiclesYearOptions.filter(
                  (selectOption) => selectOption.value === yearTo
                )[0]
              }
              onChange={handleChangeYearTo}
              isSearchable={false}
              components={selectComponents}
              styles={selectStyles}
            />
          </div>
        </div>

        <div className={s["vehicles-filters__sort-wrapper"]}>
          <p className={s["vehicles-filters__text"]}>Mileage (km)</p>
          <div className={s["vehicles-filters__range-wrapper"]}>
            <NumberInput
              value={mileageFrom}
              minValue=""
              placeholder="Min"
              onChange={handleChangeMileageFrom}
              spliter=" "
              chunkSize={3}
              className={s["vehicles-filters__sort-controller"]}
            />
            <p className={s["vehicles-filters__dash"]}>—</p>
            <NumberInput
              value={mileageTo}
              minValue=""
              placeholder="Max"
              onChange={handleChangeMileageTo}
              spliter=" "
              chunkSize={3}
              className={s["vehicles-filters__sort-controller"]}
            />
          </div>
        </div>
        <div className={s["vehicles-filters__sort-wrapper"]}>
          <p className={s["vehicles-filters__text"]}>Price ($)</p>
          <div className={s["vehicles-filters__range-wrapper"]}>
            <NumberInput
              value={priceFrom}
              minValue=""
              placeholder="Min"
              onChange={handleChangePriceFrom}
              spliter=" "
              chunkSize={3}
              className={s["vehicles-filters__sort-controller"]}
            />
            <p className={s["vehicles-filters__dash"]}>—</p>
            <NumberInput
              value={priceTo}
              minValue=""
              placeholder="Max"
              onChange={handleChangePriceTo}
              spliter=" "
              chunkSize={3}
              className={s["vehicles-filters__sort-controller"]}
            />
          </div>
        </div>
        <div className={s["vehicles-filters__sort-wrapper"]}>
          <p className={s["vehicles-filters__text"]}>Transmission</p>
          <MemoizedSelect
            options={utilConstants.vehicleTransmissionOptions}
            value={
              utilConstants.vehicleTransmissionOptions.filter(
                (selectOption) => selectOption.value === localTransmissionType
              )[0]
            }
            onChange={handleChangeTransmission}
            isSearchable={false}
            components={selectComponents}
            styles={selectStyles}
          />
        </div>
        <div className={s["vehicles-filters__sort-wrapper"]}>
          <p className={s["vehicles-filters__text"]}>Fuel type</p>
          <MemoizedSelect
            options={utilConstants.vehicleFuelOptions}
            value={
              utilConstants.vehicleFuelOptions.filter(
                (selectOption) => selectOption.value === localFuelType
              )[0]
            }
            onChange={handleChangeFuelType}
            isSearchable={false}
            components={selectComponents}
            styles={selectStyles}
          />
        </div>
        {isExtendingFiltering && (
          <div className={s["vehicles-filters__sort-wrapper"]}>
            <p className={s["vehicles-filters__text"]}>Progress state</p>
            <MemoizedSelect
              options={utilConstants.vehicleProgressStateOptions}
              value={
                utilConstants.vehicleProgressStateOptions.filter(
                  (selectOption) => selectOption.value === localProgressState
                )[0]
              }
              onChange={handleChangeProgressState}
              isSearchable={false}
              components={selectComponents}
              styles={selectStyles}
            />
          </div>
        )}
        <div className={s["vehicles-filters__sort-wrapper"]}>
          <p className={s["vehicles-filters__text"]}>Color</p>
          <div className={s["vehicles-filters__color-wrapper"]}>
            {utilConstants.colorFilter.map((color) => (
              <ColoredCheckbox
                onChange={(isColorChecked) =>
                  handleChangeColors(color, isColorChecked)
                }
                isChecked={localColors.includes(color.value)}
                title={color.title}
                tickColor={color.tickColor}
                style={{ background: color.background, border: color.border }}
                key={color.value}
              />
            ))}
          </div>
        </div>

        {isExtendingFiltering && (
          <>
            <div
              className={
                s["vehicles-filters__sort-wrapper"] +
                " " +
                s["vehicles-filters__sort-wrapper--main-checkboxes"]
              }
            >
              {includeValuesKeys.map((includeValuesKey) => (
                <Checkbox
                  label={utilConstants.includeValues[includeValuesKey].label}
                  isChecked={
                    utilConstants.includeValues[includeValuesKey].reverse
                      ? !localIncludeValues.includes(includeValuesKey)
                      : localIncludeValues.includes(includeValuesKey)
                  }
                  onChange={(isChecked) =>
                    handleChangeIncludeValues(includeValuesKey, isChecked)
                  }
                  key={includeValuesKey}
                />
              ))}
              {requiredValuesKeys.map((requiredValueKey) => (
                <Checkbox
                  label={utilConstants.requiredValues[requiredValueKey].label}
                  isChecked={localRequiredValues.includes(requiredValueKey)}
                  onChange={(isChecked) =>
                    handleChangeRequiredValues(requiredValueKey, isChecked)
                  }
                  key={requiredValueKey}
                />
              ))}
            </div>
            <div className={s["vehicles-filters__sources-wrapper"]}>
              <Checkbox
                label="Source:"
                isChecked={localSources.length === currentSourcesKeys.length}
                isBold
                onChange={handleChangeAllSources}
              />
              {currentSourcesKeys.map((sourceKey) => (
                <Checkbox
                  label={
                    utilConstants.sources[sourceKey].label +
                    (typeof vehiclesCount[sourceKey] == "number"
                      ? ` (${vehiclesCount[sourceKey]})`
                      : "")
                  }
                  isChecked={localSources.includes(sourceKey)}
                  onChange={(isChecked) =>
                    handleChangeSources(sourceKey, isChecked)
                  }
                  key={sourceKey}
                />
              ))}
            </div>
          </>
        )}

        <div className={s["vehicles-filters__buttons-wrapper"]}>
          <FilledButton
            className={s["vehicles-filters__button"]}
            disabled={isLoading || !localSources.length}
            onClick={handleClickApply}
          >
            Apply filters
          </FilledButton>
          <FilledButton
            className={s["vehicles-filters__button"]}
            disabled={isLoading}
            onClick={handleClickReset}
          >
            <img src={iconReset} alt="Reset" />
          </FilledButton>
        </div>
      </div>
      <img
        className={s["vehicles-filters__toggle"]}
        src={isOpened ? iconOpenedVehiclesFilters : iconClosedVehiclesFilters}
        alt="Close/open filters"
        onClick={() => setIsOpened(!isOpened)}
      />
    </>
  );
};

export default VehiclesFilters;
