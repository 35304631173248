import { createSlice } from "@reduxjs/toolkit";

export const conversationSlice = createSlice({
  name: "conversation",
  initialState: {
    isLoading: false,
    seller: {},
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSeller: (state, action) => {
      state.seller = action.payload;
    },
    resetSeller: (state, action) => {
      state.seller = {};
    },
  },
});

export const { setIsLoading, setSeller, resetSeller } =
  conversationSlice.actions;

export default conversationSlice.reducer;
