import s from "./index.module.scss";

const MAX_IMAGES_TO_SHOW = 6;
const MIN_IMAGES_TO_GRID_TEMPLATE = 3;

const AttachedMessageImages = ({
  media = [],
  handleClickImage = () => {},
  className = "",
  imagesListWrapperClassName = "",
  imageWrapperClassName = "",
}) => {
  const imagesToShow =
    media.length > MAX_IMAGES_TO_SHOW
      ? [...media].splice(0, MAX_IMAGES_TO_SHOW)
      : media;
  const hiddenImagesCount = media.length - imagesToShow.length + 1;

  return (
    <div
      className={s["attached_images"] + (className ? " " + className : "")}
      onClick={handleClickImage}
    >
      <div
        className={
          s["attached_images__wrapper"] +
          (imagesToShow.length < MIN_IMAGES_TO_GRID_TEMPLATE
            ? " " + s["attached_images__wrapper--single"]
            : "") +
          (imagesListWrapperClassName ? " " + imagesListWrapperClassName : "")
        }
      >
        {imagesToShow.map((img, index) => (
          <div
            className={
              s["attached_images__image-wrapper"] +
              (imageWrapperClassName ? " " + imageWrapperClassName : "")
            }
            key={index}
          >
            {index === MAX_IMAGES_TO_SHOW - 1 &&
            media.length > MAX_IMAGES_TO_SHOW ? (
              <>
                <img
                  alt="attached file"
                  key={index}
                  src={img.link}
                  className={s["attached_images__image"]}
                />
                <div className={s["attached_images__image-image-blur"]}>
                  <span
                    className={s["attached_images__hidden-images-value"]}
                  >{`+${hiddenImagesCount}`}</span>
                </div>
              </>
            ) : (
              <img
                alt="attached file"
                key={index}
                src={img.link}
                className={s["attached_images__image"]}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AttachedMessageImages;
