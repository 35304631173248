import Modal from "components/Modal";
import VehicleGallery from "components/VehicleGallery";
import iconCrossWhite from "assets/icons/cross-white.svg";
import s from "./index.module.scss";

const ConvoImagesModal = ({
  isOpen = false,
  onClose = () => {},
  images = [],
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={s["convo-images-modal"]}>
        <div className={s["convo-images-gallery-wrapper"]}>
          <VehicleGallery photoLinks={images} />
        </div>
        <img
          className={s["convo-images-modal__cross"]}
          src={iconCrossWhite}
          alt="Close"
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};

export default ConvoImagesModal;
