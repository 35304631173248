import AsyncSelect from "react-select/async";
import ExpandIndicator from "components/ExpandIndicator";
import FilledButton from "components/buttons/FilledButton";
import iconDropdownBlue from "assets/icons/dropdown-blue.svg";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const AddToWatchList = ({
  options = [],
  value = null,
  isDisabled = false,
  onChange = () => {},
  onDone = () => {},
}) => {
  const loadOptions = (inputValue, callback) =>
    serviceWatchLists.searchMe(inputValue).then((watchLists) =>
      callback(
        watchLists.map((watchList) => ({
          value: watchList.id,
          label: `${watchList.title} (${watchList.client_name})`,
        }))
      )
    );

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      border: "unset",
      borderRadius: "unset",
      borderBottom: "1px solid #e0e0e0",
      boxSizing: "border-box",
      boxShadow: "unset",
      cursor: "pointer",
      width: "100%",
      height: 42,
      marginTop: 17,
      minHeight: "unset",
      display: "grid",
      gridTemplateColumns: "1fr 16px",
      gridColumnGap: 10,
      padding: "0px 9px 0px 8px",
      backgroundColor: "#fff",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#7f7f7f",
      fontSize: 16,
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      fontSize: 16,
      display: "grid",
      gridTemplateTolumns: "1fr 0",
      alignItems: "center",
    }),

    singleValue: (provided) => ({
      ...provided,
      overflow: "unset",
      fontSize: 16,
      marginTop: 1,
      color: "#1c1c1c",
    }),

    indicatorsContainer: (provided) => ({
      ...provided,
      marginTop: 3,
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    menu: (provided) => ({
      ...provided,
      margin: 0,
      boxShadow: "unset",
      borderRadius: 0,
    }),

    menuList: (provided) => ({
      ...provided,
      padding: 0,
      maxHeight: 408,
    }),

    option: (provided) => ({
      ...provided,
      minHeight: 34,
      fontSize: 14,
      padding: "0 10px",
      display: "grid",
      alignContent: "center",
      color: "inherit",
      backgroundColor: "#fff",
      wordBreak: "break-word",
      "&:hover": {
        backgroundColor: "#cfdeff !important",
      },
    }),
  };

  return (
    <div className={s["add-to-watch-list"]}>
      <p className={s["add-to-watch-list__text"]}>Choose watchlist</p>
      <AsyncSelect
        loadOptions={loadOptions}
        defaultOptions={options}
        value={value}
        onChange={onChange}
        components={{
          DropdownIndicator: ({ selectProps: { menuIsOpen } }) => (
            <ExpandIndicator
              className={s["add-to-watch-list__dropdown-indicator"]}
              src={iconDropdownBlue}
              isOpen={menuIsOpen}
            />
          ),
        }}
        styles={selectStyles}
      />
      <FilledButton
        className={s["add-to-watch-list__button"]}
        disabled={isDisabled}
        onClick={onDone}
      >
        Add to watchlist
      </FilledButton>
      <p
        className={
          s["add-to-watch-list__text"] + " " + s["add-to-watch-list__text--or"]
        }
      >
        or
      </p>
    </div>
  );
};

export default AddToWatchList;
