import useVehiclesSelection from "hooks/useVehiclesSelection";
import useVehicleViewToggle from "hooks/useVehicleViewToggle";

import VehiclesSaveSearchButton from "components/buttons/SaveSearchButton";
import VehicleViewToggle from "components/VehiclesViewToggle";
import VehiclesExportCsvButton from "components/buttons/VehiclesExportCsvButton";
import VehicleCheckbox from "components/inputs/VehicleCheckbox";

import s from "./index.module.scss";

const VehicleToolbar = ({
  vehicleRepository,
  vehicleStateStorageGateway,
  canSaveSearch = true,
  canExportCsv = true,
  canSelectAll = false,
}) => {
  const { isGridView, switchToGridView, switchToListView } =
    useVehicleViewToggle(vehicleRepository, vehicleStateStorageGateway);

  const { isAllVehiclesSelected, onChangeVehiclesSelection } =
    useVehiclesSelection(vehicleRepository, canSelectAll);

  const handleChangeVehiclesSelection = (isChecked) => {
    onChangeVehiclesSelection(isChecked);
  };

  return (
    <div className={s["vehicle-controllers-wrapper"]}>
      {canSelectAll && (
        <div
          className={
            s["vehicle-controllers-control"] +
            " " +
            s["vehicle-controllers-control--select-all"]
          }
        >
          <VehicleCheckbox
            title="Select all"
            onChange={handleChangeVehiclesSelection}
            isChecked={isAllVehiclesSelected}
            checkmarkClassName={s["vehicle-checkbox-checkmark"]}
            labelClassName={s["vehicle-checkbox-label"]}
            titleClassName={s["vehicle-checkbox-title"]}
            wrapperClassName={s["vehicle-checkbox-wrapper"]}
          />
        </div>
      )}
      {canSaveSearch && (
        <div
          className={
            s["vehicle-controllers-control"] +
            " " +
            s["vehicle-controllers-control--save-search"]
          }
        >
          <VehiclesSaveSearchButton />
        </div>
      )}
      <div
        className={
          s["vehicle-controllers-control"] +
          " " +
          s["vehicle-controllers-control--view-toggle"]
        }
      >
        <VehicleViewToggle
          isGridView={isGridView}
          handleClickGridIcon={switchToGridView}
          handleClickListIcon={switchToListView}
        />
      </div>
      {canExportCsv && (
        <div
          className={
            s["vehicle-controllers-control"] +
            " " +
            s["vehicle-controllers-control--export-csv"]
          }
        >
          <VehiclesExportCsvButton>
            <span className={s["export-csv__btn__text"]}>export</span>{" "}
            <span className={s["export-csv__btn__text"]}>search results</span>
          </VehiclesExportCsvButton>
        </div>
      )}
    </div>
  );
};

export default VehicleToolbar;
