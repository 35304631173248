import { useState } from "react";
import { Link } from "react-router-dom";
import s from "./index.module.scss";

const SavedSearchCard = ({
  savedSearch: { thumbnail = null, title = "", id = "" } = {},
}) => {
  const [isErrorImageLoading, setIsErrorImageLoading] = useState(false);

  const handleErrorThumbnail = () => setIsErrorImageLoading(true);

  return (
    <Link
      className={s["saved-search-card"]}
      to={`/employee/my-saved-searches/${id}`}
    >
      {thumbnail?.link && !isErrorImageLoading ? (
        <img
          className={s["saved-search-card__thumbnail"]}
          src={thumbnail.link}
          alt="Saved search thumbnail"
          onError={handleErrorThumbnail}
        />
      ) : (
        <div className={s["saved-search-card__thumbnail-placeholder"]}></div>
      )}
      <div className={s["saved-search-card__info"]}>
        <p className={s["saved-search-card__text"]}>{title}</p>
      </div>
    </Link>
  );
};

export default SavedSearchCard;
