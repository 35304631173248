import iconAvatar from "assets/icons/avatar-placeholder.svg";
import utilFormatter from "utils/formatter";
import s from "./index.module.scss";

const Preview = ({ event = {} }) => {
  const {
    comment: { text = "" } = "",
    event_at: eventAt = "",
    user: {
      avatar = {},
      first_name: firstNane = "",
      last_name: lastName = "",
    } = "",
  } = event;

  return (
    <div className={s["comment-wrapper"]}>
      <img
        className={s["comment-avatar"]}
        src={avatar?.link || iconAvatar}
        alt="Avatar"
      />
      <div className={s["comment"]}>
        <div className={s["comment__name"]}>
          {firstNane} {lastName}
        </div>
        <div className={s["comment__date"]}>
          {utilFormatter.prettyDateTimeJsx(eventAt)}
        </div>
        <div className={s["comment__text"]}>{text}</div>
      </div>
    </div>
  );
};

export default Preview;
