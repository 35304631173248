import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import toaster from "utils/toaster";
import SavedSearchDetailsTitle from "components/SavedSearchDetailsTitle";
import SavedSearchVehicleToolbar from "components/VehicleToolbar/saved-search";
import VehiclePager from "components/VehiclePager";
import SavedSearchVehicleCard from "components/VehicleCard/saved-search";
import VehicleListTableRow from "components/Tables/VehicleListTable/VehicleListTableRow";
import SavedSearchVehiclesFilters from "components/SavedSearchVehiclesFilters";
import Loader from "components/Loader";
import serviceSavedSearches from "services/saved-searches";
import savedSearchRepository from "repositories/savedSearchRepository";
import userRepository from "repositories/userRepository";
import savedSearchStateStorageGateway from "gateways/storage/savedSearchStateStorage";

import s from "./index.module.scss";

const SavedSearchDetails = () => {
  const { savedSearchId } = useParams();

  const history = useHistory();

  const shouldFetchSavedSearch =
    savedSearchRepository.getSavedSearchId() !== savedSearchId;

  const [isSavedSearchLoading, setIsSavedSearchLoading] = useState(() => {
    return shouldFetchSavedSearch ? true : false;
  });
  const [isLoading, setIsLoading] = useState(false);

  const defaultTitle = useSelector((state) =>
    savedSearchRepository.getTitle(state)
  );
  const defaultSearchText = useSelector((state) =>
    savedSearchRepository.getDefaultSearchText(state)
  );
  const defaultSorting = useSelector((state) =>
    savedSearchRepository.getDefaultSorting(state)
  );
  const defaultZipCode = useSelector((state) =>
    savedSearchRepository.getDefaultZipCode(state)
  );
  const defaultFilters = useSelector((state) =>
    savedSearchRepository.getDefaultFilters(state)
  );

  const [localDefaultSearchText, setLocalDefaultSearchText] = useState(() => {
    return shouldFetchSavedSearch
      ? defaultSearchText
      : savedSearchRepository.getSearchText();
  });

  const [localDefaultSorting, setLocalDefaultSorting] = useState(() => {
    return shouldFetchSavedSearch
      ? defaultSorting
      : savedSearchRepository.getSorting();
  });
  const [localDefaultZipCode, setLocalDefaultZipCode] = useState(() => {
    return shouldFetchSavedSearch
      ? defaultZipCode
      : savedSearchRepository.getZipCode();
  });
  const [localDefaultFilters, setLocalDefaultFilters] = useState(() => {
    return shouldFetchSavedSearch
      ? defaultFilters
      : savedSearchRepository.getFilters();
  });

  const isLoaderOpen = isSavedSearchLoading || isLoading;

  const goBackToSavedSearchList = () => {
    history.push("/employee/my-saved-searches");
  };

  const updateSavedSearchState = (savedSearch) => {
    setLocalDefaultSearchText(savedSearch.query.searchText);
    setLocalDefaultSorting(savedSearch.query.sorting);
    setLocalDefaultFilters(savedSearch.query.filters);
    setLocalDefaultZipCode(savedSearch.query.zipCode);

    savedSearchRepository.updateTitle(savedSearch.title);
    savedSearchRepository.updateDefaultSorting(savedSearch.query.sorting);
    savedSearchRepository.updateDefaultZipCode(savedSearch.query.zipCode);
    savedSearchRepository.updateDefaultFilters(savedSearch.query.filters);
    savedSearchRepository.updateDefaultSearchText(savedSearch.query.searchText);
  };

  const handleRemoveSavedSearch = async () => {
    setIsLoading(true);
    return serviceSavedSearches
      .deleteSavedSearch(savedSearchId)
      .then(() => goBackToSavedSearchList())
      .catch(() =>
        toaster.displayErrorBottom("Something went wrong. Please try again")
      )
      .finally(() => setIsLoading(false));
  };

  const handleUpdateSavedSearch = async (title) => {
    title = title || defaultTitle;
    setIsLoading(true);
    return serviceSavedSearches
      .updateSavedSearch(savedSearchId, title)
      .then((savedSearch) => {
        updateSavedSearchState(savedSearch);
      })
      .catch(() => {
        toaster.displayErrorBottom("Something went wrong. Please try again");
        return Promise.reject();
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!shouldFetchSavedSearch) return;

    savedSearchRepository.updateTitle("");
    setIsSavedSearchLoading(true);
    serviceSavedSearches
      .getSavedSearch(savedSearchId)
      .then((savedSearch) => updateSavedSearchState(savedSearch))
      .catch(() => goBackToSavedSearchList())
      .finally(() => setIsSavedSearchLoading(false));
  }, [savedSearchId]);

  return (
    <div className={s["saved-search-details"]}>
      {!isSavedSearchLoading ? (
        <SavedSearchVehiclesFilters
          vehicleRepository={savedSearchRepository}
          vehicleService={serviceSavedSearches}
          userRepository={userRepository}
          defaultSearchText={localDefaultSearchText}
          defaultSorting={localDefaultSorting}
          defaultZipCode={localDefaultZipCode}
          defaultFilters={localDefaultFilters}
        />
      ) : (
        <div></div>
      )}
      <div className={s["saved-search-details__wrapper"]}>
        <SavedSearchDetailsTitle
          onRemoveSavedSearch={handleRemoveSavedSearch}
          onUpdateTitle={handleUpdateSavedSearch}
          defaultTitle={defaultTitle}
        />
        <SavedSearchVehicleToolbar
          vehicleRepository={savedSearchRepository}
          vehicleStateStorageGateway={savedSearchStateStorageGateway}
          onReSaveSearch={handleUpdateSavedSearch}
        />
        {!isSavedSearchLoading && (
          <VehiclePager
            vehicleService={serviceSavedSearches}
            vehicleRepository={savedSearchRepository}
            baseUrl={`/employee/my-saved-searches/${savedSearchId}/`}
            cardComponent={SavedSearchVehicleCard}
            rowComponent={VehicleListTableRow}
          />
        )}
      </div>
      <Loader isOpen={isLoaderOpen} />
    </div>
  );
};

export default SavedSearchDetails;
