import { useState, useEffect } from "react";
import Modal from "components/Modal";
import Loader from "components/Loader";
import ConversationModalMessage from "./ConversationModalMessage";
import ConvoImagesModal from "../ConvoImagesModal";
import iconCrossBlack from "assets/icons/cross-black.svg";
import iconAvatar from "assets/icons/avatar-placeholder.svg";
import serviceVehicles from "services/vehicles";
import utilConstants from "utils/constants";
import s from "./index.module.scss";

const OtherConversationModal = ({
  isOpen = false,
  onClose = () => {},
  vehicleId = "",
  vehicleSource = "",
  userId = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isOpenConvoImagesModal, setIsOpenConvoImagesModal] = useState(false);
  const [convoImages, setConvoImages] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setMessages([]);
    setIsLoading(true);

    serviceVehicles
      .getConversation(vehicleId, userId)
      .then((messages) => {
        const formatMessage = (message) => {
          return {
            id: message.id,
            createdAt: message.created_at,
            sender: {
              name:
                message.sender === "user"
                  ? `${message.user.first_name} ${message.user.last_name}`
                  : message.vehicle?.dealer_name
                  ? message.vehicle.dealer_name
                  : "Seller",
              imgData:
                message.sender === "user"
                  ? message.user.avatar
                    ? { src: message.user.avatar.link }
                    : { src: iconAvatar }
                  : utilConstants.sources[vehicleSource]?.imgData,
              type: message.sender,
            },
            media: message.media ? message.media : [],
            text: message.text,
          };
        };

        setMessages(messages.map(formatMessage));
      })
      .finally(() => setIsLoading(false));
  }, [isOpen]);

  const handleClose = () => {
    if (isLoading) {
      return;
    }

    onClose();
  };

  return (
    <>
      <Modal
        className={s["other-conversation-modal__wrapper"]}
        isOpen={isOpen}
        onClose={handleClose}
      >
        <div className={s["other-conversation-modal"]}>
          <img
            className={s["other-conversation-modal__cross"]}
            src={iconCrossBlack}
            alt="Close"
            onClick={handleClose}
          />
          <div className={s["other-conversation-modal__messages__wrapper"]}>
            <div className={s["other-conversation-modal__messages"]}>
              {messages.map((message) => (
                <ConversationModalMessage
                  message={message}
                  key={message.id}
                  setConvoImages={setConvoImages}
                  setIsOpenConvoImagesModal={setIsOpenConvoImagesModal}
                />
              ))}
            </div>
          </div>
        </div>
        <Loader isOpen={isLoading} />
      </Modal>
      <ConvoImagesModal
        isOpen={isOpenConvoImagesModal}
        onClose={() => setIsOpenConvoImagesModal(false)}
        images={convoImages}
      />
    </>
  );
};

export default OtherConversationModal;
