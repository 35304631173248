import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import useVehiclesListTableRow from "hooks/useVehiclesListTableRow";

import utilConstants from "utils/constants";
import utilFormatter from "utils/formatter";

import s from "./index.module.scss";

const HotlistVehiclesListTableRow = ({ vehicle = {}, vehicleRepository }) => {
  const {
    to = "",
    id = "",
    year = "",
    make = "",
    model = "",
    trim = "",
    vin = null,
    mileage = null,
    days_on_market: daysOnMarket = null,
    price = null,
    our_offered_price: offeredPrice = null,
    lowest_offered_price: lowestOfferedPrice = null,
    source_name: sourceName = "",
    thumbnail = {},
    delisted = false,
    last_activity_at = "",
  } = vehicle;
  const vehicleRowRef = useRef();

  const {
    lastAccessedVehicleId,
    isThumbnailLoadingError,
    onClickVehicleLinkCell,
    onThumbnailLoadingError,
  } = useVehiclesListTableRow(vehicleRepository);

  const handleClickVehicleLinkCell = () => {
    onClickVehicleLinkCell(id);
  };

  useEffect(() => {
    if (id === lastAccessedVehicleId) {
      const vehicleRowRootElement = vehicleRowRef.current;

      if (vehicleRowRootElement) {
        vehicleRowRootElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, []);

  return (
    <Link
      className={s["vehicle"] + (delisted ? " " + s["vehicle--delisted"] : "")}
      ref={vehicleRowRef}
      to={to}
      onClick={handleClickVehicleLinkCell}
    >
      <div className={s["vehicle__left-side"]}>
        <div className={s["vehicle__cell"] + " " + s["vehicle__cell--source"]}>
          <div className={s["vehicle__images-wrapper"]}>
            {Object.keys(utilConstants.sources).includes(sourceName) && (
              <div className={s["vehicle__source-wrapper"]}>
                <img
                  className={s["vehicle__source-icon"]}
                  src={utilConstants.sources[sourceName].imgData.src}
                  srcSet={utilConstants.sources[sourceName].imgData.srcSet}
                  alt={utilConstants.sources[sourceName].imgData.alt}
                />
              </div>
            )}
            {thumbnail?.link && !isThumbnailLoadingError ? (
              <img
                className={s["vehicle__thumbnail"]}
                src={thumbnail.link}
                alt="thumbnail"
                onError={onThumbnailLoadingError}
              />
            ) : (
              <div className={s["vehicle__thumbnail-placeholder"]}></div>
            )}
          </div>
        </div>
        <div
          className={
            s["vehicle__cell"] +
            " " +
            s["vehicle__cell--ymmt"] +
            (delisted ? " " + s["vehicle__cell--delisted"] : "")
          }
        >
          <span className={s["vehicle__text"] + " " + s["vehicle__text--ymmt"]}>
            {year ? `${year} ` : ""}
            <span
              className={s["vehicle__text"] + " " + s["vehicle__text--bold"]}
            >
              {[make, model, trim].join(" ")}
            </span>
          </span>
          {delisted ? (
            <div className={s["vehicle__delisted"]}>
              <p className={s["vehicle__delisted-badge"]}>Delisted</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={s["vehicle__cell"]}>
          {typeof mileage === "number" && (
            <span className={s["vehicle__text"]}>
              {utilFormatter.prettyString(mileage, " ")}
            </span>
          )}
        </div>
        <div className={s["vehicle__cell"] + " " + s["vehicle__cell--vin"]}>
          {vin && (
            <span
              className={s["vehicle__text"] + " " + s["vehicle__text--vin"]}
            >
              {vin}
            </span>
          )}
        </div>
        <div className={s["vehicle__cell"]}>
          {typeof daysOnMarket === "number" && (
            <span className={s["vehicle__text"]}>
              {utilFormatter.prettyString(daysOnMarket, " ")}
            </span>
          )}
        </div>
        <div className={s["vehicle__cell"]}>
          {typeof price === "number" && (
            <span className={s["vehicle__text"]}>
              {utilFormatter.prettyString(price, " ")}
            </span>
          )}
        </div>
      </div>
      <div className={s["vehicle__right-side"]}>
        <div className={s["vehicle__cell"]}>
          <span
            className={
              s["vehicle__text"] +
              (!offeredPrice ? " " + s["vehicle__text--price-placeholder"] : "")
            }
          >
            {offeredPrice
              ? utilFormatter.prettyString(offeredPrice, " ")
              : "000 000"}
          </span>
        </div>
        <div className={s["vehicle__cell"]}>
          <span
            className={
              s["vehicle__text"] +
              (!lowestOfferedPrice
                ? " " + s["vehicle__text--price-placeholder"]
                : "")
            }
          >
            {lowestOfferedPrice
              ? utilFormatter.prettyString(lowestOfferedPrice, " ")
              : "000 000"}
          </span>
        </div>
        <div className={s["vehicle__cell"]}>
          <span className={s["vehicle__text"]}>
            {utilFormatter.prettyDateTimeJsx(last_activity_at)}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default HotlistVehiclesListTableRow;
