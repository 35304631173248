import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PublicWatchListGrid from "components/VehiclesGridPublicWatchList";
import PublicWatchListVehicleCard from "components/VehicleCard/PublicWatchList";
import Loader from "components/Loader";
import iconScrollToTopHover from "assets/icons/scroll-to-top.svg";
import serviceWatchLists from "services/watch-lists";
import s from "./index.module.scss";

const WatchListDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const { watchListId } = useParams();

  useEffect(() => {
    serviceWatchLists
      .getPublicVehicles(watchListId)
      .then((data) =>
        setVehicles(
          data.map((vehicle) => ({
            to: `/public-watch-lists/${watchListId}/vehicles/${vehicle.id}`,
            id: vehicle.id,
            thumbnail: vehicle.thumbnail,
            year: vehicle.build.year,
            make: vehicle.build.make,
            model: vehicle.build.model,
            trim: vehicle.build.trim,
            mileage: vehicle.mileage,
            dist: vehicle.dist,
            price: vehicle.price,
            reaction: vehicle.reaction,
            delisted: vehicle.delisted,
            isAbleToRemove: false,
            onRemove: () => {},
          }))
        )
      )
      .finally(() => setIsLoading(false));
  }, [watchListId]);

  const handleClickScrollToTop = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <div className={s["watchlists-customer__vehicles"]}>
      <PublicWatchListGrid
        vehicles={vehicles}
        cardComponent={PublicWatchListVehicleCard}
        cardProps={{ watchListId }}
      />
      {!isLoading && (
        <img
          src={iconScrollToTopHover}
          alt="Scroll to top"
          className={s["watchlists-customer__scroll-to-top"]}
          onClick={handleClickScrollToTop}
        />
      )}
      <Loader isOpen={isLoading} />
    </div>
  );
};

export default WatchListDetails;
