import { useState, useEffect, useRef, useLayoutEffect } from "react";
import imageVehicleThumbnailPlaceholderLogo from "assets/images/logo-gray.svg";
import iconVehicleGalleryPrev from "assets/icons/vehicle-gallery-prev.svg";
import iconVehicleGalleryNext from "assets/icons/vehicle-gallery-next.svg";
import iconVehicleReactionLikeOn from "assets/icons/vehicle-reaction-like-on.svg";
import iconVehicleReactionDislikeOn from "assets/icons/vehicle-reaction-dislike-on.svg";
import iconWipBadge from "assets/icons/wip-badge-big-icon.svg";
import s from "./index.module.scss";

const Image = ({ alt = "", ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 10000);

    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return (
      <img
        alt={alt}
        {...props}
        onLoad={() => {
          setIsLoading(false);
          setIsLoaded(true);
        }}
      />
    );
  }

  return isLoaded ? (
    <img alt={alt} {...props} />
  ) : (
    <div
      className={s["vehicle-gallery__placeholder"]}
      style={props.style || {}}
    >
      <img
        className={s["vehicle-gallery__logo"]}
        src={imageVehicleThumbnailPlaceholderLogo}
        alt={alt}
      />
    </div>
  );
};

const VehicleGallery = ({
  photoLinks = [],
  delisted = false,
  workInProgress = false,
  reaction = null,
}) => {
  const isMobileLayout = () => window.innerWidth < 790;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliceThumbnailsFrom, setSliceThumbnailsFrom] = useState(0);
  const [sliceThumbnailsTo, setSliceThumbnailsTo] = useState(
    isMobileLayout() ? 4 : 5
  );
  const [thumbnailsCount, setThumbnailsCount] = useState(0);
  const [, reRender] = useState();

  const photosWrapperRef = useRef();
  const thumbnailsWrapperRef = useRef();

  const calculatePhotoStyles = (index) => ({
    left: (index - currentSlide) * 790,
  });

  const handleClickPrev = () => {
    if (photoLinks.length <= 1) {
      return;
    }
    setCurrentSlide(
      currentSlide === 0 ? photoLinks.length - 1 : currentSlide - 1
    );
  };

  const handleClickNext = () => {
    if (photoLinks.length <= 1) {
      return;
    }
    setCurrentSlide(
      currentSlide === photoLinks.length - 1 ? 0 : currentSlide + 1
    );
  };

  const calculateThumbnailStyles = (index) => {
    const thumbnailsWrapper = thumbnailsWrapperRef.current;

    if (!thumbnailsWrapper) {
      return {
        left: 0,
        width: 0,
        height: 0,
      };
    }

    const width =
      (thumbnailsWrapper.getBoundingClientRect().width -
        (thumbnailsCount - 1) * 5) /
      thumbnailsCount;
    const height = width * 0.75;
    const left = (index - sliceThumbnailsFrom) * (width + 5);

    return {
      width,
      height,
      left,
    };
  };

  const handleClickThumbnail = (index) => setCurrentSlide(index);

  const calculateThumbnailCoverStyles = () => {
    const thumbnailsWrapper = thumbnailsWrapperRef.current;

    if (!thumbnailsWrapper) {
      return {
        left: 0,
        width: 0,
        height: 0,
      };
    }

    const width =
      (thumbnailsWrapper.getBoundingClientRect().width -
        (thumbnailsCount - 1) * 5) /
      thumbnailsCount;
    const height = width * 0.75;
    const left = (currentSlide - sliceThumbnailsFrom) * (width + 5);

    return {
      width,
      height,
      left,
    };
  };

  useEffect(() => {
    if (photoLinks.length <= thumbnailsCount) {
      return;
    }
    if (currentSlide <= sliceThumbnailsFrom && sliceThumbnailsFrom !== 0) {
      setSliceThumbnailsFrom(currentSlide === 0 ? 0 : currentSlide - 1);
      setSliceThumbnailsTo(
        currentSlide === 0
          ? thumbnailsCount
          : currentSlide + thumbnailsCount - 1
      );
      return;
    }
    if (
      currentSlide >= sliceThumbnailsTo - 1 &&
      sliceThumbnailsTo !== photoLinks.length
    ) {
      setSliceThumbnailsFrom(
        currentSlide -
          (currentSlide === photoLinks.length - 1
            ? thumbnailsCount - 1
            : thumbnailsCount - 2)
      );
      setSliceThumbnailsTo(
        currentSlide +
          (currentSlide === photoLinks.length - 1
            ? thumbnailsCount - 4
            : thumbnailsCount - 3)
      );
      return;
    }
  }, [
    currentSlide,
    photoLinks.length,
    sliceThumbnailsFrom,
    sliceThumbnailsTo,
    thumbnailsCount,
  ]);

  useLayoutEffect(() => {
    const recalculateThumbnaiulsCount = () =>
      setThumbnailsCount(isMobileLayout() ? 4 : 5);

    recalculateThumbnaiulsCount();

    window.addEventListener("resize", recalculateThumbnaiulsCount);

    return () =>
      window.removeEventListener("resize", recalculateThumbnaiulsCount);
  }, []);

  useLayoutEffect(() => {
    const resizePhotosWrapper = () => {
      const photosWrapper = photosWrapperRef.current;

      if (!photosWrapper) {
        return;
      }

      photosWrapper.style.height = `${
        photosWrapper.getBoundingClientRect().width * 0.75
      }px`;
    };

    resizePhotosWrapper();

    window.addEventListener("resize", resizePhotosWrapper);

    return () => window.removeEventListener("resize", resizePhotosWrapper);
  }, [photoLinks]);

  useLayoutEffect(() => {
    const resizeThumbnailsWrapper = () => {
      const thumbnailsWrapper = thumbnailsWrapperRef.current;

      if (!thumbnailsWrapper) {
        return;
      }

      const thumbnailWidth =
        (thumbnailsWrapper.getBoundingClientRect().width -
          (thumbnailsCount - 1) * 5) /
        thumbnailsCount;

      thumbnailsWrapper.style.height = `${thumbnailWidth * 0.75}px`;
    };

    resizeThumbnailsWrapper();

    window.addEventListener("resize", resizeThumbnailsWrapper);

    return () => window.removeEventListener("resize", resizeThumbnailsWrapper);
  }, [photoLinks, thumbnailsCount]);

  useLayoutEffect(() => {
    reRender();

    window.addEventListener("resize", reRender);

    return () => window.removeEventListener("resize", reRender);
  }, [photoLinks]);

  const [touchPositionX, setTouchPositionX] = useState(null);
  const [touchPositionY, setTouchPositionY] = useState(null);

  const handleTouchStart = (e) => {
    const touchDownX = e.touches[0].clientX;
    const touchDownY = e.touches[0].clientY;
    setTouchPositionX(touchDownX);
    setTouchPositionY(touchDownY);
  };

  const handleTouchEnd = (e) => {
    if (touchPositionX === null) {
      return;
    }
    const currentTouchX = e.changedTouches[0].clientX - touchPositionX;
    const currentTouchY = e.changedTouches[0].clientY - touchPositionY;
    setTouchPositionX(null);
    const stepX = Math.abs(currentTouchX);
    const stepY = Math.abs(currentTouchY);
    if (stepX < 30 || stepY > 80) {
      return;
    }
    currentTouchX > 0 ? prevSlide() : nextSlide();
  };

  const nextSlide = () => {
    if (currentSlide === photoLinks.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(photoLinks.length - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const reactionIcons = {
    like: iconVehicleReactionLikeOn,
    dislike: iconVehicleReactionDislikeOn,
  };

  if (photoLinks.length === 0) {
    return (
      <div className={s["vehicle-gallery"]}>
        <div
          className={s["vehicle-gallery__photos-wrapper"]}
          ref={photosWrapperRef}
        >
          <div className={s["vehicle-gallery__placeholder"]}>
            <img
              className={s["vehicle-gallery__logo"]}
              src={imageVehicleThumbnailPlaceholderLogo}
              alt="Vehicle"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={s["vehicle-gallery"]}>
      <div
        className={s["vehicle-gallery__photos-wrapper"]}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        ref={photosWrapperRef}
      >
        {photoLinks.map((photoLink, i) => (
          <Image
            className={s["vehicle-gallery__photo"]}
            src={photoLink?.link || ""}
            alt="Vehicle"
            style={calculatePhotoStyles(i)}
            key={`vehicle_${i}`}
          />
        ))}
        {delisted && <p className={s["vehicle-gallery__delisted"]}>Delisted</p>}
        {workInProgress && (
          <div className={s["vehicle-gallery__wip-badge-wrapper"]}>
            <img
              className={s["vehicle-gallery__wip-badge"]}
              src={iconWipBadge}
              alt="wip badge"
            />
          </div>
        )}
        {Object.keys(reactionIcons).includes(reaction) && (
          <img
            src={reactionIcons[reaction]}
            alt="Reaction"
            className={s["vehicle-gallery__reaction-icon"]}
          />
        )}
        {photoLinks.length > 1 && (
          <>
            <img
              className={
                s["vehicle-gallery__arrow"] +
                " " +
                s["vehicle-gallery__arrow--prev"]
              }
              src={iconVehicleGalleryPrev}
              alt="Prev"
              onClick={handleClickPrev}
            />
            <img
              className={
                s["vehicle-gallery__arrow"] +
                " " +
                s["vehicle-gallery__arrow--next"]
              }
              src={iconVehicleGalleryNext}
              alt="Next"
              onClick={handleClickNext}
            />
          </>
        )}
      </div>
      <div
        className={s["vehicle-gallery__thumbnails-wrapper"]}
        ref={thumbnailsWrapperRef}
      >
        {photoLinks.map((photoLink, i) => (
          <Image
            className={s["vehicle-gallery__thumbnail"]}
            src={photoLink.link}
            alt="Vehicle thumbnail"
            style={calculateThumbnailStyles(i)}
            onClick={() => handleClickThumbnail(i)}
            key={i}
          />
        ))}
        <div
          className={s["vehicle-gallery__thumbnail-cover"]}
          style={calculateThumbnailCoverStyles()}
        ></div>
      </div>
    </div>
  );
};

export default VehicleGallery;
